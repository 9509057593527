<template>
  <section class="content-area" style="margin-top: 75px;">
    <article class="content">
      <form>
        <div class="toolbar" style="background-color:#F3F4F8">
          <div class="left">
            <a @click="$router.back() " class="back">
              <i class="fal fa-chevron-left icon"></i>
              Back
            </a>
          </div>
          <div class="right">

          </div>
        </div>
        <div class="content" style="min-height: 400px; margin-top: 25px;">
            <div v-for="item in this.likesByShareId(this.$route.params.share_id)" :key="item.id" style="display:flex; margin-bottom: 15px; position: relative" >
                <i v-if="item.attributes['type'] == 'Like'" class="sentiment-button fa-thumbs-up fas icon like" style="float:left; margin-left:25px; padding-top: 22px; position: absolute;  z-index: 3; font-size: .9rem;margin-top: 18px; background-color: white; border-radius: 50%; padding-left: 5px; padding-top: 5px; width: 25px; height: 25px; border-style: double"></i>
                <i v-if="item.attributes['type'] == 'Thank'" class="ssentiment-button fa-award fas icon thank" style="float:left; margin-left:25px; padding-top: 22px; position: absolute;  z-index: 3; font-size: 1rem;margin-top: 18px; background-color: white; border-radius: 50%; padding-left: 5px; padding-top: 5px; width: 25px;  height: 25px; border-style: double"></i>
                <img
                    :src="item.attributes['creator-avatar']"
                    class="author-avatar"
                    style="height: 35px; width: 35px; border-radius: 50%; margin-left: 10px; margin-right: 5px; "
                />
              <div style="margin-left: 10px; padding-top: 10px;">
                {{ item.attributes['creator-first-name']}} {{ item.attributes['creator-last-name']}}
                <span class="created-at" style="padding-left: 7px; display: contents">
                  - {{ when(item.attributes["created-at"]) }}
                </span>
              </div>

            </div>
        </div>
      </form>
    </article>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import {end} from "vue2-timepicker";
import moment from "moment-timezone";

export default {
  props: ["likeType", "shareId"],
  data() {
    return {
    };
  },
  created() {
    this.fetchContentLikes({ shareId: this.$route.params.share_id});
    console.log(this.likesByShareId(this.shareId));
  },
  computed: {
    ...mapGetters("sentiments", ["likesByShareId"])
  },

  methods: {
    ...mapActions("sentiments", ["fetchContentLikes"]),
    getShareId() {
      this.$route.params.share_id;
    },
    navMessageList: function() {
      router.push(`/${this.$route.params.rallyId}/messages`);
    },
    randomKey: function() {
      var r = Math.floor(Math.random() * 10000);
      return;
    },
    when(dateToWhen) {
      return dateToWhen ? moment(dateToWhen).fromNow() : "";
    }
  }
};
</script>
