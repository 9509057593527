<template>
    <div v-if="isPinned" class="" >
      <i class="fas fa-thumbtack pinned"/>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
import UserPermissionMixin from './UserPermissionMixin.vue';
import ContentFlagging from "./ContentFlagging";
  export default {
    props: ['content'],
    data: function () {
      return {};
    },
    created() {

    },
    computed: {
      ...mapState("contents", ["contentById"]),
      /*userIsOwner(){
      return (this.content && this.content.attributes && this.content.attributes["creator-id"] == this.user.user_id) 
    },*/
      isPinned() {
        if (parseInt(this.content.attributes['sort-order']) == 0) {
          return true;
        }
        return false;
      },
    },
    methods: {}
    }
</script>
