<template>
  <article v-if="rally.rally" class="listed rally">
    <span v-on:click="gotoHomePage(rally)">
      <p v-if="rally.content">
        {{ rally.content.id }}
      </p>
      <img  class="rally-avatar" @error="rallyAvatarOnError" :src="avatarImage" />
      <div class="info">
        <div class="title">
          <i v-if="isPrivate" class="primary far fa-lock" style="padding-right: 10px; color: #008295;"></i>
          <i v-if="isPublic" class="public far fa-lock-open" style="padding-right: 10px; color:#008295"></i>
          <i v-if="isAMember" class="primary fa fa-heart" style="color:darkred; padding-right: 10px;"></i>

          {{ rally.rally.attributes.title }}
        </div>
        <div class="stats">
          <span v-if="rally.rally.attributes['child-count'] > 0" class="stat">
            <i class="fa-sitemap fal icon"></i>
            {{ rally.rally.attributes["child-count"] }} Rallies
          </span>
          <span class="stat">
            <i class="fa-users fal icon"></i>
            {{ rally.rally.attributes["member-count"] }} Members
          </span>
        </div>
      </div>
    </span>
   
    <button v-if="invited" @click="doAcceptInvitation" class="primary">Accept Invitation</button>
    <button v-if="archived" @click="unarchiveRally" class="primary">Un-Archive</button>
  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router, findDefaultChannelOfType, avaUrl} from "../common";
import { userInfo } from "../common/user-info";
export default {
  name: "Rally",
  props: ["rally"],
  data() {
    return {
      page: 1,
      loadAttempted: false,
      show_create: false,
      polling_int: null,
      rallyAvatarLoaded: true,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),
    ...mapState("memberships", ["membersByRallyId", "membersByUserId"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    checkReloadData() {
      if ((!this.membersByRallyId || !this.membersByRallyId[this.rally.id]) && !this.loadAttempted) {
        this.loadPageData();
        return false
      }
      return true
    },
    avatarImage() {
      if (this.contentById[this.rally.id + "_rally_avatar"]) {
        return this.contentById[this.rally.id + "_rally_avatar"].attachmentDataURL
      } else {
        return avaUrl(this.rallyAvatarContent)
      }
    },
    rallyAvatarContent() {
      var avid = this.rally.rally.relationships.avatar.data.id
      if (avid == -1 || !this.rallyAvatarLoaded) {
        return this.defaultAvatarsByType["rally"]
      } else {
        return this.contentById[avid];
      }

    },
    archived() {
      return (this.rally && this.rally.rally.attributes.status == 'Archived')
    },
    disabled() {
      return this.invited || this.archived || (!this.rally.membership)
    },
    invited() {
      return (this.rally.membership && this.rally.membership.attributes.status == 'Invited')
    },
    locked() {
      return !this.rally.membership && this.rally.rally.attributes.privacy !== 'Public'
    },
    isPrivate() {
      return this.rally.rally.attributes.privacy !== 'Public'
    },
    isAMember() {
      return (this.rally.membership && this.rally.membership.attributes.status !== 'Invited') || (this.membersContainsUserId())
    },
    isPublic() {
      return this.rally.rally.attributes.privacy == 'Public'
    }
  },
  created(){
   //  this.fetchMembersByRally({ rallyId:  this.rally.id, status: ["Active", "Invited", "Requested", "Affliated"]});
  },
  methods: {
    ...mapActions("rallies", ["fetchRalliesWithInfo", "fetchRalliesByParent", "updateRally"]),
    ...mapActions("memberships", ["fetchMembersByRally", "acceptInvite"]),
    ...mapActions("activities", ["fetchActivities"]),

    loadPageData(){
      this.loadAttempted = true
      this.fetchMembersByRally({ fetchMyMemberInfo: true, rallyId: this.rally.id, status: ['Active'] });
      //this.fetchMembersByRally({ rallyId:  this.rally.id, status: ["Active", "Invited", "Requested", "Affliated"]});
    },

    rallyAvatarOnError(){
      this.rallyAvatarLoaded = false
    },

    //Started PUT "/api/v1/memberships/2840338.json" for 127.0.0.1 at 2022-04-28 22:17:29 -0500
    //Processing by Api::V1::MembershipsController#update as JSON
    //Parameters: {"data"=>{"type"=>"memberships", "attributes"=>{"id"=>"2840338", "rally_id"=>39017, "user_id"=>303137, "role"=>"Owner", "status"=>"Active", "sort_order"=>5, 
    //"privacy"=>0, "title"=>"JT August 14 Rally", "disable_notifications"=>false, "created_via"=>"NewRally", "created_at"=>"2020-08-14T23:16:22.000Z", 
    //"updated_at"=>"2021-11-13T00:03:53.000Z", "deleted_at"=>nil, "rally_type"=>"Rally", "is_custom"=>false, "links"=>{"self"=>"http://localhost:3030/api/v1/memberships/2840338"}, 
    //"included_objects"=>[], "membership_profile"=>{"id"=>"590386", "first_name"=>"Jason", "last_name"=>"Topliff", "nickname"=>"", "email"=>"jason.topliff@rallyhood.com", 
    //"address"=>"", "address2"=>nil, "address3"=>nil, "city"=>"66086", "state"=>"", "zip"=>"", "country"=>nil, "primary_phone"=>"", "secondary_phone"=>nil, "position"=>"testing", 
    //"facebook_url"=>"", "linkedin_url"=>"", "twitter_url"=>"", "expertise"=>"", "birthday_month"=>nil, "additional_info"=>""}, "rally"=>{}, "user"=>{"id"=>"303137", 
    //"avatar"=>{"id"=>"-1", "background"=>"", "large_url"=>"http://staging.rallyhood.com/avatars/large/missing.png", 
    //"medium_url"=>"http://staging.rallyhood.com/avatars/medium/missing.png", "small_url"=>"http://staging.rallyhood.com/avatars/thumb/missing.png", 
    //"url"=>"http://staging.rallyhood.com/avatars/original/missing.png", "links"=>{"self"=>"/-1"}}, "first_name"=>"Jason", "last_name"=>"Topliff", 
    //"neo_avatar"=>{"id"=>"-1", "background"=>"", "large_url"=>"http://staging.rallyhood.com/avatars/large/missing.png", 
    //"medium_url"=>"http://staging.rallyhood.com/avatars/medium/missing.png", "small_url"=>"http://staging.rallyhood.com/avatars/thumb/missing.png",
     //"url"=>"http://staging.rallyhood.com/avatars/original/missing.png", "links"=>{"self"=>"/-1"}}, "current_position"=>"testing", "additional_info"=>"", "expertise"=>"", 
     //"facebook_url"=>"", "linkedin_url"=>"", "twitter_url"=>"", "nickname"=>"", "primary_phone"=>"", "address"=>"", "city"=>"66086", "state"=>"", "zip_code"=>"", "country"=>nil, 
     //"email"=>"jason.topliff@rallyhood.com", "time_zone"=>"Central Time (US & Canada)", "claimed"=>true, "locale"=>"en", "links"=>{"self"=>"http://localhost:3120/api/v1/303137"}}}}, 
     //"id"=>"2840338"}
    doAcceptInvitation() {
       this.acceptInvite({member:this.rally.membership, rallyId: this.rally.rally.id })
       router.push(`/${this.rally.rally.id}/messages`);
    },
    gotoHomePage(rally) {
      var rallyId = rally.rally.id;
      //if (!this.isUserAMember(rally)){
      if(!rally.rally.attributes.viewable) {
          router.push({name: 'gate', params: {rallyId: this.rally.id, uniqueId: 12345}});
        }
      else {
        this.fetchActivities({rallyId: rallyId})
        router.push(`/${rallyId}`);
      }
    },

    isUserAMember(rally){
      return (rally.membership && rally.membership.attributes.status !== 'Invited') || (this.membersContainsUserId())
    },
    membersContainsUserId() {
      if (this.membersByRallyId[this.rally.id] === undefined) {
        return false;
      }
      return this.membersByRallyId[this.rally.id].some(obj => obj.id === userInfo().id);
    },
    getContentById(id) {
      return this.contentById[parseInt(id)] ? this.contentById[parseInt(id)] : this.contentById[-1];
    },

    getContentAttribute(id, attname) {
      var c = this.getContentById(id);
      if (c && c.attributes && c.attributes[attname]) {
        return c.attributes[attname];
      } else {
        return "";
      }
    },

    /*getRallyAvatar(id) {
      var ava = this.getContentAttribute(id, "small-url");
      return ava ? ava : "../assets/rally_avatar.jpg";
    },*/


    log(message) {
      this.$log.debug(message);
    },
    unarchiveRally() {
     this.rally.rally.attributes.status = "Active"
     this.updateRally({rally: this.rally, status:"Active"})
     this.gotoHomePage(this.rally)
    },
  }
};
</script>
