<template>
  <div class="body">
    <p>Your primary email is where all notifications will be sent. You can log in from any of your emails.</p>
    <div class="form-group">
      <label style="font-weight: bold;">Primary Email</label>
    </div>
    <form>
      <div class="form-group">
        <input v-model="currentUser.attributes['email']" type="text" />
        <button class="primary" style="float: right;margin-top: -40px;" @click.prevent="saveUserInfo()">Save</button>
      </div>
    </form>

    <hr/>
    <div style="padding-bottom:25px;">
      <label style="font-weight: bold;">Secondary Emails</label>
    </div>
    <!-- Loop through secondary emails here -->
    <div v-for="(email, id) in secondaryEmails" :key="id" class="form-group">
      <input type="text" v-model="secondaryEmails[id]" disabled="disabled" style="background-color:lightgray"/>
      <button @click="removeEmail(id)" class="danger" style="float: right;margin-top: -40px;">Remove</button>
    </div>
    <div class="form-group">
      <input type="text" v-model="newEmail" placeholder="Secondary Email"/>
      <button @click="addEmail" class="primary" style="float: right;margin-top: -40px;">Add</button>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {userInfo} from "@/common/user-info";

export default {
  name: "EmailAddresses",
  data: function() {
    return {
      user_id: null,
      primaryEmail: '',
      emailList: {},
      newEmail: ''
    };
  },
  computed: {
    ...mapState("user", ["usersById", "userEmails"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
    passwordError() {
      return this.password.length < 8 ||
          !/\d/.test(this.password) ||
          !/[a-z]/.test(this.password) ||
          !/[A-Z]/.test(this.password) ||
          !/[^\w\s]/.test(this.password)
    },
    passwordConfirmationError() {
      return this.passwordConfirmation !== this.password
    },
    secondaryEmails() {
      const secondaryEmails = {};
      this.userEmails.forEach((email) => {
        if (email.attributes['primary'] == false) {
          secondaryEmails[email.id] = email.attributes['email'];
        }
      });
      //return filteredEmails;
      //let secondaryEmails = this.userEmails.filter(email => email.attributes['primary'] == false).map(email => email.attributes['email'], id => email.attributes['id'])
      return secondaryEmails;
    },
  },
  created() {
    this.loadPageData();
    this.primaryEmail = this.usersById[this.user_id].attributes['email']
  },
  methods: {
    ...mapActions("user", ["addSecondaryUserEmail", "removeSecondaryUserEmail", "updateUserInfo"]),
    addEmail() {
      // Make API call to add email
      this.addSecondaryUserEmail({updatedUser: this.currentUser, email: this.newEmail})
      const userEmailId = Date.now(); // Generate unique ID for new email
      this.secondaryEmails[userEmailId] = this.newEmail;
      this.newEmail = '';
    },
    async loadPageData() {
      this.user_id = userInfo().user_id;
    },
    removeEmail(index) {
      // Make API call to delete email
      this.removeSecondaryUserEmail({userEmailId: index})
    },
    saveUserInfo(){
      this.updateUserInfo({updatedUser:this.currentUser, userNotificationSettings: {}, updateAll: this.updateAll, userPrefs: []})
      this.$emit('saveComplete');
      this.$store.dispatch('user/flashSuccess', 'User email updated!');
    },
  }
}
</script>

<style scoped>

</style>