<script>
  import { Plugins, Filesystem, FilesystemDirectory, FilesystemEncoding, Share, Capacitor } from "@capacitor/core";
  import { timezoneMapping, railsTzToMomentTz, router, RH_URL } from "../common";
  import { userInfo } from "../common/user-info"
  import { mapState, mapActions, mapGetters } from "vuex";

  export default {

    computed : {
      ...mapState("locations", ["locationsByAddressId"]),
      addressLine1() {
        return this.addressInfo? this.addressInfo.attributes.line1 : ""
      },
      city() {
        return  this.addressInfo? this.addressInfo.attributes.city : ""
        //return this.locationsByAddressId[parseInt(this.content.attributes['address-id'])].attributes.city
      },
      state() {
          return  this.addressInfo? this.addressInfo.attributes.state : ""
          //return this.locationsByAddressId[parseInt(this.content.attributes['address-id'])].attributes.state
      },
      zip() {
        return  this.addressInfo? this.addressInfo.attributes.zip : ""
        //return this.locationsByAddressId[parseInt(this.content.attributes['address-id'])].attributes.zip
      },
      seUrl(){
        return RH_URL() + this.rallyId + "/fundraisers/" + this.content.id + "?channel_key="
      },
      addressInfo(){
        if(this.content && this.content.attributes) {
          return this.locationsByAddressId[this.content.attributes["address-id"] + "" ]
        } else {
          return ''
        }
      }
    },
    methods: {
    }
  };
</script>
