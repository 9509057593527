<template>
  <div  :class="[{ open: modalOpen }, 'modal', '-modal']">
    <div class="shadow" @click="closeModal"></div>
    <div class="body">
      <i class="fal fa-times exit" @click="closeModal"></i>
        <div >
          <div class="heading">{{this.modalTitle}}</div>
          <div class="description">
            {{this.modalText}}
          </div>
          <div class="actions">
            <div class="left">
              <button class="secondary" @click="closeModal">Cancel</button>
            </div>
            <div class="right">
              <button class="primary" @click="openRhWeb">OK</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { accessToken } from "../common/auth-header"
  import { RH_URL, router} from "../common";
  import { eventBus } from "../main.js";
  import { Plugins } from "@capacitor/core";
  const { Browser } = Plugins;
  export default {
    name: "OpenRhWebSpecialEvents",
    props: [ 'params', 'uniqueId', 'content' ],
    data() {
      return {
        openModal: 'no',
        mode: 'edit',
        modalTitle: "",
        modalText: ""
      }
    },
    created(){
      eventBus.$on('openRHWebSpecialEventsEdit' + this.uniqueId, () => {
          //this.openModal = 'yes'
          this.mode = 'edit'
          this.modalTitle = 'Edit Special Event',
          this.modalText = 'Do you want to leave the mobile app and edit the Special Event?'
        this.openRhWeb();
      })
      eventBus.$on('openRHWebSpecialEventsManage' + this.uniqueId, () => {
          this.modalTitle = 'Manage Special Event'
          this.modalText = 'Do you want to leave the mobile app and manage the Special Event?'
          //this.openModal = 'yes'
          this.mode = 'manage'
        this.openRhWeb();
      })
    },
    computed: {
      modalOpen() {
        return this.openModal == 'yes'
      },
    },
    methods: {
      closeModal() {
        this.openModal = 'no';
      },
      openRhWeb() {
        const url = RH_URL();
        const rallyId = this.$route.params.rallyId;
        const mt = accessToken();
        const cid = this.content.id
        const theMode = this.mode  == "edit" ? "edit?cap_open=1" : "payments?cap_open=1"
        const capOpenParam = "&cap_open=1"
        //window.open(`${url}?mt=${mt}&return_to=/${rallyId}/fundraisers/${cid}/${theMode}`);
        console.log("url is...."  + `${url}?mt=${mt}&return_to=/${rallyId}/fundraisers/${cid}/${theMode}`)
        Browser.open({ url: `${url}?mt=${mt}&return_to=/${rallyId}/fundraisers/${cid}/${theMode}` });
        Browser.addListener('browserFinished', () => {
            console.log('browserFinished event called')
            router.push(`/${rallyId}/special_events`)
            location.reload()
        });
        this.openModal= 'no'
      }
    }
  }
</script>
