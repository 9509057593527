<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { userInfo } from "../common/user-info"
import ContentPayableMixin from "./ContentPayableMixin"; 
import { router, findDefaultChannelOfTypeForRally, generateCustomFieldJSON, STRIPE_PUBLISHABLE_KEY} from "../common";
import moment from "moment";
import { eventBus } from "../main.js";
export default {
// mixins: [ContentPayableMixin],
  data: function() {
    return {
      loadAttempted: false,
      checkoutMode: false,
      participantList: [],
      participantListsBySku: {},
      stripeToken: "",
      addInfo: "",
      notes: "",
      //publishableKey: "pk_test_51DLYQSF3WHnNQL2Jfw7ewSqfpFOKJqc8we7lWdT5QBkKgYSNKaJevvccsF4Ig98Eq3qvd3hsGzgz9l3D47n3VDCY00bcBvrsOy",
      skuString: "",
      oldConfirm: "",
     // fundraiser: null,
      //user_id: null,
      modalStatus: false,
      confirmStatus: false,
      state: null, // <--- This should come from the participation record, and is referenced by cssClass() and label() below, but I was not really sure what the options were.
      payItEnabled: false,
      customFieldValues:{}
    };
  },
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("rfins", ["pendingStripeTxByUserAndContentId", "stripeTxErrorsByTxId", "accountRallyById"]),
    ...mapState("user", ["usersById"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("shares", ["sharesByShareId"]),
    deadlineDate() { 
      let c = this.allContentByContextTypeAndId[this.contextId]
      if (c && c.filter){
       let de =  c.filter(function(s) {
         if (s.attributes.type == 'DeadlineEvent'){
           return s
         } 
       })
       if (de && de.length > 0){
         if (de[0].attributes['to-date']){
            return moment(de[0].attributes['to-date'], 'MM/DD/YYYY').subtract(1, "days");
         } else {
           return null
         }
       } else {
         return null
       }
      } else {
        return null
      }
    },
    isArchived() {
      return this.fundraiser &&
          this.fundraiser.attributes['status'] == 2
    },
    isPastDeadline() {
      return this.deadlineDate && this.deadlineDate.add(1, "days").isBefore()
    }, 
    payables() {
      let c = this.allContentByContextTypeAndId[this.contextId]
      if (c && c.filter){
       return c.filter(function(s) {
        return s.attributes.type == 'Payable'
       })
      } else {
        return []
      }
      //return this.allContentByContextTypeAndId[parseInt(this.contextId)];
    },
    currentUser() {
      return this.usersById[this.user_id];
    },
    confNum() {
      return this.oldConfirm
      /*var tx = this.pendingStripeTxByUserAndContentId[this.user_id + "" + this.contextId];
      if (tx) {
        return this.rallyId + "-" + tx.id;
      } else {
        return "Processing...";
      }*/
    },
    invalid() {
      return false
    },
    stripeTxKey() {
      return this.user_id + "" + this.contextId;
    },
    user_id() {
      return userInfo().user_id
    },
    stripeTx() {
      return this.pendingStripeTxByUserAndContentId[this.user_id + "" + this.contextId];
    },
    stripeTxId(){
      return this.stripeTx?.id
    },
    cssClass() {
      if (!this.isStripeAccountPresent && !this.allPayablesFree){
        return "hollow"
      }
      if (this.isArchived) {
        return 'warning'
      }
      switch (this.state + "") {
        case "1":
          return "warning";
        case "2":
          return "hollow";
        default:
          return "primary";
      }
    },
    label() {
      if (this.isArchived) {
        return 'Archived'
      }
      if (this.isPastDeadline || (!this.isStripeAccountPresent && !this.allPayablesFree)){ 
        return "Disabled"
      }
      switch (this.state + "") { 
        case "1":
          return "I'm Going";
        case "2":
          return "Holding My Spot";
        default:
          return (this.iHazPurchaz) ? "PURCHASE MORE": "REGISTER"; 
      }
    },
    accountRallyId(){
      //var payablez = this.allContentByContextTypeAndId[this.contextId]
      if (this.payables && this.payables.length > 0){
          return this.payables[0].attributes['account-rally-id']
      }
      return null;
    },
    accountRally(){
      return this.accountRallyById[this.accountRallyId]
    },
    publishableKey(){
      return  this.accountRally &&  this.accountRally.attributes && this.accountRally.attributes['publishable-key'] ? this.accountRally.attributes['publishable-key'] : STRIPE_PUBLISHABLE_KEY()
    }, 
    isStripeAccountPresent(){
      return (this.accountRally && this.accountRally.attributes.title !== "Draft Account") ? true :false
    },

    registered() {
      return this.state !== null;
    },
    allFree(){
      return this?.stripeTx?.attributes["formatted-total"] === "0.00" ? true : false
    },
    fundraiser() {
        return this.contentById[this.contextId];
    },
    iHazPurchaz() {
      //let payablez = this.allContentByContextTypeAndId[this.contextId]
      let haz = false
      if (this.payables){
          this.payables.forEach(p => {
            if (this.getMyPurchaseById(p.id)){
              haz = true;
            }
        })
      }
      return haz;
    },
    iHazIntrez() {
      let haz = false;
     // let payablez = this.allContentByContextTypeAndId[this.contextId]
      if (this.payables){
          this.payables.forEach(p => {
            if (this.getInterestPart(p.id)){
              haz = true;
            }
        })
      }
      return haz;
    },
    allPayablesFree() {
      //let payablez = this.allContentByContextTypeAndId[this.contextId]
      let amount = 0
      if (this.payables && this.payables.forEach){
          this.payables.forEach(p => {
           amount = amount + p.attributes.cost
        })
      }
      return amount == 0;
    },
    channelId() {
      if (this.rallyChannelsByRallyId && this.rallyChannelsByRallyId[parseInt(this.rallyId)]) {
        var chanid = findDefaultChannelOfTypeForRally(
          this.channelTypePlural,
          this.rallyChannelsByRallyId,
          this.rallyId
        );
        return chanid
      } else {
        return null
      }
    },
    customFields(){
      if (this.allContentByContextTypeAndId[this.contextId] ) {
        let carr = this.allContentByContextTypeAndId[this.contextId]
        //console.log("carr is")
        //console.log(carr)
        let cf = []
        carr.forEach(c => {
          //console.log("carr")
          //console.log(c)
          //console.log("c.attributes.type")
          //console.log(c.attributes.type)
          //console.log("c.body")
          //console.log(c.attributes.body)
          if (c.attributes.type && c.attributes.type == "PaymentCustomFieldsDefinition" && c.attributes.body){
            try {
              cf = JSON.parse(c.attributes.body)["custom_field_definitions"]
              //console.log("cf")
              //console.log(cf)
            } catch (e){
              console.log(e)
            }
          }
        });
        return cf
      } 
      return null

    }
  },
  watch: {
    allContentByContextTypeAndId: function(newMap, oldMap) {
       if (newMap && newMap[parseInt(this.contextId)] && newMap[parseInt(this.contextId)].length > 0) {
        if (!this.stripeTx){
          //no transaction yet...
          let c = newMap[parseInt(this.contextId)]
          let pbles = []
          if (c && c.filter){
            pbles =  c.filter(function(s) {
              return s.attributes.type == 'Payable'
            })
          }
          //this.createStripeTx(pbles)
        }
      }
    },
    pendingStripeTxByUserAndContentId: function(newMap, oldMap) {
      var tx = newMap[this.user_id + "" + this.contextId]
      if (tx.attributes["transaction-status"] == "pay") {
        if (tx) {
          this.oldConfirm =  this.rallyId + "-" + tx.id
        }
        this.checkoutMode = false
        this.participantList= []
        this.stripeToken = ""
        this.notes = ""
        this.addInfo = "" 
        this.customFieldsArr = []

        this.toggleConfirm()
        this.myReloadPageData()
        this.loadAndCreate() 
      }
    }
  },

  methods: {
    ...mapActions("contents", ["fetchContents", "fetchContentById", "createContents"]),
    ...mapActions("rfins", ["createStripeTransaction", "updateStripeTransaction","fetchFundraiserDetails"]),
    ...mapActions("rallies", ["rallyChannelsByRallyId"]),


    soldOut(p){
       return this.calcMaxTickets(p) == 0
    },
    calcMaxTickets(p){
      let max = 0
      let amt = p.attributes["amount"];
      let maxp = p.attributes["max-participants"];
      let totp = p.attributes["participant-total"];
      if (!p.attributes["infinite-inventory"]) {
        if (amt == 0) {
          max = 0 //sold out
        } else {
          //max = maxp - totp;
          max = amt;
        }
      } else {
        max =  11
      }
      return max
    },

    customFieldName(n){
      return "custom_field_" + n
    },
    customFieldValueObject(n){
      if (!this[n]){
        this[n] = ""
      }
      return this[n]
    },

    getMyPurchaseById(id) {
      return this.user_id ? this.participationById[id + "purchased" + this.user_id] : null
    }, 
    getInterestPart(id) {
      return this.user_id ? this.participationById[id + "interested" + this.user_id] : null;
    },
    loadContents() {
      if (!this.contentById[this.contextId] ||  !(this.payables) || this.payables.size == 0) {
        this.myFetchContents()
        this.loadAttempted = true;
      }
    },
    loadPageData() {
      try {
        this.myReloadPageData()
        this.loadContents();
      } catch (e) {
        console.log(e);
      }
    },
    myFetchContents() {
      this.loadAndCreate();
    },
    myReloadPageData() {
      if (userInfo()){
        this.fetchContents({
          ignorePagination: true,
          contentType: "Payable", 
          contextId: this.contextId,
          contextRallyId: this.rallyId,
          contextType: "Content",
          include: ["shares", "participations"],
          type: "Payable",
          rallyId: this.rallyId
        })

        try {
        this.fetchContents({
          ignorePagination: true,
          contentType: "Event", 
          contextId: this.content.id,
          contextRallyId: this.rallyId,
          contextType: "Content",
          include: ["shares"],
          type: "DeadlineEvent",
          rallyId: this.rallyId
        })
        } catch (e){
           console.log(e)
        }
        
        /*this.fetchContentById({
          id: this.contextId,
          contextChannelId: "308357",
          contentType: "Fundraiser"
        });*/
      } 
      else {
        router.push(`/login`);
        location.reload()
      }
    },
    loadAndCreate() {
      this.payItEnabled = true
      //if (this.payables && this.payables.length > 0) {
       // this.createStripeTx(this.payables)
     // }
    },
    createStripeTx(plist){
        //clear out the prior selection
        plist.forEach(p => {
          p.quantitySelected = 0;
        });
        this.createStripeTransaction({
          accountRallyId: plist[0].attributes["account-rally-id"],
          contentUid: this.contextId,
          payableId: plist[0].attributes.id,
          shareId: this.fundraiser.relationships["context-share"].data.id,
          shareChannel: this.sharesByShareId[this.fundraiser.relationships["context-share"].data.id]?.attributes['context-channel-id'],
          rallyId: this.rallyId,
          customFields: this.customFields
        });
    },
    createCustomFields(){
      try{

        if (this.customFields && this.customFields.length > 0){
          let cfields = {custom_fields:[]}
          this.customFields.forEach(c => {
            let v = this[this.customFieldName[c.name]]
            let cfield = {name: c.name, value: v}
            cfields.custom_fields.push(cfield)
          });
          return JSON.stringify(cfields)
          //let  chanid = this.sharesByShareId[this.fundraiser.relationships["context-share"].data.id]?.attributes['context-channel-id']
          //this.createContents({  title:"Custom Fields",  type: "PaymentCustomFields", contentType: "Message", rallyId: this.rallyId,  contextChannelId: chanid, contextRallyId:  this.rallyId, body: JSON.stringify(cfields)});
             /* MSF.model(:content).create(context_id: @payment.id,
                                        context_channel_id: @fundraiser.context_share.context_channel_id,
                                        context_type: 'Payment',
                                        context_rally_id: @rally.id,
                                        content_type: 'Message',
                                        type: 'PaymentCustomFields',
                                        title: 'Custom Fields',
                                        body: cust_field_json,
                                        creator_id: user_id)*/
        } else {
          return null 
        }
      } catch (e){
        console.log(e)
      }

    },

    stripeCardError(e){
        eventBus.$emit('overlayOff')
    },

    payItTokenCallback(token) { 
      this.stripeToken = token;
      var ss = [];
      this.payables.forEach(p => {
        if (p.quantitySelected && p.quantitySelected > 0) {
          ss.push(p.attributes["sku-id"] + ":" + p.quantitySelected + ":" + p.relationships["context-share"].data.id);
          p.attributes["amount"];
          let amt =  p.attributes["amount"]
          let totp = p.attributes["participant-total"]
          p.attributes["amount"] = parseInt(amt) - parseInt(p.quantitySelected)
          p.attributes["participant-total"] = parseInt(totp) + parseInt(p.quantitySelected)
        }
      });
      if (ss.length > 0) {
        console.log("generateCustomFieldJSON2")
        console.log(this.customFields)
        console.log(this.customFieldsArr)
        console.log(generateCustomFieldJSON(this.customFields, this.customFieldsArr))
        //alert(generateCustomFieldJSON(this.customFields, this.customFieldsArr))
        this.skuString = ss.join(",");
        this.updateStripeTransaction({ 
          stripeTransactionId: this.stripeTx.id,
          accountRallyId: this.payables[0].attributes["account-rally-id"],
          contentUid: this.contextId,
          payableId: this.payables[0].attributes.id,
          shareId: this.fundraiser.relationships["context-share"].data.id,
          shareChannel: this.sharesByShareId[this.fundraiser.relationships["context-share"].data.id]?.attributes['context-channel-id'],
          skuArray: ss,
          transactionStatus: "pay",
          stripePaymentToken: this.stripeToken  ? this.stripeToken.id: "",
          participantList: this.participantList,
          rallyId: this.rallyId,
          notes: this.notes,
          addInfo: this.addInfo,
          customFields: generateCustomFieldJSON(this.customFields, this.customFieldsArr)
        });
        
      }
    },

    onChangeValue(e) {
      //call rfins for updated totals, etc
      //"1472:1:10134819"
      var ss = [];
      this.payables.forEach(p => {
        if (p.quantitySelected && p.quantitySelected > 0) {
          ss.push(p.attributes["sku-id"] + ":" + p.quantitySelected + ":" + p.relationships["context-share"].data.id);
        }
      });
      if (ss.length > 0) {
        this.skuString = ss.join(",");
        console.log("generateCustomFieldJSON")
        console.log(this.customFields)
        console.log(this.customFieldsArr)
        console.log(generateCustomFieldJSON(this.customFields, this.customFieldsArr))
        this.updateStripeTransaction({
          stripeTransactionId: this.stripeTx.id,
          accountRallyId: this.payables[0].attributes["account-rally-id"],
          contentUid: this.contextId,
          payableId: this.payables[0].attributes.id,
          shareId: this.fundraiser.relationships["context-share"].data.id,
          shareChannel: this.sharesByShareId[this.fundraiser.relationships["context-share"].data.id]?.attributes['context-channel-id'],
          skuArray: ss,
          transactionStatus: "",
          rallyId: this.rallyId,
          notes: this.notes,
          addInfo: this.addInfo,
          customFields:  generateCustomFieldJSON(this.customFields, this.customFieldsArr)
        });
        //this.createCustomFields()
      }
    },

    checkout() {
      this.payables.forEach(p => {
        if (p.quantitySelected && p.quantitySelected > 0) {
          var listBySku = [];
          var skuId = p.attributes["sku-id"];
          for (var i = 0; i < p.quantitySelected; i++) {
            var newPart = { id: skuId + "_" + i, firstName: "", lastName: "", email: "", skuId, ticketName: p.attributes.title };
            this.participantList.push(newPart);
            listBySku = [...listBySku, newPart];
          }
        }
        this.participantListsBySku[skuId] = listBySku;
      });
      this.checkoutMode = true;
    },
    payIt() {
      this.payItEnabled = false
      eventBus.$emit('overlayOn')
      if (!this.allFree){
      this.$refs.elementRef.submit();
      } else {
        this.payItTokenCallback("")
      }
    },
    available(cost) {
      return Number(cost.total) - Number(cost.sold);
    },
    destroy() {
      // Do the thing
      this.toggleModal();
    },
    save() {
      // Do the thing
      this.toggleModal();
    },
    toggleModal() {
      if (this.isPastDeadline || (!this.isStripeAccountPresent && !this.allPayablesFree)){ 
        return 
      }
      if (this.payables && this.payables.length > 0) {
        console.log("creating tx!!")
        this.createStripeTx(this.payables)
      }
      this.modalStatus = !this.modalStatus;
      this.confirmStatus = false;
    },
    toggleConfirm() {
      this.modalStatus = false;
      eventBus.$emit('overlayOff')
      if (this.confirmStatus){
        this.loadAndCreate()
      }
      this.confirmStatus = !this.confirmStatus;
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
