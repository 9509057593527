<template>
  <div :key="this.randomKey()">
    <main>
      <section class="content-area">
        <div class="content-modal">
          <form @submit.prevent="doSaveMessage" class="content message new" method="post">
            <div class="toolbar">
              <div class="left">
                <a @click="navMessageList()" class="back">
                  <i class="fal fa-chevron-left icon"></i>
                  Cancel
                </a>
              </div>
              <div class="right">
                <button class="primary" type="submit">Post</button>
              </div>
            </div>

            <article class="content listed message">
              <div class="form-group">
                <label for="title">Title</label>
                <input v-model="newMessage.title" id="title" name="title" type="text" />
              </div>
              <div class="form-group">
                <label for="body">Body</label>
                <vue-editor
                  v-model="newMessage.body"
                  :customModules="customModules"
                  :editorOptions="editorOptions"
                  :editorToolbar="customToolbar"
                ></vue-editor>
              </div>
              <div class="form-group">
                <label for="priority" class="checkbox-label">
                  <input
                    v-model="notifyImmediately"
                    type="checkbox"
                  />
                  <b>&nbsp; &nbsp; Notify Immediately</b>
                  - This will send an email with your message to all {{this.rallyMemberCount}} people in
                  this Rally.
                </label>
              </div>
              <ContentAttachments v-if="content" ref="cAttach" :readOnly="false" :content="content" :rallyId="this.rallyId" :channelId="parseInt(this.channelId)" />
            </article>
          </form>
        </div>
        <!-- END CREATE MESSAGE -->
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router, findDefaultChannelOfTypeForRally, slugToTypeObjectMap } from "../common";
import QuillMention from "quill-mention";

export default {
  name: "CreateMessage",
  data() {
    return {
      customModules: [{ alias: "quillMention", module: QuillMention }],
      customToolbar: ["bold", "italic", "underline", { list: "bullet" }, { 'color': [] }, { 'background': [] }, "link", { 'align': [] },],
      editorOptions: {
        modules: {
          quillMention: {
            allowedChars: /^[A-Za-z0-9\s]*$/,
            mentionDenotationChars: ['@'],
            source: async function(searchTerm, renderList) {
              // TODO: Query RMS
              // const raw = await this.searchMembersByRally({query: searchTerm, rallyId: this.rallyId});
              const raw = [
                { id: 1, first: 'David', last: 'Hanson', avatar: 'https://cdn.shopify.com/s/files/1/0037/0268/5745/articles/home-yourpets-rabbit_400x.jpg' },
                { id: 2, first: 'Jason', last: 'Topliff', avatar: 'https://cdn.shopify.com/s/files/1/0037/0268/5745/articles/home-yourpets-rabbit_400x.jpg' },
                { id: 3, first: 'Chris', last: 'Alderson', avatar: 'https://cdn.shopify.com/s/files/1/0037/0268/5745/articles/home-yourpets-rabbit_400x.jpg' },
              ];

              // Parse the results to the format QuillMention expects: { id: String, avatar: String, value: String }
              let results = [];
              for ( const result in raw ) {
                results.push({
                  avatar: raw[result].avatar,
                  id: raw[result].id,
                  value: raw[result].first + ' ' + raw[result].last,
                });
              }

              // As you type, filter results on client side as well for faster response.
              if (searchTerm.length === 0) {
                renderList(results, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < results.length; i++) {
                  if ( ~results[i].value.toLowerCase().indexOf(searchTerm.toLowerCase() ) ) {
                    matches.push(results[i]);
                  }
                }
                renderList(matches, searchTerm);
              }
            }.bind(this),
            renderItem(item) {
              return `<img src="${item.avatar}">${item.value}`;
            },
          },
        },
      },
      notifyImmediately: false,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("contents", ["contentById"]),
    ...mapState("rallies", ["rallyChannelsByRallyId", "allRalliesByRallyId"]), 
    rallyMemberCount(){
        return this.allRalliesByRallyId[this.rallyId]?.rally?.attributes["child-count"] 
    },
    content() {
      return this.contentById[this.tempKey];
    },
    channelTypePlural() {
      return slugToTypeObjectMap()["messages"].channelTypePlural;
    },
 
  },

  created() {
    this.rallyId = this.$route.params.rallyId
    this.tempKey = "TK" + Math.floor(Math.random() * 10000);
    this.newMessage.title = ""
    this.newMessage.body = "";
    this.channelId = findDefaultChannelOfTypeForRally(this.channelTypePlural, this.rallyChannelsByRallyId, this.rallyId);
    this.createMessage({ newMessage: this.newMessage, rallyId: this.$route.params.rallyId, channelId: this.channelId, status:0, tempKey: this.tempKey});
  },
  methods: {
    ...mapActions("messages", ["createMessage", "saveMessage"]),
    doSaveMessage() {
      this.$refs.cAttach.saveAttachments()
      this.content.attributes.title = this.newMessage.title
      this.content.attributes.body = this.newMessage.body
      this.saveMessage({ priority: this.notifyImmediately ? 1 : 4, message: this.content, rallyId: this.rallyId, channelId: this.channelId, status: 1});
      this.newMessage.title = null;
      this.newMessage.body = null;
      router.push(`/${this.$route.params.rallyId}/messages`);
    },
    navMessageList: function() {
      router.push(`/${this.$route.params.rallyId}/messages`);
    },
    randomKey: function() {
      var r = Math.floor(Math.random() * 10000);
      return;
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
