<template>
  <div v-if="checkReloadData"  class="register">
   <DarkOverlay />
    <button :class="[cssClass, 'register-button']" @click="toggleModal">{{ label }}</button>
    <div :class="[{ open: confirmStatus }, 'modal']" >
      <div class="shadow" @click="toggleConfirm"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleConfirm"></i>
        <div class="heading">Thanks for your purchase!</div>
        <div>Confirmation Number:</div>
        <div>{{ "[RH" + confNum + "]" }}</div>
      </div>
    </div>
    <div :class="[{ open: modalStatus }, 'modal']" style="max-height: 100vh;">
      <div class="shadow" @click="toggleModal"></div>
      <div class="body" style="scrollbar-width: thin;">
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">Register</div>
        <div style="color:red">{{this.errorMessage}}</div>
        <br/>
        <div v-if="checkoutMode">
          <div class="heading">Purchaser</div>
          <input
            type="text"
            name="p_first_name"
            id="p_first_name"
            :value="currentUser.attributes['first-name']"
            class="form-control"
            disabled="true"
            placeholder="First"
          />
          <input
            type="text"
            name="p_last_name"
            id="p_last_name"
            :value="currentUser.attributes['last-name']"
            class="form-control"
            disabled="true"
            placeholder="Last"
          />
          <input type="text" name="p_email" id="" :value="currentUser.attributes['email']" class="form-control" disabled="true" placeholder="Email" />
          <input
            type="text"
            v-model="currentUser.attributes['phone']"
            id="p_phone"
            value=""
            class="form-control"
            style="border-color: red;"
            placeholder="Phone"
          />
          <br />
        </div>
        <div class="heading">Tickets</div>
        <div class="form-group" v-for="payable in this.payables" :key="payable.id">
          <div v-if="!checkoutMode || (payable.quantitySelected && payable.quantitySelected > 0)">
            <span class="label"> {{ payable.attributes.title }} ({{ "$" + payable.attributes.cost / 100 + ".00" }})  {{ soldOut(payable) ? "SOLD OUT": ""}}</span>
            <select  v-model="payable.quantitySelected" class="quantity" v-on:change="onChangeValue($event)" :disabled="checkoutMode || soldOut(payable)">
              <option v-for="i in calcMaxTickets(payable) + 1 " :key="i" :value="i - 1">{{ i -1 }}</option>
            </select> 
          </div>
          <div v-if="checkoutMode">
            <div class="form-group" v-for="(part, $index) in participantList" :key="$index">
              <div v-if="payable.attributes.title == part.ticketName">
                <input v-model="part.firstName" type="text" class="form-control" style="text-align: left;" placeholder="First" />
                <input v-model="part.lastName" type="text" class="form-control" style="text-align: left;" placeholder="Last" />
                <input v-model="part.email" type="text" class="form-control" style="text-align: left;" placeholder="Email" />
              </div>
            </div>
          </div>
        </div> 

        <div class="form-group" v-for="n in customFields.length" :key="customFields[n-1].name">
          <textarea v-model="customFieldsArr[n-1]"  onkeydown='if (!/^[A-Za-z0-9@.-]+$/.test(event.key)) { return false; return true;}' :placeholder="customFields[n-1].name"></textarea>
        </div>
        <div class="form-group">
          <textarea v-model="notes" placeholder="Notes"></textarea>
        </div>
        <div class="form-group">
          <textarea v-model="addInfo" :placeholder="this.additionalInfoText"></textarea>
        </div>
        <div v-if="this.stripeTx">
          <div class="left">Taxes</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-taxes"] }}</div>
        </div>
        <div v-if="this.stripeTx">
          <div class="left">Fees</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-fees"] }}</div>
        </div>
        <div v-if="this.stripeTx">
          <div class="left">Total</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-total"] }}</div>
        </div>
        <div style="color:red">{{this.errorMessage}}</div>
        <div>
          <StripeElementCard v-if="!allFree && checkoutMode && !usePaypal" @error="stripeCardError"  ref="elementRef" :pk="publishableKey" @token="payItTokenCallback" />
        </div>
        <div class="actions">
          <button class="secondary" v-if="!usePaypal" @click="toggleModal">Cancel</button>
          <button class="danger" @click="destroy" v-if="registered">Delete</button>
          <button v-if="!checkoutMode" class="primary" @click="checkout" :disabled="errorMessage != null">Checkout</button>
          <button v-if="checkoutMode && !usePaypal" class="primary" @click="payIt" :disabled="errorMessage != null">Pay Now</button>
          <!--<paypal-checkout v-if="checkoutMode && usePaypal" amount="10.91" currency="USD" :client="paypal"  env="sandbox" > </paypal-checkout>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CheckoutMixin from "./CheckoutMixin";
import ContentReloadMixin from "./ContentReloadMixin";
import ContentPayableMixin from "./ContentPayableMixin";
export default {
  mixins: [CheckoutMixin],
  data: function() {
    return {
        loadAttempted: false,
        usePaypal: false,
        customFieldsArr: ["",""],
        paypal: {
        sandbox: 'AebrtfvrdVtPgkOWyyUxB4Y_57X5p1Dqa8tfFXbsnIjjHIo8pKSC0SZD4jwODbq6nnoRokSFfUv6Gsbn',
        production: 'donthavethisyet',
      }
    }
  },
  props: ["rallyId", "contextId", "content"],
  created() {
    //this.loadAndCreate();
  },

  computed : {
    checkReloadData() {
      if (!this.allContentByContextTypeAndId[this.contextId] && !this.loadAttempted ) {
        return false
      } 
      return true
    },
    additionalInfoField(){
      let c = this.allContentByContextTypeAndId[this.contextId]
      if (c && c.filter){
       return c.filter(function(s) {
        return s.attributes.type == 'NotesInstructions'
       })
      } else {
        return []
      }
      //return this.allContentByContextTypeAndId[parseInt(this.contextId)];
    },
    

    additionalInfoText(){
      return this.additionalInfoField && this.additionalInfoField[0] ?  this.additionalInfoField[0].attributes.body : "Additional Info"
    },
 
    errorMessage(){
      return this.stripeTxErrorsByTxId[parseInt(this.stripeTxId)]?.data?.errors[0]?.detail
    }
  
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    customFieldName(n){
      return "custom_field_" + n
    },
    
    customFieldValueObject(n){
      if (!this[n]){
        this[n] = ""
      }
      return this[n] 
    }
  }
};
</script>
