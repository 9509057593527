<template>
  <div>
    <main class="gate start-flow">
      <form>
        <div v-if="currentStep == 1">
          <div class="avatar-upload">
            <AvatarUpload :uniqueId="uniqueId" :rallyImgUrl="rallyImgUrl"/>
          </div> 
          <div class="form-group">
            <label>What would you like to call your Rally?</label>
            <input v-model="rallyTitle" type="text" />
          </div>
          <div class="form-group">
            <label>Add a description or create a "Rally Cry" for your group.</label>
            <textarea
              v-model="rallyDescription"
              rows="5"
            >
              Great things happen when we come together. Thanks for all you do to help make our community strong.
            </textarea>
          </div>
          <div class="form-group">
            <label>What brings your community together?</label>
            <select v-model="rallyType" ref="vertical_id">
              <option />
              <option
                v-for="vertical in verticals"
                :key="vertical.id"
                :value="vertical.id"
              >
                {{vertical.name}}
              </option>
            </select>
          </div>
        </div>
        <div v-if="currentStep == 2">
          <i class="fal fa-times exit" @click="quit"></i>
          <div class="sub-heading">
            Welcome to Rallyhood!
          </div>
          <div class="instructions">
            Please select your organization.
          </div>
          <div class="form-group stacked-labels radios">
            <div class="form-group" @change="onChangeOrganization" >
              <label>
                <input type="radio" name="client_id" v-model="organization" value="gs"/>
                <div class="circle"></div>
                Girl Scouts of the USA
              </label>
              <label>
                <input type="radio" name="client_id" v-model="organization" value="bs"/>
                <div class="circle"></div>
                Boy Scouts of America
              </label>
              <label>
                <input type="radio" name="client_id" v-model="organization" value="jj"/>
                <div class="circle"></div>
                Jack and Jill of America
              </label>
              <label>
                <input type="radio" name="client_id" v-model="organization" value="other"/>
                <div class="circle"></div>
                Other Organization
              </label>
            </div>
          </div>
          <div v-if="showCouncil" class="sub-heading">
            Council
          </div>
          <div v-if="showCouncil" class="form-group">
            <select v-model="council" ref="vertical_id">
              <option />
              <option
                v-for="council in councils"
                :key="council.id"
                :value="council.id"
              >
                {{council.name}}
              </option>
            </select>
          </div>
        </div>
        <div v-if="currentStep == 3">
          <i class="fal fa-times exit" @click="quit"></i>
          <div class="sub-heading">
            Membership Settings
          </div>
          <div class="instructions">
            Who should be able to join this group?
          </div>
          <div class="form-group stacked-labels radios">
            <div class="form-group">
              <label>
                <input v-model="rallyPrivacy" value="Public" type="radio" name="privacy" />
                <div class="circle"></div>
                <strong>Public -</strong>
                Who should be able to join this group?
              </label>
              <label>
                <input v-model="rallyPrivacy" value ="Private" type="radio" name="privacy" />
                <div class="circle"></div>
                <strong>Private -</strong>
                Members must be invited or have their request approved by an admin.
              </label>
              <label>
                <input v-model="rallyPrivacy" value="Unlisted" type="radio" name="privacy" />
                <div class="circle"></div>
                <strong>Unlisted -</strong>
                Members may only be invited by an admin.  This Rally will not appear in search results.
              </label>
            </div>
          </div>
        </div>
        <div class="actions">
          <button
            v-if="currentStep == 1"
            class="secondary"
            @click.prevent="exit"
          >
            Cancel
          </button>
          <button
            v-else
            class="secondary"
            @click.prevent="back"
          >
            Back
          </button>
          <button
            class="primary"
            @click.prevent="next"
          >
            Next
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import {authHeader, buildServiceParamMap, router, RRS_URL} from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
import {IS_STAGING} from "../common";
import { eventBus } from "../main.js";


import axios from "axios";
  export default {
    data() {
      return {
        uniqueId : Math.random(),
        newRallyImgUrl: null,
        rallyTitle: "",
        rallyDescription: "",
        rallyType: "",
        rallyPrivacy: "",
        council: "",
        councils: [
        ],
        currentStep: 1,
        organization: "",
        showCouncil: true,
        verticals: [
          { id: 31212, name: 'Scouts / Youth Organization' },
          { id: 31211, name: 'Non-Profit' },
          { id: 31214, name: 'Team/Club' },
          { id: 31213, name: 'School/Alumni' },
          { id: 31210, name: 'Caregiving' },
          { id: 31217, name: 'Professional' },
          { id: 31216, name: 'Other' }
        ],
      };
    },
    computed: {
      ...mapState("rallies", ["newlyCreatedRallyId"]),
      rallyImgUrl() {
        return "//rallyhood-assets.s3.amazonaws.com/default/rally_avatars/1/large/rally_avatar.jpg"
        //return this.newRallyImgUrl ? this.newRallyImgUrl : "//rallyhood-assets.s3.amazonaws.com/default/rally_avatars/1/large/rally_avatar.jpg"
      }
    },
    created() {
      eventBus.$on('newAvatarSelected' + this.uniqueId, (img) => {
        this.newRallyImgUrl = img.dataUrl
        /*console.log('called newAvatarSelected' + this.uniqueId + "with params " + p.rallyId)
        this.imgParams['contextRallyId'] =  p.rallyId
        this.imgParams['contextId'] =  p.rallyId
        //Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "creator_id"=>nil, "cropping"=>{"crop_h"=>"7", "crop_w"=>"20", "crop_x"=>"0", "crop_y"=>"10"}, "content_type"=>"Binary", "context_id"=>"51060", "context_type"=>"Rally", "status"=>1, "type"=>"RallyAvatar", "context_rally_id"=>"51060", "context_channel_id"=>nil, "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}
        this.uploadFile(this.imgParams);*/
      })
    },
    watch: {
      newlyCreatedRallyId: function (newId) {
        eventBus.$emit('uploadAvatar'+ this.uniqueId, {rallyId: newId})
        router.push(`/${newId}/messages`);
      }
    },
    methods: {
      ...mapActions("rallies", ["createRally", "fetchRalliesWithInfo"]),
      back() {
        if ( this.rallyType != 31212 ){
          if (this.currentStep == 3) {
            this.currentStep = 1
          }
        } else {
          this.currentStep -= 1;
        }
      },
      exit() {
        router.push('/rallies');
      },
      next() {
        if ( this.currentStep == 1 ) {
          if ( this.rallyType == 31212 ){
            this.currentStep = 2;
          }
          else {
            this.currentStep = 3;
          }
        } else if (this.currentStep == 2) {
          this.currentStep = 3;
        } else if ( this.currentStep == 3 ) {
          console.log("creating rally!!")
          var ret = this.createRally({avatarId: 1, privacy: this.rallyPrivacy,
            title: this.rallyTitle, description: this.rallyDescription, type: "Standard",
          vertical: this.rallyType, council: this.council, parent_id: this.$route.query.parent_id});
          console.log(ret);
          this.fetchRalliesWithInfo({});

          //router.push('/rallies');
        } else {
          this.currentStep += 1;
        }
      },
      async onChangeOrganization() {
        var organization = this.organization;
        var a_relation_id;

        switch(organization) {
          case "gs":
            this.showCouncil = true;
            if (IS_STAGING()) {
              a_relation_id = 39009
            } else {
              a_relation_id = 31368;
            }
            break;
          case "bs":
            this.showCouncil = true;
            a_relation_id = 47875;
            break;
          case "jj":
            this.showCouncil = false;
            a_relation_id = 57817;
            break;
          case "other":
            this.showCouncil = false;
            break;
        }

        var jsonparams = {
          a_relation_id: a_relation_id,
          status: "active,pending",
          sort: "title",
          "page[number]": "1",
          "page[size]": "300"
        };

        var params = buildServiceParamMap(jsonparams);

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader()
          },
          url: RRS_URL() + "/rallies",
          params
        };
        const response = await axios(requestOptions);
        this.councils = [];
        var councils = response.data.data;
        for (var prop in councils) {
          this.councils.push({id: councils[prop].id, name: councils[prop].attributes.title})
        }
      },
      quit() {
        //alert('TBD: If the rally record has already been created, maybe go there, but if this has all been happening in memory, maybe just return to the dashboard?');
      }
    },
  }
</script>
