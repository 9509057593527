<template>
  <div  >
    <DashboardNav /> 
    <main class="dashboard">
      <ToolBar
        :options="toolbarOptions"
        v-on:perform="perform"
      />
        <section  class="content-area" v-if="this.rallySearchResults && this.rallySearchResults.length > 0">
          <Rally
            v-for="(rally, $index) in this.rallySearchResults"
            :key="$index"
            :rally="rally"
          > 
          </Rally>
        </section>
      <section class="content-area"   v-if="!(this.rallySearchResults && this.rallySearchResults.length > 0 && this.isDashboardLoaded)">
        <Rally
          v-for="(rally, $index) in this.rallyListing"
          :key="$index"
          :rally="rally"
        >
        </Rally>
        <div v-if="!isLoadingComplete">
        <infinite-loading ref="infLoad2" @infinite="infiniteHandler">
          <span slot="no-more"> </span>
        </infinite-loading>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { findDefaultChannelOfType } from "../common";
import RallyPaginationMixin from "../components/RallyPaginationMixin";  
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from "@capacitor/core";
import { userInfo } from "../common/user-info";
const { PushNotifications } = Plugins;

export default {
  mixins: [RallyPaginationMixin],
  name: "DashboardPage",
  data() {
    return {
      //parentRallyId: null,
      page: 1,
      isDashboardLoaded: false,
      // infiniteRallies: [],
      prevLoadCount: 0,
      prevLoadCountSameCount: 0,
      show_create: false,
      polling_int: null,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("rallies", [
      "allRallies",
      "myRallies",
      "rallyChannelsByRallyId",
      "ralliesByParent",
      "allRalliesByRallyId", "resultCountByRallyId", "rallySearchResults"
    ]),
    ...mapState("contents", ["contentById"]),
    ...mapState("user", ["status", "usersById"]),
    ...mapState("memberships", ["myMembershipByRallyId"]),
    rallyListing() {
      if (!this.isDashboardLoaded) {
        return []; // or any default value that signifies loading state
      }
      let rs = []
      if (this.parentRallyId) {
        rs = [...this.myRallies[parseInt(this.parentRallyId)]]; // Copying the array using spread operator
      } else {
        rs = [...this.myRallies]; // Copy the myRallies array
      }

      // Remove entries where rally attribute is missing
      rs = rs.filter(item => item?.rally?.attributes?.title);
      rs = rs.filter(item => item?.rally?.attributes?.viewable);
      rs = rs.filter(item => (item?.rally?.attributes?.status == "Active") || (item?.rally?.attributes?.status == "Archived" && item?.membership?.attributes?.role == "Owner" && item?.membership?.attributes?.status == "Active") );

      if (rs && Array.isArray(rs)) {
        rs.sort((a, b) => {
          if (a.rally && a.rally.attributes && a.rally.attributes.title) { 
            const titleA = a.rally.attributes.title.toUpperCase();
            const titleB = b.rally.attributes.title.toUpperCase();

            if (a.rally.attributes.status == 'Active' && b.rally.attributes.status == 'Archived'){
              return -1;
            }
            if (a.rally.attributes.status == 'Archived' && b.rally.attributes.status == 'Active'){
              return 1;
            }
            if (titleA < titleB) {
              return -1;
            }
            if (titleA > titleB) {
              return 1;
            }
            return 0; // Titles must be equal
          } else {
            console.warn("Missing rally title for item:", a); // Warn if there's a missing title
            return 0;
          }
        });
      } else {
        console.warn("rs is not an array or is undefined");
      }
      
      return rs
    },
    parentRallyId() {
      return this.$route.params.parentRallyId;
    },
    resultCount() {
      return this.resultCountByRallyId[this.parentRallyId > 0 ? this.parentRallyId : 1]
    },
    toolbarOptions() {
      let options = new Array();
      //options.push({ onClick: "openSearch", params: { context: 'my rallies' }, icon: "fa-search", label: "My Rallies" });
      options.push({ onClick: "openSearch", params: { context: 'all rallies' }, icon: "fa-search", label: "Search" });
      options.push({ onClick: "newRally", params: { }, icon: "fa-plus-circle", label: "Create Rally" });
      options.push({ onClick: "refreshAll", params: { }, icon: "fa-sync", label: "Refresh" });
      return options;
    },
    /*filteredRallies() {
      return this.allRallies.filter(function(rally) {
        return rally.rally.attributes['child-count'] == 0
      });
    }*/
  },
  created() {
    if (this.myRallies && this.myRallies.length > 1) {
      this.isDashboardLoaded = true
    } else {
      console.log("fetching all the rallies")
      this.fetchDashboardRallies()
    }
    this.clearRallySearchResults();
  },

  methods: {
    ...mapActions("rallies", ["clearRallyState", "fetchMyRalliesWithInfo", "clearRallySearchResults"]),
    ...mapActions("user", ["registerUserDevice", "fetchUsersById"]),
    ...mapActions("memberships", ["fetchMembersByRally"]),

    async fetchDashboardRallies() {
      // TODO - clear out the rally state whenever this page is loaded due to the fact that
      // rallies that the user may not be an explicit member of may be loaded into the state
      // as the user clicks on the rabbit-trail links to Hubs
      // Eventually we should keep the state of the rallies that the user is a member of in a separate state
      //await this.clearRallyState()
      console.log("FETCH DASHBOARD RALLIES")
      await this.fetchMyRalliesWithInfo({});    
      this.isDashboardLoaded = true
    },
    getContentById(id) {
      return this.contentById[parseInt(id)]
        ? this.contentById[parseInt(id)]
        : this.contentById[-1];
    },

    getContentAttribute(id, attname) {
      var c = this.getContentById(id);
      if (c && c.attributes && c.attributes[attname]) {
        return c.attributes[attname];
      } else {
        return "";
      }
    },

    /*infiniteHandler2($state) {
      if (this.page < 20 && !this.parentRallyId) {
        //keep this call from running away!
        console.log(this.page);
        this.page += 1;
       this.fetchRalliesWithInfo();
      }
    },*/
    //we have to pass in the status filter for subsequent pages so we get a correct total count...by default the status is filtered anyhow but...
    //there seems to be a bug with the backend total count
    loadNextPage(){
      this.fetchMyRalliesWithInfo({});
    },
    infiniteHandler($state) {
      if (this.prevLoadCount == 0 && this.rallyListing){
        this.prevLoadCount = this?.rallyListing.length
      } 
      if (this.rallyListing && this.prevLoadCount == this?.rallyListing.length ){
        this.prevLoadCountSameCount++
      } else {
        this.prevLoadCountSameCount = 0
      }
      if (this.prevLoadCountSameCount <  4) {
        this.prevLoadCount = this?.rallyListing.length
        this.loadNextPage()
      }
    },
    log(message) {
      this.$log.debug(message);
    },
    newRally() {
      router.push('/start');
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
  }
};
</script>
