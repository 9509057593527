<template>
  <div>
    <button
      class="danger delete-button"
      title="Delete"
      @click="openDeleteModal"
    >
      <i class="fal fa-trash"></i>
    </button>
    <div :class="[{ open: deletModalStatus }, 'modal', 'confirmation-modal']">
      <div class="shadow" @click="closeDeleteModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="closeDeleteModal"></i>
          <div >
            <div class="heading">Are you sure you want to delete this?</div>
            <div class="actions">
              <div class="left leftButton">
                <button class="secondary" @click="closeDeleteModal">Cancel</button>
              </div>
              <div class="right">
                <button class="primary" @click="doDelete">Confirm</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        deletModalStatus: false,
      };
    },
    computed: {
    },
    methods: {
      doDelete() {
        this.$emit('click');
        this.closeDeleteModal()
      },
      closeDeleteModal() {
        this.deletModalStatus = false;
      },
      openDeleteModal() {
        this.deletModalStatus = true;
      }
    }
  }
</script>
