<template>
  <main class="gate rally-gate" style="position:absolute;overflow:scroll;">
    <div class="together">
      <img src="/rallyhood-white.svg" />
      Together with purpose!
    </div>
    <div style="margin-top: 25px; margin-bottom: 15px;" v-if="errorMessages.length">
      <div style="padding-left: 10px; text-align: left; color: red; padding-top: 10px;" v-for="message in errorMessages" :key="message" class="error">** {{ message }}</div>
    </div>
    <form>
      <p v-if="accountStatus.error" style="color:red;text-align:center">Account Creation Error Occured: {{ userFacingMessage }}</p>
        <div class="form-group half">
          <label for="firstName">First Name</label>
          <input id="firstName" v-model="firstName" type="text" required/>
        </div>
        <div class="form-group half">
          <label for="lastName">Last Name</label>
          <input id="lastName" v-model="lastName" type="text" required/>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="email" type="text" required/>
        </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="password-input-container">
              <input v-model="password" :type="showPasswordConfirmation ? 'text' : 'password'" id="password" name="password" autocomplete="current-password" class="password-input" @click="scrollTop"/>
              <a href="#" class="toggle-password" @click.prevent="togglePasswordVisibility('passwordConfirmation')">
                {{ showPassword ? 'Hide' : 'Show' }} 
              </a>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Confirm Password</label>
            <div class="password-input-container">
              <input v-model="passwordConfirmation" :type="showPasswordConfirmation ? 'text' : 'password'" id="password" name="passwordConfirmation" autocomplete="current-password" class="password-input" @click="scrollTop"/>
              <a href="#" class="toggle-password" @click.prevent="togglePasswordVisibility('passwordConfirmation')">
                {{ showPassword ? 'Hide' : 'Show' }}
              </a>
            </div>
          </div>
        <div class="form-group" >
          <label for="timezone">Time Zone</label>
          <select id="timezone" v-model="timezone" @blur="validateTimezone" required>
            <option  v-for="t in timezoneEntryList" :key="t[0]" :value="t[1]">{{t[0]}}</option>
          </select>
        </div>
        <div class="actions">
          <button
            class="primary rally-join"
            @click.prevent="createMyAccount"
          >
            Create Account
          </button>
         <div> <br/></div>
        </div>
      <hr />
      <div class="trouble">
        <div>
          <router-link class="link-button" to="/login">Return to Login</router-link>
          or
          <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import {TIMEZONE_MAPPING} from "@/common";
  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        timezone: 'UTC',
        errorMessages: [],
        timezoneValid: false, // Flag to track timezone validity
        passwordMatchValid: false,
        passwordComplexityValid: false,// Array to store error messages
        showPassword: false,
        showPasswordConfirmation: false,
      };
    },
    computed: {
      ...mapState("user", ["accountStatus"]),
      timezoneEntryList(){
        return Object.entries(TIMEZONE_MAPPING)
      },
      userFacingMessage() {
        if (this.accountStatus.errorMessage && this.accountStatus.errorMessage.indexOf("emails.email has already been taken") >= 0){
            return "A user account exists with that email."
        } else {
          return this.accountStatus.errorMessage
        }
      },
    },
    created(){

    },

    mounted() {
      this.clearUsersState()
    },
    methods: {
      ...mapActions("user", ["createAccount", "clearUsersState"]),
      togglePasswordVisibility(field) {
        if (field === 'password') {
          this.showPassword = !this.showPassword
        } else if (field === 'passwordConfirmation') {
          this.showPasswordConfirmation = !this.showPasswordConfirmation
        }
      },
      scrollBack(){
        this.$refs["loginform"].style = ''
      },
      scrollTop(){
        if (this.screenWidth < 960) {
          this.$refs["loginform"].style = 'position: fixed; top: 25px; width: 100%'
        }
      },
      scrollToTop () {
        this.$el.scrollTop = 0
      },
      async createMyAccount() {
        this.clearUsersState()
        if (!this.validateForm()) {
          return;
        }
        const userData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          timezone: this.timezone,
          password: this.password,
        };
        await this.createAccount(userData);
        if (!this.accountStatus.error){
          this.$store.dispatch('user/flashSuccess', 'Account Created!');
          this.navToRallies();
        }
      },
      validateForm() {
        // Reset error messages
        this.errorMessages = [];
        try {
          this.validatePassword()
          this.validatePasswordMatch()
        } catch (e){
          console.log(e)
        }
        // Check if any required field is empty
        if (!this.firstName.trim()) {
          this.errorMessages.push("First Name is required.");
        }
        if (!this.lastName.trim()) {
          this.errorMessages.push("Last Name is required.");
        }
        if (!this.email.trim()) {
          this.errorMessages.push("Email is required.");
        }
        if (!this.timezoneValid) {
          this.errorMessages.push("Timezone is required.");
        }
        if (!this.password.trim()) {
          this.errorMessages.push("Password is required.");
        }
        if (!this.passwordMatchValid) {
          this.errorMessages.push("Passwords do not match.");
        }
        if (!this.passwordComplexityValid) {
          this.errorMessages.push("Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long.");
        }
        // Return true if the form is valid, otherwise return false
        return this.errorMessages.length === 0;
      },
      validatePassword() {
        // Validate password complexity on each input change
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        this.passwordComplexityValid = passwordRegex.test(this.password);
      },
      validatePasswordMatch() {
        // Validate password match when passwordConfirmation field is changed
        this.passwordMatchValid = this.password === this.passwordConfirmation;
      },
      validateTimezone() {
        // Validate timezone when the field loses focus (blurred)
        this.timezoneValid = !!this.timezone;
      },
      navToRallies(){
         this.$router.push("/rallies")
      }
    }
  };
</script>
<style scoped>
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  flex-grow: 1;
  padding-right: 60px; /* Adjust as needed for space for the toggle link */
}

.toggle-password {
  color: #008295;
  position: absolute;
  right: 10px; /* Adjust as needed for exact positioning */
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
}
</style>
