<template>
  <div class="body">
    <div class="form-group stacked-labels checkboxes">
      <div v-for="(channel, index) in sortedChannels"
           :key="channel.id"
           class="drag-item channel-row"
           :draggable="true"
           @dragover="dragOver($event, index)"
           @dragleave="dragLeave"
           @drop="drop($event, index)">
        <div class="channel-state">
          <label>
            <input type="checkbox" :checked="channel.attributes['state'] === 'on'" @change="changeState(channel)">
            <div class="square"></div>
          </label>
        </div>
        <div class="channel-name">
          <template v-if="editingChannelId === channel.id">
            <input type="text" v-model="channel.attributes['name']" @blur="saveName(channel)" @keydown.enter="saveName(channel)" >
          </template>
          <template v-else>
            <label @click="startEditing(channel)">{{ channel.attributes['name'] }}</label>
          </template>
        </div>
        <div class="channel-security">
          <div v-if="hasChannelSettings(channel)">
            <router-link
                :to="{ name: 'edit_channel', params: { rallyId: rallyId, channelSlug: channel.attributes['slug'] }}"
                class="action"
            >
              {{ shortLabel(channel) }}
            </router-link>
          </div>
          <div v-else>
            {{ shortLabel(channel) }}
          </div>
        </div>
        <div class="drag-handle" v-if="isDesktopSize">
          <i class="fas fa-arrows-alt" @mousedown="dragStart($event, channel, index)"
             ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Vue from "vue";
  export default {
    props: ['rallyId', 'isDesktopSize'],
    name: "ManageFeatures",
    data() {
      return {
        dragging: false,
        dragChannel: null,
        editingChannelId: null
      };
    },
    computed: {
      ...mapState("rallies", ["rallyChannelsByRallyId"]),
      channels() {
        return this.rallyChannelsByRallyId[parseInt(this.$route.params.rallyId)] || [];
      },
      sortedChannels() {
        return [...this.channels]
            .filter(channel => channel.attributes['type'] !== 'Analytics')
            .sort((a, b) => a.attributes['sort-order'] - b.attributes['sort_order']);
      }
    },
    mounted() {
      const dragItems = document.querySelectorAll('.drag-item');
      dragItems.forEach((item) => {
        item.addEventListener('mousemove', this.dragMove);
        item.addEventListener('mouseup', this.dragEnd);
      });
    },
    beforeDestroy() {
      const dragItems = document.querySelectorAll('.drag-item');
      dragItems.forEach((item) => {
        item.removeEventListener('mousemove', this.dragMove);
        item.removeEventListener('mouseup', this.dragEnd);
      });
    },
    methods: {
      ...mapActions("channels", ["updateChannel"]),
      dragStart(event, channel, index) {
        this.dragChannelId = channel.id;
        const dragHandle = event.target.closest('.drag-handle');
        if (!dragHandle) {
          event.preventDefault();
          return;
        }
        this.draggedItemIndex = index;
        event.target.dataset.draggedItemIndex = index;
        //event.dataTransfer.setData('text/plain', ''); // Required for other browsers
      },
      dragMove(event) {
        if (this.draggedItemIndex !== null) {
          // Handle drag movement if needed
        }
      },
      dragEnd() {
        this.draggedItemIndex = null;
      },
      dragOver(event, index) {
        event.preventDefault();
        this.dragOverIndex = index;
        event.target.classList.add('drag-over');
      },
      dragLeave(event) {
        event.target.classList.remove('drag-over');
      },
      drop(event, index) {
        event.preventDefault();
        const channelId = this.dragChannelId;
        const channelIndex = this.channels.findIndex(channel => channel.id === channelId);
        if (channelIndex > -1) {
          if (this.dragOverIndex > -1 && this.dragOverIndex !== channelIndex) {
            const removed = this.channels.splice(channelIndex, 1)[0];
            this.channels.splice(this.dragOverIndex, 0, removed);
            this.updateSortOrder();
          }
        }
        this.dragChannelId = null;
        this.dragOverIndex = -1;

        const dragOverElements = document.querySelectorAll('.drag-over');
        dragOverElements.forEach((element) => {
          element.classList.remove('drag-over');
        });
      },
      changeState (channel) {
        if (channel.attributes['state'] === 'off') {
          channel.attributes['state'] = 'on';
        } else {
          channel.attributes['state'] = 'off';
        }
        this.updateChannel({ channel });
      },

      editName(channel) {
        // Handle inline editing logic here
      },
      hasChannelSettings(channel) {
        if (channel.attributes['type'].toLowerCase() === 'directory' ||
            channel.attributes['type'].toLowerCase() === 'groups' ||
            channel.attributes['type'].toLowerCase() === 'members' ||
            channel.attributes['type'].toLowerCase() === 'files' ||
            channel.attributes['type'].toLowerCase() === 'gallery' ||
            channel.attributes['type'].toLowerCase() === 'links' ||
            channel.attributes['type'].toLowerCase() === 'messagewall' ||
            channel.attributes['type'].toLowerCase() === 'rallies') {
          return true
        }
        return false
      },
      startEditing(channel) {
        this.editingChannelId = channel.id;
      },
      saveName(channel) {
        this.editingChannelId = null;

        //channel.attributes['name'] = (index + 1) * 100;
        this.updateChannel({channel: channel})
      },
      readWrite(channel) {
        if (channel.attributes['members-read'] == 'admin') {
          return 'admin_only'
        } else if (channel.attributes['members-write'] == 'admin') {
          return 'members_read'
        } else if (channel.attributes['members-write'] == 'members' && channel.attributes['members-read'] != 'public') {
          return 'all_members'
        } else if (channel.attributes['members-read'] == 'public') {
          return 'public_read'
        }
        return 'admin_only'
      },
      shortLabel(channel) {
        switch (this.readWrite(channel)) {
          case "admin_only":
            return 'Admin Only'
          case "members_read":
            return 'Read Only'
          case "all_members":
            return 'All Members'
          case "public_read":
            return 'Public'
        }
      },
      updateSortOrder() {
        // Simulate API call to update sort order
        setTimeout(() => {
          // Update the sort_order of each channel
          this.channels.forEach((channel, index) => {
            channel.attributes['sort-order'] = (index + 1) * 100;
            this.updateChannel({channel: channel})
          });
        }, 500);
      }
    }
  }

</script>

<style scoped>
  .drag-item {
    cursor: move;
  }

  .drag-over {
    background-color: #eaf2fd;
  }
</style>