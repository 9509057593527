<template>
  <nav  class="subchannels-menu">
    <div
      v-for="subchannel in this.filteredSubchannels"
      :class="['subchannel', { active: active == subchannel['user-id']}]"
      :key="subchannel.id"
      @click="clearNewCount(subchannel)"
    >
   
    <div>
      <UserAvatar :pUserId="recipientUserId(subchannel)" :pSize="'25px'"
                  p-line-height="1.5rem"
                  p-font-size="1.0rem"
                  type="author-avatar"
                  style="float:left;
                  width: 8%; padding-right: 10px;"/>
      <div class="name" style="float:left;width: 50%; padding-top: 4px; margin-left: 18px;">
        {{ firstName(subchannel) }}  {{ lastName(subchannel) }}
      </div>
      <div class="rally">
        {{ subchannel.rally_name }}
      </div>
      <span
        v-if="newCount(subchannel) > 0"
        class="unread-count"
      >
        {{ newCount(subchannel)}} 
      </span>
      </div>
    </div>
    <article class="empty-state">
      <i class="fal fa-comments icon"></i>
      <div class="cta">
        Ready to Create
        <br />
        a Direct Message?
      </div>
      <div class="subtitle">
        <ol>
          <li>Go to any Rally</li>
          <li>Click on a User's Avatar</li>
          <li>Create and send your Message</li>
          <li>The recipient will be notified</li>
        </ol>
      </div>
      Looking for your Messaging history?
      <div class="subtitle">
        (To see your complete list of messages, log into Rallyhood on your desktop or mobile browser)
      </div>
    </article>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
  export default {
    props: {
      active: Number,
      subchannels: Array,
    },
    computed: {
      ...mapState("user", ["user", "status", "usersById"]),
      ...mapState("channels", ["channelsByRecipientUserId", "newPrivateMessageCountBySenderName"]),
      filteredSubchannels(){
          return this.subchannels.filter((s) =>  {
            return !this.userIsSelf(this.recipientUser(s)) && (this.lastName(s) && this.lastName(s) + "" != "undefined")
          });
      }
    },
    methods: {
    ...mapActions("channels", ["clearPrivateMessageCountByUserName"]),
     /* channelRallyId(subchannel){
        console.log("channelRallyId")
        console.log(subchannel)
        console.log(this.channelsByRecipientUserId[parseInt(subchannel['user-id'])])
        return this.channelsByRecipientUserId[parseInt(subchannel['user-id'])]?.attributes['rally-id'] 
        //return 123
      },*/
      firstName(subchannel){
        return this.recipientUser(subchannel)?.attributes['first-name']
      },
      lastName(subchannel){
        return this.recipientUser(subchannel)?.attributes['last-name']
      },
      clearNewCount(subchannel){
        this.clearPrivateMessageCountByUserName({name: this.firstName(subchannel) + " " + this.lastName(subchannel)})
        this.$emit('perform', 'selectSubchannel', subchannel['user-id'])
      },
      newCount(subchannel){
        let nc = this.newPrivateMessageCountBySenderName[this.firstName(subchannel) + " " + this.lastName(subchannel)]
        return nc
      },

      recipientUserId(subchannel){
        return subchannel['user-id']
      },
      recipientUser(subchannel){
        return this.usersById[subchannel['user-id']] 
      },
      userIsSelf(u){
        return (u?.id &&  parseInt(u.id) == parseInt(this.user.user_id))
      }
    }
  };
</script>
