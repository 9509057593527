<template>
  <article v-on:click="$emit('perform', 'openContainer', content)">
    <div class="wrapper">
      <div class="title">{{ content.attributes.title }}</div>
    </div>
  </article>
</template>

<script>
  import ContentMixin from "@/components/ContentMixin.vue";

  export default {
    name: "Board",
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    mixins: [
      ContentMixin
    ],
  };
</script>
