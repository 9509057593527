<template>
  <div class="register">
    <div v-if="showButton">
      <button :class="[cssClass, 'register-button']" @click="toggleModal">
        {{ buttonText }}
      </button>
    </div>
    <div
      :class="[{ open: modalStatus }, 'modal']"
      style="max-height: 100vh;overflow: scroll;"
    >
      <div class="shadow" @click="toggleModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">{{ headingText }}</div>
        <div
          class="form-group"
          v-for="payable in this.payables"
          :key="payable.id"
        >
          <div>
            <span class="label"
              >{{ payable.attributes.title }} ({{
                "$" + payable.attributes.cost / 100 + ".00"
              }})
            </span>
            <select v-if="payable['isLoaded']" v-model="payable[selectedAttribute]" class="quantity">
              <option v-for="i in 20" :key="i" :value="i - 1">{{
                i - 1
              }}</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <textarea v-model="notes" placeholder="Notes"></textarea>
        </div>
        <div class="form-group">
          <textarea v-model="addInfo" placeholder="Additional Info"></textarea>
        </div>
        <div class="actions">
          <button class="secondary" @click="toggleModal">Cancel</button>
          <button class="primary" @click="createParticipations">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { userInfo } from '../common/user-info';
export default {
  data: function() {
    return {
      modalStatus: false,
      fundraiser: null,
      shareId: null,
      notes: null,
      addInfo: null,
      showButton: true
    };
  },
  props: ["contextId", "rallyId", "payables", "participationType", "hideButton", "modalCloseMethod"],
  watch: {
    // The key 'payables' corresponds to the prop name
    payables: {
      immediate: true, // This makes sure the watcher is triggered once when the component is created
      handler(newPayables) {
        if (newPayables && newPayables.length) {
          newPayables.forEach(payable => {
            var participant = this.getParticipant(payable.id);
            if (participant) {
              payable[this.selectedAttribute] = participant ? participant.attributes.quantity : 0;
            }
            payable['isLoaded'] = true;
          });
        }
      }
    }
  },
  created() {
    this.fundraiser = this.contentById[this.contextId];
    this.shareId = this.fundraiser.relationships["context-share"].data.id;
    if(this.hideButton) {
      this.showButton = false
      this.modalStatus = true
    }
    if(this.isArchived) {
      this.showButton = false
    }
  },
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("user", ["usersById"]),
    ...mapState("participations", ["participationById"]),
    buttonText() {
      if(this.participationType === 'waitlist') {
        return 'Manage My Waitlist'
      } else {
        return 'Express Interest'
      }
    },
    headingText() {
      if(this.participationType === 'waitlist') {
        return 'Manage My Waitlist'
      } else {
        return 'Express Interest'
      }
    },
    isArchived() {
      return this.fundraiser &&
          this.fundraiser.attributes['status'] == 2
    },
    participantTypeKey() {
      if(this.participationType === 'waitlist') {
        return "waitlist"
      } else {
        return "interested"
      }
    },
    selectedAttribute() {
      return this.participationType === 'express_interest'
          ? 'quantityInterestSelected'
          : 'quantityWaitlistSelected';
    },
    user() {
      return userInfo();
    },
    cssClass() {
      switch (this.state + "") {
        case "1":
          return "warning";
        case "2":
          return "hollow";
        default:
          return "primary";
      }
    }
  },
  methods: {
    ...mapActions("participations", ["createOrUpdateParticipation"]),
    getParticipant(id) {
      return this.participationById[id + this.participantTypeKey + this.user.user_id];
    },
    createParticipations() {
      this.payables.forEach(payable => {
        const selectedQuantity = payable[this.selectedAttribute];

        // Your saving logic here, using selectedQuantity
      });
      this.payables.forEach(payable => {
        console.log("SAVING!!!!!!")
        console.log(payable[this.selectedAttribute])
        if (payable[this.selectedAttribute] || payable[this.selectedAttribute] == 0) {
          console.log("UMMMM")
          var participant = this.getParticipant(payable.id);
          this.createOrUpdateParticipation({
            id: participant ? participant.id : null,
            contentUid: payable.id,
            shareId: payable.relationships["context-share"].data.id,
            participationStatus: this.participantTypeKey,
            participantType: this.participantTypeKey,
            quantity: payable[this.selectedAttribute],
            rallyId: this.rallyId
          });
        }
      });
      this.toggleModal();
    },
    toggleModal() {
      this.modalStatus = !this.modalStatus;
      if(this.modalCloseMethod) {
        this.modalCloseMethod();
      }

    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
