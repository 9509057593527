<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
        </div>
        <div class="right">
          <button
            @click.prevent="$emit('perform', 'save')"
            class="primary"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Message Title (optional)</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
        <div class="form-group">
          <label for="body">Body</label>
          <vue-editor
            v-model="content.attributes.body"
            :editorToolbar="customToolbar"
          ></vue-editor>
        </div>
        <ContentAttachments
          v-if="content"
          ref="cAttach"
          :readOnly="false"
          :content="content"
          :rallyId="parseInt(this.rallyId)"
          :channelId="parseInt(this.channelId)"
          v-on:perform="perform"
        />
      </div>
    </form>
  </article>
</template>

<script>
  export default {
    name: "Message",
    props: {
      channelId: Number,
      rallyId: Number,
      content: Object,
    },
    data() {
      return {
        customToolbar: [
          "bold",
          "italic",
          "underline",
          { list: "bullet" },
          { color: [] },
          { background: [] },
          //"image",
          "link",
          { align: [] }
        ],
      };
    },
    methods: {
      perform(method, params) {
        if (this[method]) {
          params ? this[method](params) : this[method]();
        } else {
          this.$emit("perform", method, params);
        }
      },
    }
  };
</script>
