<template>
  <div class="body">
    <form>
      <div class="form-group stacked-labels checkboxes">
        <label>
          <input name="my_reminders" type="checkbox" v-model="userNotificationSettings.attributes['my-reminders']"   />
          <div class="square"></div>
          <b>My Reminders</b> - Reminders about events coming up on your calendar.
        </label>
        <label>
          <input name="daily_digest" type="checkbox" v-model="userNotificationSettings.attributes['daily-digest']"  />
          <div class="square"></div>
          <b>Daily Digest</b> - Daily summaries about what is happening in your rallies.
        </label>
        <label>
          <input name="event_assigned" type="checkbox" v-model="userNotificationSettings.attributes['event-assigned']"  />
          <div class="square"></div>
          <b>Event Assignments</b> - Notifications when an admin has assigned you to a signup or an event.
        </label>
        <label>
          <input name="notify_immediately" type="checkbox"  v-model="userNotificationSettings.attributes['notify-immediately']"  />
          <div class="square"></div>
          <b>Notify Immediately</b> - Urgent notifications from your rallies.
        </label>
        <label>
          <input name="invited" type="checkbox"  v-model="userNotificationSettings.attributes['invited']"  />
          <div class="square"></div>
          <b>Invited</b> - Alerts when you've received a new invitation.
        </label>
        <label>
          <input name="invitation_sent" type="checkbox"  v-model="userNotificationSettings.attributes['invitation-sent']"  />
          <div class="square"></div>
          <b>Invitation Sent</b> - Notifications when invitations have been successfully sent.
        </label>
        <label>
          <input name="invitation_accepted" type="checkbox"  v-model="userNotificationSettings.attributes['invitation-accepted']"  />
          <div class="square"></div>
          <b>Invitation Accepted</b> - Notifications when a friend has accepted an invitation.
        </label>
        <label>
          <input name="join_requested" type="checkbox"  v-model="userNotificationSettings.attributes['join-requested']"  />
          <div class="square"></div>
          <b>Join Requested</b> - Notifications when someone has requested to join your rally.
        </label>
        <label>
          <input name="join_approved" type="checkbox"  v-model="userNotificationSettings.attributes['join-approved']"  />
          <div class="square"></div>
          <b>Join Approved</b> - Alerts when your request to join a rally has been accepted.
        </label>
        <label>
          <input name="content_shared" type="checkbox"  v-model="userNotificationSettings.attributes['content-shared']"  />
          <div class="square"></div>
          <b>Content Shared</b> - Notifications when someone shares your message.
        </label>
        <hr/>
        <label>
          <input type="checkbox" v-model="toggleAll" @click="toggleAllCheckboxes">
          <div class="square"></div>
          <b>Receive all notifications</b> - Click here to set all notifications, on or off.
        </label>
        <div class="actions">
          <button class="secondary" @click="cancelSaveComment()" >Cancel</button>
          <button class="primary" @click.prevent="saveUserInfo()" >Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {userInfo} from "@/common/user-info";

export default {
  name: "NotificationSettings",
  data: function() {
    return {
      user_id: null,
      toggleAll: false
    };
  },
  computed: {
    ...mapState("user", ["usersById", "userNotificationSettings"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
  },
  created() {
    this.loadPageData();
  },
  methods: {
    ...mapActions("user", ["updateUserInfo"]),
    async loadPageData() {
      this.user_id = userInfo().user_id;
    },
    saveUserInfo(){
      this.updateUserInfo({updatedUser:this.currentUser, userNotificationSettings: this.userNotificationSettings, updateAll: this.updateAll, userPrefs: []})
      this.$emit('saveComplete');
      this.$store.dispatch('user/flashSuccess', 'Notification Settings updated!');
    },
    toggleAllCheckboxes() {
      const toggleValue = !this.toggleAll;
      Object.keys(this.userNotificationSettings.attributes).forEach((key) => {
        if (key !== 'toggle-all') {
          this.userNotificationSettings.attributes[key] = toggleValue;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>