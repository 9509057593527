<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import { userInfo } from "../common/user-info"
  import { totalParticipantCount } from "../common";
  export default {
    computed: {
      ...mapState("participations", ["participationById"]),
      contentUid() {
        return this.content.id;
      },
      shareId() {
        return this.content.relationships["context-share"].data.id;
      },
      myCount() {
        if (this.myParticipation ) {
          return this.myParticipation.attributes.quantity; 
        } else {
          return 0;
        }
      },
      remainingSpots(){
        return this.maxParts - this.partCount
      },
      maxParts() {
        return this.content.attributes["max-participants"];
      },
      isFull() {
        if (this.maxParts == 0) {
          return false;
        } else if (this.partCount >= this.maxParts) {
          return true;
        } else {
          return false;
        }
      },
      myUserId() {
        return userInfo().user_id;
      },
      myParticipation() {
        return this.participationById[
          this.contentUid + "follower" + this.myUserId
        ];
      },
      cssClass() {
        return this.isFull
          ? ""
          : this.myCount > 0
          ? "warning"
          : "primary";
      },
      label() {
        return this.isFull
          ? "FULL"
          : this.myCount > 0
          ? "SIGNED UP"
          : "SIGN UP";
      },
      registered() {
        return this.state !== null;
      }
    }
  };
</script>
