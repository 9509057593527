<template>
  <article v-if="content" >
    <div  class="title">
      <i class="fal fa-folder file-type"></i> 
      {{ content.attributes.title }}
    </div>
    <footer>
      <div  class="left">
        <UserAvatar :pUserId="content.attributes['creator-id']" pSize="35px;" pFontSize="1.0rem" pLineHeight="2.2rem"/>
        <div class="author-name">
          {{ byLine }}
        </div>
        <div class="created-at">
          {{ when }}
        </div>
      </div>
      <div class="actions">
         <ContentElipsisMenu
             :content="content"
             :rallyId="this.rallyId"
             :channelId="this.channelId"
             contentType="Links"
             canPin="true"
             canShare="true"
             hideDelete="true"
             hideReport="true"/>
      </div>

    </footer>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin";

  export default {
    name: "Board", 
    mixins: [
      ContentMixin,
    ],
     props: ["content", "rally", "channel"]
  };
</script>
