<template>
  <article class="content listed file">
    <i class="fal fa-folder file-type" @click="open">
    </i>
    <div v-if="!isSharedFromAnotherRally" @click="open" class="title">
      {{ content.attributes.title }} 
    </div>
    <div v-if="isSharedFromAnotherRally" @click="open" class="shared-title">
      {{
        content.attributes.title
      }} <span class="shared">(SHARED by {{sharedByName}})</span>
    </div>
    <div class="author-name">
      {{ byLine }}
    </div>
    <div class="other-info">
      {{ content.attributes["subcontents-count"] == 0 ? "Empty" : content.attributes["subcontents-count"] + " Items" }} |
      {{ formatDate(content.attributes["created-at"]) }}
    </div>
    <footer>
      <div class="actions">
        <ShareIcon :content="content" :rallyId="rallyId"  :channelId="channelId" contentType="Files"/>
      </div>
    </footer>
  </article>
</template>


<script>
import ContentMixin from "../../ContentMixin";
import { mapState, mapActions, mapGetters } from "vuex";
import shared from "@/components/content/wall_message/Shared.vue";

export default {
  name: "Folder",
  mixins: [
    ContentMixin,
  ],
  props: {
    channel: Object,
    rally: Object,
    content: Object
  },
  computed: {
    ...mapState("shares", ["sharesByContentId"]),
    shared() {
      return shared
    },

  },
  methods: {

    open() {
      this.$emit('perform', 'openContainer', this.content);
    },
  }
};
</script>
