<template>
  <component 
    :is="elementType"
    :class="theType"
    :src="avatarFile"
  >

    <div v-if="imageMissing" :style="spiritColor + '; width: ' + pSize + '; height: ' + pSize + '; font-size: ' + fontSizeComputed + '; line-height: ' + lineHeightComputed" class="initials" @click="emitMaybeDirectMessage" >
      {{ initials }}
    </div>

    <img v-if="imagePresent"
         :src="avatarFile"
         :style="'width: ' + pSize + '; height: ' + pSize"
         @click="emitMaybeDirectMessage"  @error="rallyAvatarOnError" 
         />

    <DirectMessageModal :pUserId="pUserId" :uniqueId="uniqueId" :pRallyId="rallyId"/>
  </component>
</template>

<script>
import MemberMixin from "./MemberMixin";
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { userInfo } from "../common/user-info"
import { eventBus } from "../main.js";

export default {
  mixins: [MemberMixin],
  name: "UserAvatar",
  props: ["pUserId", "type", "pMembership", "pRallyId", "pSize", "pFontSize", "pLineHeight"],
  data() {
      return {
       uniqueId : Math.random(),
       reloadAttempted: false,
       rallyAvatarLoaded: true
      };
  },
  computed: {
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("memberships", ["profileByMembershipId", "membersByMemberId", "membersByUserIdByRallyId"]),

    fontSizeComputed() {
      if (this.pFontSize) {
        return this.pFontSize;
      } else {
        return "1.8rem";
      }
    },
    lineHeightComputed() {
      if (this.pLineHeight) {
        return this.pLineHeight;
      } else {
        return "3.2rem";
      }
    },

    userAvatarContent() {
      //var avid = parseInt(_user.relationships.avatar.data.id)
      if (this?.pMembership?.relationships?.avatar?.data?.id && this?.pMembership?.relationships?.avatar?.data?.id != "-1" && this.contentById[this.pMembership.relationships.avatar.data.id + ""].attributes){
        return this.contentById[this.pMembership.relationships.avatar.data.id + ""]
      }
      else if (this?.theUser?.attributes?.avatar){
        return this.theUser.attributes.avatar
      } else if ( !this?.theUser?.relationships?.avatar?.data?.id ){
        return this.defaultAvatarsByType["user"]
      } else {
        return this.contentById[parseInt(this.theUser.relationships.avatar.data.id)]
      }
    },
    pUser() {
      return this.usersById[parseInt(this.pUserId)];
    },
    userId() {
      return this?.pUserId;
    },
    //avatar: function() {
      //return //this.theUser.relationships ? this.contentById[parseInt(this.theUser.relationships.avatar.data.id)] : null;
    //},
    avatarFile: function() {
      if (this.theUser) {
        var _avatar = this.userAvatarContent 
        if (typeof(_avatar) === 'string' || _avatar instanceof String){
          return _avatar
        }
        if (_avatar) {
            var imgUrl = _avatar['medium_url'] ? _avatar['medium_url'] : _avatar.attributes['medium-url']
            return (imgUrl && imgUrl.indexOf("https://") < 0) ? "https://" + imgUrl : imgUrl
        }
      }
      return null;
    },
    elementType() {
      return this.imageMissing ? "div" : "div";
    },
    imageMissing() {
      return !this.rallyAvatarLoaded || !this.avatarFile || this.avatarFile.indexOf("missing.png") >= 0;
    },
    imagePresent() {
      return this.rallyAvatarLoaded && this.avatarFile && this.avatarFile.indexOf("missing.png") < 0;
    },
    membersByUserId() {
      return this.membersByUserIdByRallyId[this.rallyId];
    }, 
    membership(){
      return this.membersByUserId ?  this.membersByUserId[this.pUserId] : null 
    },
    initials() {
      let firstInitial = ""
      let lastInitial = ""
      try{
        firstInitial =  (this?.theUser?.attributes["first-name"] && this.theUser.attributes["first-name"].length > 0) ?  this.theUser.attributes["first-name"][0] : "";
        lastInitial =  (this?.theUser?.attributes["last-name"] && this?.theUser?.attributes["last-name"].length > 0) ? this.theUser.attributes["last-name"][0] : "";
        if (this.membership && this.membership.attributes){
          firstInitial = this.membership.attributes["first-name"] ? this.membership.attributes["first-name"][0] : firstInitial
          lastInitial = this.membership.attributes["last-name"] ? this.membership.attributes["last-name"][0] : lastInitial
        }
      } catch (e)
      {
        console.log(e)
      }
      return firstInitial + lastInitial
    },
    // Choose one of a five colors "randomly", but assign it permenantly to a person
    // by using the last digit of their user_id as the key.
    spiritColor() {
      const colors = ["#40B5BC", "#5AC6C6", "#8FD0CA", "#03DACE", "#42ABC5"];
      if (this.theUser && this.theUser.id ){
      const spiritNumber = Math.floor(parseInt(this.theUser.id.split("").pop()) / 2);
      return `background-color: ${colors[spiritNumber]};`;
      } else {
        return `background-color: ${colors[0]};`;
      }
      //return "#40B5BC"
    },
    theUser: function() {
      //use logged in user if pUser is not provided
      //this.user may not have the full complement of attributes...pull from usersById
      return this.pUserId ? this.pUser : this.usersById[parseInt(userInfo().user_id)]
    },
    theType: function() {
      return this.type ? this.type : "author-avatar"; 
    },
    rallyId(){
        return  this.pRallyId ? this.pRallyId : this.$route.params.rallyId ? this.$route.params.rallyId : null
    },
    checkReloadData(){
        if (!this.reloadAttempted && this.rallyId && !this.membership && this.pUserId){
          this.loadData()
          return false
        }
        return true
    }
  },
  created() {

   /* if (this.user.id){
   // this.fetchUsersById({ userIds : [this.user.id] });
    }
    if (this.pUserId && !this.usersById[this.pUserId]) {
     // this.fetchUsersById({ userIds : [this.pUserId] });
    }*/
  },

  methods: {
   ...mapActions("user", ["fetchUsersById"]), 
   ...mapActions("memberships", ["fetchMembersByRallyAndUserId"]), 
   loadData(){
    // console.log("reloading data for " + this.pUserId)
    // this.reloadAttempted = true
      //this.fetchMembersByRallyAndUserId({rallyId: this.rallyId, userIds: [this.pUserId]})
   },
   rallyAvatarOnError(){
      console.log("rallyavatarnotloaded")
      this.rallyAvatarLoaded = false
   },
   emitMaybeDirectMessage(){
     console.log("emitting direct message!")
     eventBus.$emit('maybeDirectMessageEvent' + +this.uniqueId);
   },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
