<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back"> 
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
          <div v-if="!isNewLifecycle" class="left"> 
            <!--<button class="danger" @click.prevent="doDelete" >Delete</button>-->
            <DeleteIcon type="button"  :channel-id="this.channel.id"  :content="content" :rallyId="this.rallyId"  />
          </div>
        </div>
        <div class="right">
          <button
            @click.prevent="$emit('perform', 'save')"
            class="primary"
            type="submit"
          >Save</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Link Board Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
      </div>
    </form>
  </article>
</template>

<script>
  import ContentMixin from "@/components/ContentMixin.vue";

  export default {
    name: "Folder",
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    mixins: [
      ContentMixin
    ],
    computed: {
      isNewLifecycle(){
        return this.content.attributes['lifecycle'] == "new" && !this.content.id
      }
    }
  };
</script>
