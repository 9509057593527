<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fas fa-chevron-left icon"></i> 
            Cancel
          </a>
        </div>
        <div class="right">
          <DeleteIcon type="button"   :content="content" :rallyId="this.rallyId"  :channel-id="this.channelId" />
          <button
            @click.prevent="$emit('perform', 'save')"
            class="primary save_button"
            type="submit"
          >Save</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            v-model="content.attributes.description"
            id="description"
            name="description"
          />
        </div>
        <div class="form-group">
          <label for="available">Available</label>
          <select  v-model="content.attributes['max-participants']">
            <option value="0">No Limit</option>
            <option
              v-for="i in 100"
              :key="i"
              :value="i"
            >
              {{ i }} available
            </option>
          </select>
        </div>
        <div class="form-group checkboxes">
          <label>
            <input  v-model="show_names_to_admin"
              v-on:change="onCheckChange"
              type="checkbox"
            />
            <div class="square" />
            Show names of assigned to Admin only
          </label>
        </div>
        <EventFields :contentId="content.id" :optional="true" :populateOnCreate="false" />
        <ContentAttachments  ref="cAttach" v-on:perform="perform"
                             :readOnly="false"
                             :content="content"
                             :rallyId="parseInt(this.rallyId)"
                             :channelId="parseInt(this.channelId)"
                              :context-share="this.contextShare"/>
      </div>
      
    </form>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin";
  import EventFields from "../../EventFields";
  import { mapState, mapActions } from "vuex";
    import moment from "moment-timezone";
  export default {
    name: "SignupList",

    components: {
      EventFields,
    },
    mixins: [
      ContentMixin,
    ],
    data() {
      return {
        show_names_to_admin:  true
      };
    },
     props: ["content", "channel", "rally", "contextShare"],
     computed: {
        isNewLifecycle(){
          return this.content.attributes['lifecycle'] == "new" && !this.content.id
        }
      },
      mounted() {
        this.content.attributes['status'] = "1"
      },
      created() { 
        this.show_names_to_admin  = (this.content.attributes['privacy'] == 1)
      },
     methods: {
      ...mapActions("contents", ["createContents"]),
      perform(method, params) {
        if ( this[method] ) {
          params ? this[method](params) : this[method]();
        } else {
          this.$emit('perform', method, params);
        }
      },
      onCheckChange(){
        console.log("on check change")
        this.content.attributes.show_names_to_admin = this.show_names_to_admin
      }
    }
  }
</script>
