<template>
  <div
    :class="type"
    :ref="'alert'"
    id="vue-alert-area"
  >
    {{ message }}
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fadingOut: false,
      };
    },
    computed: {
      alert() {
        return this.$store.getters['user/alert'];
      },
      type() {
        return this.alert.type;
      },
      message() {
        return this.alert.message;
      },
    },
    created() {
      setTimeout( function() {
        this.$refs.alert.classList.toggle("fadeOut");
      }.bind(this), 5000);
    },
  };
</script>
