<template>
  <div
    v-if="content"
    class="lightbox-wrapper"
    @click.self="$emit('perform', 'handleLightboxHide')"
  >
    <button
      v-if="!editing"
      class="lightbox-close"
      @click="$emit('perform', 'handleLightboxHide')"
    >
      <i class="fal fa-times"></i>
    </button>
    <article
      v-if="editing"
      class="as-form content details lightbox photo"
    >
      <form>
        <div class="toolbar">
          <div class="left">
            <a @click="cancel" class="back">
              <i class="fas fa-chevron-left icon"></i>
              Cancel
            </a>
          </div>
          <div class="right">
            <button
              @click.prevent="save"
              class="primary"
              type="submit"
            >Save</button>
          </div>
        </div>
        <div class="wrapper">
          <div class="form-group">
            <label for="title">Title</label>
            <input
              v-model="content.attributes.title"
              id="title"
              name="title"
              type="text"
            />
          </div>
        </div>
      </form>
    </article>
    <article
      v-else
      class="content details lightbox photo"
      ref="lightbox"
    >
      <div class="image"> 
        <img :src="imageUrl" />
        <button v-if="!isFirst" class="previous" @click="previous">
          <i class="fa fa-chevron-left"></i>
        </button>
        <button v-if="!isLast" class="next" @click="next">
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>
      <div class="title">
        {{ abbrev(content.attributes.title) }}
      </div>
      <footer>
        <div class="left">
          <UserAvatar :pUserId="content.attributes['creator-id']" pSize="35px;" pFontSize="1.0rem" pLineHeight="2.2rem"/>
          <div class="author-name">
            {{ byLine }}
          </div>
          <div class="created-at">
            {{ when }}
          </div>
        </div>
        <div class="right actions" >
          &nbsp; &nbsp;
          <Sentiments
            :message-id="content.id"
            :likeOnly=false
            :shareId="content.relationships['context-share'].data.id"
          />
          <a class="action download" target="_blank" :href="content.attributes['original-url']" >
            <i class="fal fa-download"></i>
          </a>
           <content-flagging  :content="content" :rallyId="this.$attrs.rallyId" />
          <a
            v-if="canEdit"
            @click="edit"
            class="action"
          > 
            <i class="fal fa-pencil"></i> 
          </a>

          <DeleteIcon   :content="content" :rallyId="this.rallyId" :channelId="this.channel ? this.channel.id : this.channelId"  v-on:perform="perform" />
           <!--not supported in legacy <ShareIcon :content="this.content" :rallyId="parseInt(this.rallyId)"  :channelId="this.channelId" contentType="Gallery"/>-->
        </div>
      </footer>
      <Comments
        :shareId="content.relationships['context-share'].data.id"
        :avatarFile="content.attributes['creator-avatar']"
        :channelId="this.channelId"
        :rallyId="this.rallyId"
      />
    </article>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import ContentMixin from "../../ContentMixin";
  import BinaryMixin from "../../BinaryMixin";
  export default {
    mixins: [
      BinaryMixin
    ],
    props: {
      photo: {
        default: () => {
          return {};
        }
      },
      channel: {
        default: null
      },
      channelId: {
        default: null
      },
      photos: {
        default: () => {
          return [];
        }
      },
      rally: {
        default: null
      },
    },
    data() {
      return {
        editing: false,
        content: null
      }
    },
    computed: {
      imageUrl() {
        return this.content.attributes['original-url'];
      },
      isFirst() {
        return this.imageUrl == this.photos[0].attributes['original-url'];
      },
      isLast() {
        return this.imageUrl == this.photos[this.photos.length - 1].attributes['original-url'];
      },
      imageIndex(){
        var cid = this.content.id
        console.log("cid is " + cid)
        return this.photos.findIndex(function(p){
          console.log("p.id" + p.id)
          console.log("cid" + cid)
          return p.id == cid
          });
      },
    },
    watch: {
      photo: function(newVal, oldVal) {
        console.log(newVal)
        this.content = newVal
      },
    },
    created() {
      this.content = this.photo
    },
    methods: {
      ...mapActions("contents", ["createContents", "updateContent", "deleteContent"]),
     destroy: function(c) {
      this.deleteContent({ id: c.id, rallyId: this.rallyId }); 
      c.deleted = true
      this.editingId = null
     },
      abbrev(name){
      if (name && name.length > 20){
        return name.substring(0,17) + "..."
      } else{
        return name
      }
    },
      cancel() {
        this.editing = false;
      },
      edit() {
        this.editing = true;
      },
      download() {
        return ""
      },
      next() {
        console.log("calling next")
        console.log("image index is")
        console.log(this.imageIndex)
        if (this.imageIndex == this.photos.length -1 ){
          this.content =  this.photos[0]
        } else {
          this.content = this.photos[this.imageIndex + 1]
        }
      },
      perform(method, params) {
        if ( this[method] ) {
          params ? this[method](params) : this[method]();
        } else {
          this.$emit('perform', method, params);
        }
      },
      previous() {
        if (this.imageIndex ==  0 && this.photos.length > 1){
          this.content =  this.photos[this.photos.length -1]
        } else {
          this.content = this.photos[this.imageIndex - 1]
        }
      },
      save() {
        //this.$emit('perform', 'save')
        this.updateContent({id: this.content.id, content: this.content, rallyId: this.$attrs.rallyId})
        this.editing = false;
      },
    },
  }
</script>
