<template>
    <div class="actions" v-click-outside="hideActions">
      <!--<ShareIcon v-if="!hideShareComputed" :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId" :contentType="this.contentType"/>-->
      <i @click="showElipsis = !showElipsis" class="action ellipsis-button more fa-ellipsis-h fas"/>
      <div class="action ellipsis-button more" style="position: absolute; right: 2rem;">
        <ul v-if="showElipsis" class="additional">
          <li v-if="isAdmin || isMe" @click="deleteMember" class="option">
            <i class="fal fa-trash"></i>Delete
          </li>
          <li v-if="!isMe" class="option" @click="emitMaybeDirectMessage">
            <i class="fal fa-comment"></i>Private Message
          </li>
          <li v-if="isAdmin && !memberIsOwner && !memberIsInvited" class="option" @click="promoteToOwner">
            <i class="fal fa-user-circle"></i>Make Owner
          </li>
          <li v-if="isAdmin && !memberIsOwner && !memberIsAdmin && !memberIsInvited" class="option" @click="promoteToAdmin">
            <i class="fal fa-user-plus"></i>Make Admin
          </li>
          <li v-if="isAdmin && !memberIsOwner && !memberIsAdmin && memberIsInvited" class="option" @click="promoteToAdmin">
            <i class="fal fa-user-plus"></i>Make this user an admin when they accept
          </li>
          <li v-if="isAdmin && memberIsAdmin && memberIsInvited" class="option" @click="demoteToFriend">
            <i class="fal fa-user"></i>Make this user a friend when they accept
          </li>
          <li v-if="isAdmin && (memberIsOwner || memberIsAdmin) && !memberIsInvited" class="option" @click="demoteToFriend">
            <i class="fal fa-user"></i>Make Friend
          </li>
          <li v-if="isAdmin && memberIsInvited" class="option" @click="openReinviteModal">
            <i class="fal fa-envelope"></i>Resend Invitation
          </li>
        </ul>
      </div>
      <DirectMessageModal :pUserId="this.memberUser.id" :uniqueId="uniqueId" :pRallyId="this.rallyId"/>
      <div :class="[{ open: showResendInvitationModal }, 'modal'] ">
        <div class="shadow" @click="closeReinviteModal"></div>
        <div class="body">
          <i class="fal fa-times exit" @click="closeReinviteModal"></i>
          <div class="heading">RESEND INVITATION</div>
          <div class="sub-heading"> TO {{ this.member.attributes['email']}}</div>
          <div class="info">
            <div class="sub-heading-sub-text">
              <p>Invitation Message: (select which message you which to use or enter a custom message)</p>
            </div>
            <div class="form-group">
              <div>
                <label>
                  <input name="invitationTextSelector"
                         ref="originalRadio"
                         type="radio"
                         value="original"
                         v-model="selectedInvitationType"
                         style="float: left; width: 5%; margin-top: 7px; margin-right: 5px;"/>
                  <div class="sub-heading-sub-text">Original Invitation</div>
                  <div class="circle"></div>
                </label>
                <label>
                  <input name="invitationTextSelector"
                         ref="defaultRadio"
                         type="radio"
                         value="default"
                         v-model="selectedInvitationType"
                         style="float: left; width: 5%; margin-right: 5px;"/>
                  <div class="sub-heading-sub-text"  style="margin-top: 30px;">Current Rally Invitation</div>
                  <div class="circle"></div>
                </label>
                <textarea v-model="invitationText"
                          rows="5" placeholder=""
                          class="invitationTextInput"
                          style="font-size: .8rem;">

                </textarea>
              </div>
              <span class="close" @click="closeReinviteModal"></span>
              <p>Are you sure you want to resend this invitation?</p>
              <button class="secondary" @click="closeReinviteModal">No</button>
              <button class="primary" @click="reInvite">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
import UserPermissionMixin from './UserPermissionMixin.vue';
import { userInfo } from "../common/user-info";
import { RH_URL, router} from "../common";
  export default {
    mixins: [UserPermissionMixin],
    props: ['rallyId', 'memberId', 'member', 'memberUser'],
    data: function() {
      return {
        invitationText: "",
        selectedInvitationType: "original",
        showElipsis: false,
        showResendInvitationModal: false,
        uniqueId : Math.random(),
      };
    },
    created () {
      this.invitationText = this.invitationsByMemberId[this.member.id].attributes['invitation-text'];
      this.selectedInvitationType = "original";
    },
    watch: {
      selectedInvitationType(newType) {
        this.setInvitationText(newType);
      }
    },
    computed: {
    ...mapState("memberships", ["invitationsByMemberId"]),
    ...mapState("rallies", ["allRalliesByRallyId"]),
    ...mapState("user", ["status", "user", "usersById"]),
    isMe() {
      return userInfo().user_id == this.memberUser.id
    },
    memberIsAdmin(){
      return this.member.attributes.role == "Admin"
    },
    memberIsInvited(){
      return this.member.attributes.status == "Invited"
    },
    memberIsOwner(){
      return this.member.attributes.role == "Owner"
    },
    openRhWebUniqueId(){
      return this.content.id
    },
    rallyIdForUserAvatar(){
      if (this.ownerShare && this.ownerShare.attributes && this.ownerShare.attributes['context-rally-id']){
        return parseInt(this.ownerShare?.attributes['context-rally-id'])
      } else {
        return parseInt(this.rallyId)
      }
    },
      shareId() {
        return this.content.relationships['context-share'].data.id
      }
    },
    methods: {
      ...mapActions("memberships", ["deleteMembership", "resendInvitation", "updateMemberRole"]),
      closeReinviteModal() {
        this.showResendInvitationModal = false;
      },
      async deleteMember(){
        try {
          await this.deleteMembership({rallyId: this.rallyId, memberId: this.member.id})
        } catch (e){
          console.log(e)
        }
        if (this.isMe){
            router.push("/rallies")
        } else {
            window.location.reload()
        }

      },
      demoteToFriend(){
        this.member.attributes.role = "Friend"
        this.updateMemberRole({ member: this.member, rallyId: this.rallyId })
        this.hideActions()
      },
      emitAssignEvent() {
        this.hideActions()
      },
      emitMaybeDirectMessage(){
        eventBus.$emit('maybeDirectMessageEvent' + +this.uniqueId);
      },
      openReinviteModal() {
        this.showResendInvitationModal = true;
        this.hideActions();
      },
      promoteToAdmin() {
        this.member.attributes.role = "Admin"
        this.updateMemberRole({ member: this.member, rallyId: this.rallyId })
        this.hideActions()
      },
      promoteToOwner(){
        this.member.attributes.role = "Owner"
        this.updateMemberRole({ member: this.member, rallyId: this.rallyId })
        this.hideActions()
      },
      reInvite(){
        this.resendInvitation({ member: this.member, invitationText: this.invitationText });
          this.showResendInvitationModal = false;
          this.$store.dispatch('user/flashSuccess', 'The invitation has been re-sent.');

      },
      setInvitationText(type) {
        this.invitationText = type === 'default'
            ? this.allRalliesByRallyId[this.rallyId].rally.attributes['invitation-message']
            : this.invitationsByMemberId[this.member.id].attributes['invitation-text'];
      },
      hideActions() {
        this.showElipsis = false;
      },
    }
  }
</script>
