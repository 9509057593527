<template>
  <div class="body">
    <form>
      <div class="form-group stacked-labels checkboxes">
        <label>
          <input type="checkbox" v-model="mergeDigest">
          <div class="square"></div>
          <b>Merge digests into one email</b>
        </label>
      </div>
      <div class="actions">
        <button class="secondary" @click="cancelSaveComment()" >Cancel</button>
        <button class="primary" @click.prevent="saveUserInfo()" >Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import UxMixin from "@/components/UxMixin.vue";
import {mapActions, mapState} from "vuex";
import {userInfo} from "@/common/user-info";

export default {
  name: "DigestSettings",
  data: function() {
    return {
      user_id: null,
      preferences: [],
    };
  },
  computed: {
    ...mapState("user", ["usersById", "userPrefs"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
    mergeDigest: {
      get() {
        const userPrefs = this.userPrefs.find(pref => pref.attributes['preferences-type'] === 2)
        return userPrefs ? userPrefs.attributes.value === 1 : false
      },
      set(value) {
        const userPrefs = this.userPrefs.find(pref => pref.attributes['preferences-type'] === 2)
        if (userPrefs) {
          userPrefs.attributes.value = value ? 1 : 0
        }
      }
    },
  },
  watch: {
    mergeDigest(value) {
    }
  },
  created() {
    this.loadPageData();
    const userPrefs = this.userPrefs.find(pref => pref.attributes['preferences-type'] === 2)
    if (userPrefs) {
      if(userPrefs.attributes.value == 1) {
        this.mergeDigest = 1
      } else {
        this.mergeDigest = 0
      }
    }
  },
  methods: {
    ...mapActions("user", ["updateUserInfo"]),
    async loadPageData() {
      this.user_id = userInfo().user_id;
    },
    log(message) {
      this.$log.debug(message);
    },
    saveUserInfo(){
      const userPreferencesToUpdate = this.userPrefs.filter(pref => pref.attributes['preferences-type'] === 2);
      this.updateUserInfo({updatedUser:this.currentUser, userNotificationSettings: {}, updateAll: this.updateAll, userPrefs: userPreferencesToUpdate})
      this.$emit('saveComplete');
      this.$store.dispatch('user/flashSuccess', 'Digest Settings updated!');
    },
  }
}
</script>
