<template>
  <article v-if="checkReloadData">
    <img class="avatar" :src="callAvaUrl(eventAvatar)" />
    <ContentElipsisMenu v-if="this.content.attributes.editable"
                        :content="content"
                        :rallyId="this.rallyId"
                        :channelId="this.channelId"
                        contentType="Fundraiser"
                        hide-sentiments="true"
                        :openRhWebOnEdit="true"
                        :can-manage="true"
                        :can-promote="false"
                        :can-share="true"/>
    <div class="left">
      <h1 class="title">{{ content.attributes.title }}</h1>
      <div class="description" v-html="content.attributes.description"></div>
    </div>
    <div class="right" v-if="this.content">
      <PayParticipation :contextId="content.id" :rallyId="this.rallyId" :theChannelId="this.channelId"/>
      <h2>Cost</h2>
      <ul class="costs">
        <li v-for="payable in this.payables" :key="payable.id">
          {{ payable.attributes.title }} ({{ formatCurr(payable.attributes.cost) }})
          <div class="availability">{{ soldText(payable) }}</div>
          <div>
            <a v-if="organizer && organizer.attributes && organizer.attributes['contact-email']"  class="minor" :href="'mailto:' + organizer.attributes['contact-email'] + '?subject=Requesting a refund for ' + content.attributes.title + ' : ' + payable.attributes.title" >
              Request a Refund
            </a>
          </div>
        </li>
      </ul>
      <div v-if="organizer && organizer.attributes &&  organizer.attributes['contact-phone']">
        <h2>Organizer</h2>
        <div>
          <a v-if="organizer && organizer.attributes && organizer.attributes['contact-email']"  href="'mailto:' + organizer.attributes['contact-email']" >
            {{ this.organizer.attributes["contact-email"] }}
          </a>
        </div>
        <div>
          <a href="">
            {{ this.organizer.attributes["contact-phone"] }}
          </a>
        </div>
      </div>
      <div>
        <h2 style="margin-bottom: -35px;">Attachments</h2>
        <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(this.channelId)"/>
      </div>
    </div>
  </article>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatCurrency, findOrganizerParticipation, formatDate, avaUrl } from "../../../common";
import { userInfo } from "../../../common/user-info"
import ContentPayableMixin from "../../ContentPayableMixin";
import {eventBus} from "@/main";
import ContentMixin from "@/components/ContentMixin.vue";
export default {
  mixins: [ContentPayableMixin],
  name: "PayableGroupDetails",
  props: ["id", "rally", "channel", "content"],
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("shares", ["sharesByContentId"]),

    eventAvatar() {
      return this.contentById[this.content.relationships.avatar.data.id];
    },
    organizer() {
      return findOrganizerParticipation(this.content.relationships.participations.data, this.participationById);
    }
  },
  created() {
    eventBus.$on('editPay', () => {
      eventBus.$emit('openRHWebPaymentsEdit'  + this.openRhWebUniqueId())
    })
    eventBus.$on('managePay', () => {
      eventBus.$emit('openRHWebPaymentsManage'  + this.openRhWebUniqueId())
    })
    this.user_id = userInfo();
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    doFormatDate(time) {
      return moment(time).format("MMMM, DD YYYY");
    },
    doFormatTime(time) {
      return moment(time).format("h:mm a");
    },
    formatCurr(val) {
      return formatCurrency(val);
    },
    openRhWebUniqueId(){
      return this.content.id
    },
    perform(method, params) {
      if ( this[method] ) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit('perform', method, params);
      }
    },
    soldText(p) {
      var amt = p.attributes["amount"];
      var maxp = p.attributes["max-participants"];
      var totp = p.attributes["participant-total"];

      var txt = totp + " Slots Filled";
      if (!p.attributes["infinite-inventory"]) {
        if (amt == 0) {
          return "SOLD OUT";
        }
        var diff = maxp - amt;
        txt = diff + " Slots Filled " + " of " + maxp;
      }
      return txt;
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
