<template>
  <div>
  <div v-if="checkReloadData">
    <main class="hub">
      <ToolBar
          :back="back"
          :channelId="channelId"
          v-if="toolbarOptions"
          :options="toolbarOptions"
          v-on:perform="perform"
          style="margin-top: 75px;"
          ref="toolbarRef"
      />

      <section  class="content-area" v-if="this.rallySearchResults && this.rallySearchResults.length > 0 && this.pageLoaded && this.$refs.toolbarRef.searchQuery != ''">
        <Rally
            v-for="(rally, $index) in this.rallySearchResults"
            :key="$index"
            :rally="rally"
        >
        </Rally>
      </section>

      <section  class="content-area"  v-if="!(this.rallySearchResults && this.rallySearchResults.length > 0) && (this.pageLoaded && this.$refs.toolbarRef.searchQuery == '')">
        <Rally
          v-for="(rally, $index) in this.rallyListing"
          :key="$index"
          :rally="rally"
        >
        </Rally>
        <div v-if="!isLoadingComplete">
          <infinite-loading ref="infLoad2" @infinite="infiniteHandler2">
            <span slot="no-more"> </span>
          </infinite-loading>
        </div>
      </section>

    </main>
  </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { findDefaultChannelOfType } from "../common";
import RallyReloadMixin from "../components/RallyReloadMixin";
import RallyPaginationMixin from "../components/RallyPaginationMixin";
import ContentMixin from "@/components/ContentMixin.vue";
import ContentReloadMixin from "@/components/ContentReloadMixin.vue";
import { userInfo } from "../common/user-info";
export default {
  mixins: [
    ContentMixin,
    ContentReloadMixin
  ],
  props: ["channel"],
  data() {
    return {
      prevLoadCountSameCount: 0,
      loadAttempted: false,
      pageLoaded: false,
      searchQuery: ""
    }
  },
  //mixins: [RallyReloadMixin],
  /*data() {
    return {
      rallyId: 0
    }
  },*/
  computed: {
    ...mapState("memberships", ["membersByRallyId"]),
    ...mapState("user", ["usersById"]),
    ...mapState("rallies", ["ralliesByParent", "allRallies", "maxPageByRallyId", "resultCountByRallyId", "allRalliesByRallyId", "rallySearchResults"]),
    
    resultCount() {
      return this.resultCountByRallyId[this.rallyId]
    },
    selectedRally() {
      return this.allRalliesByRallyId[this.rallyId];
    },
    back() {
      return ""
    },
    checkReloadData() {
      if (!this.loadAttempted && !this.ralliesByParent[parseInt(this.rallyId)]) {
        this.myLoadPageData()
        return false
      }
      return true
    },
    isLoadingComplete(){
      let lc = (this.rallyListing && this.rallyListing.length > 0 &&  this.resultCount && this.resultCount > 0) && (this.rallyListing.length >= this.resultCount  )
      return lc
    },
    rallyId() {
      return this.$route.params.rallyId;
    },
    rallyListing() {
      let rs = []
      rs = [...this.ralliesByParent[parseInt(this.rallyId)]]; // Copying the array using spread operator

      // Remove entries where rally attribute is missing
      rs = rs.filter(item => item?.rally?.attributes?.title);
      rs = rs.filter(item => item?.rally?.attributes?.viewable);
      rs = rs.filter(item => (item?.rally?.attributes?.status == "Active") || (item?.rally?.attributes?.status == "Archived" && item?.membership?.attributes?.role == "Owner" && item?.membership?.attributes?.status == "Active") );

      if (rs && Array.isArray(rs)) {
        rs.sort((a, b) => {
          if (a.rally && a.rally.attributes && a.rally.attributes.title) {
            const titleA = a.rally.attributes.title.toUpperCase();
            const titleB = b.rally.attributes.title.toUpperCase();

            if (a.rally.attributes.status == 'Active' && b.rally.attributes.status == 'Archived'){
              return -1;
            }
            if (a.rally.attributes.status == 'Archived' && b.rally.attributes.status == 'Active'){
              return 1;
            }
            if (titleA < titleB) {
              return -1;
            }
            if (titleA > titleB) {
              return 1;
            }
            return 0; // Titles must be equal
          } else {
            console.warn("Missing rally title for item:", a); // Warn if there's a missing title
            return 0;
          }
        });
      } else {
        console.warn("rs is not an array or is undefined");
      }

      return rs


      //return this.ralliesByParent[parseInt(this.rallyId)];
    },
    channelSlug() {
      // Used in place of channel.type.
      return this.$route.params.channelSlug
    },
    members() {
      return this.membersByRallyId[this.rallyId];
    },

    toolbarOptions() { 
      let options = new Array();
      if (this.isEditable){
        options.push({ onClick: "newRally", params: { }, icon: "fa-plus-circle", label: "Create Rally" });
      }
      options.push({ onClick: "openSearch", params: { context: 'child rallies' }, icon: "fa-search", label: "Search" });
      
      options.push({ onClick: "refreshAll", params: { }, icon: "fa-sync", label: "Refresh" });

      return options;
    },

    isEditable() {
      return this?.selectedRally?.rally?.attributes['editable'] === true;
    }
  },
  watch: {
    /*ralliesByParent: function(newval, oldval) {
      this.$refs.infLoad2.stateChanger.loaded();
      if (
        (!newval && oldval) ||
        (newval && oldval && newval.length < oldval.length + 6)
      ) {
        this.$refs.infLoad2.stateChanger.complete();
      }
    }*/
    ralliesByParent: function(newval, oldval) {
        if (this.$refs.infLoad2 && newval && this.isCompleted(newval)) {
          //if (this.$refs.infLoad2 && newValLen >= this.resultCountByRallyId[this.rallyId]){
          this.$refs.infLoad2.stateChanger.complete();
        } else {
          console.log("l...")
          if (this.$refs.infLoad2){
            this.$refs.infLoad2.stateChanger.loaded();
          }
        }
  }
  },
  mounted() {
    this.searchQuery = this.$refs.toolbarRef?.searchQuery;
    this.pageLoaded = true;
  },
  created() {
    this.clearRallySearchResults();
    this.fetchRalliesByParent({rallyId: this.rallyId})
    this.fetchRalliesWithInfo({rallyId: this.rallyId});
    this.loadAttempted = true
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("rallies", ["clearRallySearchResults", "fetchRalliesWithInfo", "fetchRalliesByParent"]),
 
    infiniteHandler2($state) {
      this.myLoadPageData()
    },
    isCompleted(newval) {
      var newValLen = newval && newval[this.rallyId] ?  newval[this.rallyId].length : 0
      if (this.$refs.infLoad2 && newval){
          return (newValLen >= this.resultCountByRallyId[this.rallyId])
      }
      return false
    },
    async myLoadPageData() {
      this.loadAttempted = true
      await this.fetchRalliesWithInfo({rallyId: this.rallyId});

      if (this.isCompleted(this.ralliesByParent)) {
        this.$refs.infLoad2.stateChanger.complete();
      } else {
        await this.fetchRalliesByParent({rallyId: this.rallyId})
      }
    },
    newRally() {
      router.push({ path: '/start', query: { parent_id: this.rallyId } });
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
