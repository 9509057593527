<template>
  <div :class="[thumbnailCount, 'form-group', 'attachments-area']">
    <div class="progress-bar" :style="progressBarStyle"></div>
   <UploadLimitModal ref="uploadLimitModalRef"/>
    <PhotoLightbox
      :channelId="channelId"
      :channel="channel"
      :photo="currentPhoto"
      :photos="imageList" 
      v-on:perform="perform"
    />
    <div v-if="!readOnly" class="upload-wrapper as-attachment">
      <div
      v-if="!readOnly"
      @click="showImageAttachmentPicker"
      class="upload-wrapper as-attachment"
      >
        <i class="fal fa-camera"></i>
        <input
          hidden
          ref="imageAttachmentPicker"
          type="file"
          multiple
          @change="onImageUpload"
          accept="image/*"
        />
      </div>
    </div>
    <div
      v-if="!readOnly"
      @click="showFileAttachmentPicker"
      class="upload-wrapper as-attachment"
    >
      <i class="fal fa-paperclip"></i>
      <input
        hidden
        ref="fileAttachmentPicker"
        type="file"
        multiple
        @change="onFileAttachmentUpload"
        accept="image/*, .ppt, .pptx, .pdf, .doc, .docx, .xml, .xls, .xlsx, .csv, .txt, .rtf, .mov, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </div>
    <div v-if="isLoaded">
      <h2 v-if="theAttachments && theAttachments.length > 0" style="margin-bottom: 5px;">
        Attachments</h2>
      <AttachedFile
        ref="attachedFile"
        v-on:perform="perform"
        :newImageName="newImageName"
        :readOnly="readOnly"
        v-for="attachment in theAttachments"
        :attachment="attachment"
        :key="attachment.id"
        :rallyId="rallyId"
        :channelId="channelId"
        :channel="channel"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import UploadLimitModal from "./UploadLimitModal.vue";
import {
  router,
  slugToTypeObjectMap,
  findDefaultChannelOfTypeForRally,
  kebabCase
} from "../common";

export default {
  components: {
    UploadLimitModal
  },
  data() {
    return {
      fileLimitUploads: false,
      currentPhoto: null,
      uploadProgress: 0,
      tempKey: null,
      imgData: null,
      lightboxIndex: 0,
      lightboxVisible: false,
      fileData: null,
      isLoaded: false
    };
  },
  props:["content","channelId","rallyId","readOnly", "contextType","contextShare", "channel"],
  computed: {
    ...mapState("contents", ["uploadCount","allContentByContextTypeAndId", "contentById"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    correctContextType() {
       if (!this.content){
         return "Message"
       } else if (this.content.attributes["type"] == "WallMessage" || this.content.attributes["activity-type"] == 'WallMessage')  {
         return "Message"
       } else if (this.content.attributes["type"] == "CalendarEvent" || this.content.attributes["activity-type"] == 'CalendarEvent') {
         return "Event"
       } else if (this.content.attributes["type"] == "PayableGroup" || this.content.attributes["type"] == 'GivePayableGroup' || this.content.attributes["type"] == 'PayableEvent') {
         return "Content"
       } else {
         if (this.content.attributes['activity-type']) {
           return this.content.attributes['activity-type']
         } else {
           return this.content.attributes["type"]
         }
       }
    },
    newOrExistingContentId() {
      if (this.content.attributes['activity-id']) {
        return this.content.attributes['activity-id']
      } else if (this.content.id) {
        return this.content.id;
      } else {
         var ct = this.contentById[this.content.tempKey]
         return ct ? ct.id : ""
        //return this.contentById[this.content.tempKey].id;
      }
    },

    theAttachments() {
      var atts = this.allContentByContextTypeAndId[
          parseInt(this.newOrExistingContentId)
          ];
      if (atts && atts.length > 0) {
        atts = atts.filter(a => {
          return a.attributes["type"] == "Attachment" || a.attributes["type"] == "File" || a.attributes["type"] == "Binary";
        });
      }

      return atts && atts.length > 2 ? atts.slice(0, atts.length ) : !atts ? [] : atts;
    },
    newImageName() {
      return this.imgData ? this.imgData.info.name : "";
    },
    progressBarStyle() {
      if ( this.uploadCount == 0 ) {
        return 'display: none;';
      } else {
        return "width: " + this.uploadProgress + "%;upload-count:" + this.uploadCount;
      }
    },
    attachmentsLength() {
      return this.theAttachments ? this.theAttachments.length : 0;
    },
    thumbnailCount() {
      if (this.attachmentsLength == 1) {
        return "single";
      } else if (this.attachmentsLength == 2) {
        return "double";
      } else if (this.attachmentsLength > 2) {
        return "triple";
      } else {
        return "";
      }
    },
    imageList() {
      if (this.attachmentsLength > 0) {
        return this.theAttachments.map(a => {
          return a;
        });
      } else {
        return [];
      }
    }
  },
   created() {
    //fetch after upload context_rally_id=39019&context_type=Message&context_channel_id=294210&context_id=19033866&page%5Bnumber%5D=1&page%5Bsize%5D=6&sort=contents.created_at+desc&include=shares%2Ccreator%2Ccreator_avatar%2Cparticipations%2Cavatar&user_id=303137&content_type=Binary&type=Attachment
    //started GET "/api/v1/contents.json?content_type=Binary&context_channel_id=294210&context_id=19033135&context_type=Message
    //&include=shares&page%5Bsize%5D=100&sort=contents.id"
     this.getAttachments()

  },
  watch: {
    allContentByContextTypeAndId: function(newById, oldById) {
      if (this.uploadProgress > 0) {
        this.uploadProgress = 95;
      }
    }
  },
  methods: {
    ...mapActions("contents", ["fetchContents", "uploadFile"]),
    ...mapActions("shares", [ "shareContent"]),
    closeFileLimitModal(){
      this.fileLimitUploads = false;
    },
    displayMessageUploadProgress() {
      setTimeout(
        function() {
          if (this.uploadProgress <= 90) {
            this.uploadProgress += 3;
            this.displayMessageUploadProgress();
          } else {
            this.uploadProgress = 0;
          }
        }.bind(this),
        500
      );
    },
    async getAttachments() {
      await this.fetchContents({
        contentType: "Binary",
        type: ["Attachment", "File"],
        contextRallyId: this.rallyId,
        contextChannelId: this.channelId,
        contextId: this.newOrExistingContentId,
        contextType: this.correctContextType,//this.content.attributes["type"],//"Message",
        include: "shares",
        pageNumber: 1,
        customPageSize: 100,
        sort: "content.id",
        ignorePagination: true
      });

      this.isLoaded = true
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
    showLightbox(image) {
      this.currentPhoto = image;
    },
    handleLightboxHide() {
      this.currentPhoto = null;
    },

    /* file
"/api/v1/contents.json" for 3.129.232.47 at 2021-05-18 03:23:05 +0000
Processing by Api::V1::ContentsController#create as JSON
  Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "content_type"=>"Binary", "context_id"=>"19033135", "context_type"=>"Message", "type"=>"Attachment", "context_channel_id"=>"294210", "context_rally_id"=>"39019", "creator_id"=>303137, "status"=>1, "include"=>"creator,creator_avatar", "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}
*/

    /* photo 
    Started POST "/api/v1/contents.json" for 3.129.232.47 at 2021-05-17 20:01:37 +0000
Processing by Api::V1::ContentsController#create as JSON
  Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "content_type"=>"Binary", "context_id"=>"19033299", "context_type"=>"Message", "type"=>"Attachment", "context_channel_id"=>"306610", "context_rally_id"=>"41405", "creator_id"=>303137, "status"=>1, "include"=>"creator,creator_avatar", "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}
[Aws::DynamoDB::Client 200 0.073021 0 retries] query(consistent_read:false,scan_index_forward:true,index_name:"staging_rh_channel_content_index_channel_id",table_name:"staging_rh_channel_content",key_conditions:{"channel_id"=>{comparison_operator:"EQ",attribute_value_list:[{n:"306610"}]}},query_filter:{},attributes_to_get:nil)
*/

    onImageUpload(e) {
      if (e.target.files.length > 10){
        this.$refs.uploadLimitModalRef.showModal()
      } else {
        for (var i =0;i < e.target.files.length; i++){
            this.uploadFileAttachment(e.target.files[i], this.newOrExistingContentId, this.channelId, this.correctContextType, "Photo");
        }
      }
    },
    onFileAttachmentUpload(e) {
      if (e.target.files.length > 10){
        this.$refs.uploadLimitModalRef.showModal()
      } else {
        for (var i =0;i < e.target.files.length; i++){
          this.uploadFileAttachment(e.target.files[i], this.newOrExistingContentId, this.channelId, this.correctContextType);
        }
      }
    },
    showFileAttachmentPicker() {
      this.$refs.fileAttachmentPicker.click();
    },
    showImageAttachmentPicker() {
      this.$refs.imageAttachmentPicker.click();
    },

    uploadFileAttachment(f, contextId, chanId, contextType, type = "Attachment") {
      let reader = new FileReader();
      this.fileData = f;
      reader.onload = e => {
        var params = {
          contentType: "Binary",
          contextId,
          contextType,
          type: "Attachment",
          contextChannelId: chanId,
          contextRallyId: this.rallyId,
          attachment: reader.result.split(",")[1],
          attachmentContentType: f.type,
          attachmentFileName: f.name
        };

        this.displayMessageUploadProgress();
        this.uploadFile(params);
      };
      try {
        reader.readAsDataURL(f);
      } catch (e) {
        console.log("upload file error....")
        console.log(e);
      }

      // this.displayUploadProgress();
    },
    uploadImageMessageAttachment(img) {
      this.tempKey = "TK" + Math.floor(Math.random() * 10000);
      this.imgData = img;
      this.uploadFile({
        rallyId: this.rallyId,
        contextRallyId: this.rallyId,
        contentType: "Binary",
        contextId: this.newOrExistingContentId,
        contextType: this.correctContextType,//this.content.attributes["type"],//"Message",
        type: "Attachment",
        contextChannelId: this.channelId,
        attachment: img.dataUrl.split(",")[1],
        attachmentContentType: img.info.type,
        attachmentFileName: img.info.name,
        tempKey: this.tempKey
      });
      this.displayMessageUploadProgress();
    },


/*
image share to gallery




Started PUT "/50776/messages/19052475" for 3.128.43.247 at 2022-06-14 21:31:58 +0000
Started POST "/50776/messages/19052477/shares/neo_create?share%5Bcontext_channel_id%5D=375661&share%5Bcontext_rally_id%5D=50776&share[context_type]=Channel&share[context_id]=375661" for 3.128.43.247 at 2022-06-14 21:31:58 +0000
Processing by ContentsController#update as HTML
  Parameters: {"utf8"=>"✓", "authenticity_token"=>"3OoML7MY+0puB/EHQmfs5IqT7e9fJ3WJ262vlUgfrqMpvLjzr385vREsJt0N2vdyqlAZH1BLC1PqXjRTZyJOSQ==", "content"=>{"title"=>"jjjjjtttt", "body"=>"<p>jjjttt</p>"}, "context_id"=>"root", "rally_id"=>"50776", "channel_id"=>"messages", "id"=>"19052475"}
  User Load (0.6ms)  SELECT `users`.* FROM `users` WHERE `users`.`deleted_at` IS NULL AND `users`.`id` = 303137 ORDER BY `users`.`id` ASC LIMIT 1
  UserEmail Load (0.5ms)  SELECT `user_emails`.* FROM `user_emails` WHERE `user_emails`.`user_id` = 303137 AND `user_emails`.`primary` = TRUE LIMIT 1
ETHON: performed EASY effective_url=http://rrs.staging.rallyhood.com:80/api/v1/rallies/50776.json?include=avatar%2Cchild_count%2Cmember_count%2Cparent response_code=200 return_code=ok total_time=0.096331
ETHON: performed EASY effective_url=http://rmems.staging.rallyhood.com:80/api/v1/memberships.json?include=user&rally_id=50776&user_id=303137 response_code=200 return_code=ok total_time=0.093071
ETHON: performed EASY effective_url=http://rchans.staging.rallyhood.com:80/api/v1/channels.json?default=true&rally_id=50776&sort=sort_order response_code=200 return_code=ok total_time=0.100834
Processing by SharesController#neo_create as JSON
  Parameters: {"share"=>{"context_channel_id"=>"375661", "context_rally_id"=>"50776", "context_type"=>"Channel", "context_id"=>"375661"}, "rally_id"=>"50776", "channel_id"=>"messages", "content_id"=>"19052477"}
  User Load (0.4ms)  SELECT `users`.* FROM `users` WHERE `users`.`deleted_at` IS NULL AND `users`.`id` = 303137 ORDER BY `users`.`id` ASC LIMIT 1
ETHON: performed EASY effective_url=http://rcs.staging.rallyhood.com:80/api/v1/contents/19052475.json?context_channel_id=375656&include=avatar%2Ccreator%2Ccreator_avatar%2Cshares&timezone=Hobart&use_event_timezone=false response_code=200 return_code=ok total_time=0.146005
  UserEmail Load (0.4ms)  SELECT `user_emails`.* FROM `user_emails` WHERE `user_emails`.`user_id` = 303137 AND `user_emails`.`primary` = TRUE LIMIT 1
ted 200 OK in 726ms (Views: 9.9ms | ActiveRecord: 2.7ms | Allocations: 124081)

*/

/*
file share to files channel
Processing by SharesController#neo_create as JSON
  Parameters: {"share"=>{"context_channel_id"=>"375662", "context_rally_id"=>"50776", "context_type"=>"Channel", "context_id"=>"375662"}, "rally_id"=>"50776", "channel_id"=>"messages", "content_id"=>"19052476"}
  User Load (0.6ms)  SELECT `users`.* FROM `users` WHERE `users`.`deleted_at` IS NULL AND `users`.`id` = 303137 ORDER BY `users`.`id` ASC LIMIT 1
  UserEmail Load (0.5ms)  SELECT `user_emails`.* FROM `user_emails` WHERE `user_emails`.`user_id` = 303137 AND `user_emails`.`primary` = TRUE LIMIT 1
ETHON: performed EASY effective_url=http://rrs.staging.rallyhood.com:80/api/v1/rallies/50776.json?include=avatar%2Cchild_count%2Cmember_count%2Cparent response_code=200 return_code=ok total_time=0.090298
ETHON: performed EASY effective_url=http://rmems.staging.rallyhood.com:80/api/v1/memberships.json?include=user&rally_id=50776&user_id=303137 response_code=200 return_code=ok total_time=0.06713
ETHON: performed EASY effective_url=http://rchans.staging.rallyhood.com:80/api/v1/channels.json?default=true&rally_id=50776&sort=sort_order response_code=200 return_code=ok total_time=0.071841
ETHON: performed EASY effective_url=http://rchans.staging.rallyhood.com:80/api/v1/channels.json?rally_id=50776&slug=messages response_code=200 return_code=ok total_time=0.035262
ETHON: performed EASY effective_url=http://rcs.staging.rallyhood.com:80/api/v1/contents/19052476.json?context_channel_id=375656 response_code=200 return_code=ok total_time=0.08159
ETHON: performed EASY effective_url=http://rchans.staging.rallyhood.com:80/api/v1/channels.json?default=1&rally_id=50776&type=MessageWall response_code=200 return_code=ok total_time=0.036011
ETHON: performed EASY effective_url=http://rcs.staging.rallyhood.com:80/api/v1/shares.json response_code=201 return_code=ok total_time=0.036916
[active_model_serializers] Rendered ActiveModel::Serializer::Null with Hash (0.12ms)
Completed 200 OK in 438ms (Views: 0.9ms | ActiveRecord: 1.1ms | Allocations: 23226)*/

    saveAttachments() {
       let galleryChan = findDefaultChannelOfTypeForRally(
          "Gallery",
          this.rallyChannelsByRallyId,
          this.rallyId
        );
        let fileChan = findDefaultChannelOfTypeForRally(
            "Files",
            this.rallyChannelsByRallyId,
            this.rallyId
        );
      if (this.theAttachments && this.theAttachments.length > 0){
        this.theAttachments.forEach(a => {
          if (a && a.copySettings && a.copySettings !== "none"){
            let chan2Share2 = a.attributes["file-type"] == "Photo" ?  galleryChan : fileChan
            if(a.copySettings === 'root') {
              this.shareContent({contentUid: a.id,
                channelIds: [chan2Share2],
                rallyIds: [this.rallyId],
                shareType: 'original',
                status: 1,
                owner : 1})
            } else {
              this.shareContent({contentUid: a.id,
                channelIds: [chan2Share2],
                contextId: a.copySettings,
                contextType: 'Container',
                rallyIds: [this.rallyId],
                shareType: 'original',
                status: 1,
                owner : 1})
            }
          }

          })
      }
    },
  mounted() {
    if (!this.content) {
      this.attachmentsFromTheStore = [];
    }
  }
  }
}
</script>
