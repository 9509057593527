<template>
  <component 
    :is="elementType"
    :class="theType"
    :src="avatarFile"
  >

    <div v-if="imageMissing" :style="spiritColor + '; width: ' + pSize + '; height: ' + pSize" class="af-initials" @click="emitMaybeDirectMessage" >
      {{ initials }}
    </div>

    <img v-if="imagePresent"
         :src="avatarFile"
         :style="'width: ' + pSize + '; height: ' + pSize"
         @click="emitMaybeDirectMessage"  @error="rallyAvatarOnError" 
         />

    <DirectMessageModal :pUserId="pUserId" :uniqueId="uniqueId" :pRallyId="rallyId"/>
  </component>
</template>

<script>
import MemberMixin from "./MemberMixin";
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { userInfo } from "../common/user-info"
import { eventBus } from "../main.js";

export default {
  mixins: [MemberMixin],
  name: "ActivityFeedUserAvatar",
  props: ["pUserId", "type", "pMembership", "pRallyId", "pSize", "pAvatarFile", "pUserFirstName", "pUserLastName"],
  data() {
      return {
       uniqueId : Math.random(),
       reloadAttempted: false,
       rallyAvatarLoaded: true
      };
  },
  computed: {
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),

    pUser() {
      return this.usersById[parseInt(this.pUserId)];
    },
    userId() {
      return this?.pUserId;
    },
    //avatar: function() {
      //return //this.theUser.relationships ? this.contentById[parseInt(this.theUser.relationships.avatar.data.id)] : null;
    //},
    avatarFile: function() {
      return this.pAvatarFile;
    },
    elementType() {
      return this.imageMissing ? "div" : "div";
    },
    imageMissing() {
      return !this.rallyAvatarLoaded || !this.avatarFile || this.avatarFile.indexOf("missing.png") >= 0;
    },
    imagePresent() {
      return this.rallyAvatarLoaded && this.avatarFile && this.avatarFile.indexOf("missing.png") < 0;
    },
    initials() {
      let firstInitial = ""
      let lastInitial = ""
      try{
        firstInitial  =  (this.pUserFirstName && this.pUserFirstName.length > 0) ?  this.pUserFirstName[0] : "";
        lastInitial   =  (this.pUserLastName && this.pUserLastName.length > 0) ?  this.pUserLastName[0] : "";
      } catch (e)
      {
        console.log(e)
      }
      return firstInitial + lastInitial
    },
    // Choose one of a five colors "randomly", but assign it permenantly to a person
    // by using the last digit of their user_id as the key.
    spiritColor() {
      const colors = ["#40B5BC", "#5AC6C6", "#8FD0CA", "#03DACE", "#42ABC5"];

      return `background-color: ${colors[0]};`;
    },
    theType: function() {
      return "af-avatar";
    },
    rallyId(){
        return  this.pRallyId ? this.pRallyId : this.$route.params.rallyId ? this.$route.params.rallyId : null
    },
    checkReloadData(){
        if (!this.reloadAttempted && this.rallyId && !this.membership && this.pUserId){
          this.loadData()
          return false
        }
        return true
    }
  },

  methods: {
   rallyAvatarOnError(){
      this.rallyAvatarLoaded = false
   },
   emitMaybeDirectMessage(){
     eventBus.$emit('maybeDirectMessageEvent' + +this.uniqueId);
   },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
