<template>
  <article
    v-if="this.showMember"
    :class="[memberClass, 'member']"
    @click="viewMemberDetails"
  >
    <div class="wrapper">
      <div class="top-color"></div>
      <div v-if="roleBadge" class="role-badge">{{ roleBadge }}</div>
      <UserAvatar :pUserId="memberUser.id"  :pSize="'118px'" :pMembership="member"  type="avatar" /> 
      <div class="name">
        {{ memberField(["first-name"])  }}
        {{ memberField(["last-name"]) }}
      </div>
      <div v-if="member.attributes.status == 'Invited'" class="invited">
        <div>
          Invited {{ this.doFormatDate(member.attributes["updated-at"]) }}
        </div>
        <i
          class="fal fa-redo reinvite"
          title="Resend Invitation"
          @click.stop="reinvite"
        ></i>
        <i
          class="fal fa-times-circle remove"
          title="Cancel Invitation"
          @click.stop="remove"
        ></i>
      </div>
      <div v-if="member.attributes.status == 'Requested'" class="invited">
        <div>
          Requested {{ this.doFormatDate(member.attributes["updated-at"]) }}
        </div>
        <i
          class="fal fa-check-circle reinvite"
          title="Approve"
          @click.stop="approve"
        ></i>
        <i
          class="fal fa-times-circle remove"
          title="Deny"
          @click.stop="deny"
        ></i>
      </div>
      <div
        v-else-if="memberField(['current-position', 'position'])"
        class="current-position"
      >
        {{ memberField(['current-position', 'position']) }}
      </div>
    </div>
  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import MemberMixin from "./MemberMixin";
import moment from "moment-timezone";
import { userInfo } from "../common/user-info"
export default {
  mixins: [MemberMixin],
  computed: {
    ...mapState("memberships", ["membersByUserId"]),
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    memberId() {
      return this.member.id;
    },
    showMember() {
      return (this.memberUser && this.member.attributes.status === "Active") || this.isAdmin;
    },
    isAdmin() {
      // TODO: Rally admin should be able to see everyone's profile.
      return this.myRole === "Admin" || this.myRole === "Owner";
    },
    myMembership() {
      return this.membersByUserId[parseInt(userInfo().user_id)];
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },
    memberClass() { 
      return [
        this.member.attributes && this.member.attributes.role ? this.member.attributes.role.toLowerCase(): "",
        this.member.attributes && this.member.attributes.status ? this.member.attributes.status.toLowerCase() : ""
      ];
    },
    roleBadge() {
      const role = this.member.attributes.role;
      return ['Owner', 'Admin'].includes(role) && this.m ? role : false;
    },
    m(){
      return this.memberUser && this.memberUser.attributes ? this.memberUser.attributes: []
     },
   },
  methods: {
    viewMemberDetails() {
      var s = this.$route.path;
      s =
        s.substring(s.length - 1, s.length) === "/"
          ? s.substring(0, s.length - 1)
          : s;
      router.push(`${s}/${this.member.id}?vuedir=true`);
    },
    doFormatDate(time) {
      return moment(time).format("MM/DD");
    }
  }
};
</script>
