<template>
  <div>
        <div v-if="selectedEvent">
          <article class="content event details">
            <div class="title">{{ this.selectedEvent.title }}</div>
            <!--TODO <div class="ellipsis-button">
              <ul v-if="content" class="additional">
                <li v-if="isEditable && userIsOwner" @click="$emit('perform', 'edit')" class="option">
                  <i class="fal fa-pencil"></i>Edit
                </li>
                <DetailsIcon :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId"/>
                <li v-if="isEditable && userIsOwner" class="option">
                <DeleteIcon  v-if="canDelete"  :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId" :menu="true"/>
                </li> 
              </ul>
            </div>-->
            <div class="author-name">
             {{creatorName}}
            </div>
            <div class="body">{{ doFormatDateEvent(selectedEvent) }}</div>
            <div v-if="this.selectedEvent.location" class="body">{{ this.selectedEvent.location }}</div>
            <div v-if="this.selectedEvent.description" class="body" v-html="formattedText"/>
            <div v-if="this.selectedEvent.online_location" class="body">
              Meeting Link: <a target="_blank" :href="this.selectedEvent.online_location"> {{ this.selectedEvent.online_location }}</a>
            </div>
            <div v-if="this.selectedEvent.online_location_password" class="body">Meeting Password: {{ this.selectedEvent.online_location_password }}</div>
            <div> {{ this.selectedEvent.repeat_summary }}</div>
            <div v-if="isRSVP">
              <EventParticipation
                :shareId="this.shareId"
                :content="this.content"
                :if="isRSVP"
                :channelId="this.channelId"
              />
              <ContentParticipations v-if="this.content" :id="this.content.id" :channelId="this.channelId" :rallyId="this.rallyId" :isRSVP="true" />
            </div>
            <div v-if="this.content">
              <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(this.channelId)"/>
            </div>
            <!--<div><ShareIcon v-if="!isRSVP" :content="this.content" :rallyId="parseInt(this.rallyId)"  :channelId="this.channelId" contentType="Calendar"/></div>-->
            <ContentElipsisMenu :canShare="!isRSVP"
                                  :content="content"
                                  :rallyId="this.rallyId"
                                  :channelId="this.channelId"
                                  :can-assign="isRSVP"
                                  contentType="Event"/>
            <Comments
              :shareId="this.shareId"
              :avatarFile="this.creatorAvatar"
              :channelId="this.channelId"
              :rallyId="this.rallyId"
            />
          </article>
        </div>

        <!-- END SHOW MESSAGE -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router, findDefaultChannelOfType, timezoneMapping, railsTzToMomentTz, formatDateEvent, formatDateSignup,  formatContentAllDayEvent } from "../../../common"; 
import moment from "moment-timezone";
import EventParticipation from "../../../components/EventParticipation";
import { userInfo } from "../../../common/user-info"
import DatetimeMixin from "../../../components/DatetimeMixin";
import ContentMixin from "../../../components/ContentMixin";


export default {
  mixins: [DatetimeMixin, ContentMixin],
  name: "EventDetails",
  components: {
    EventParticipation
  },
  props: ["id", "channel", "content", "rally"],
  data() {
    return {
      showElipsis: false,
      isLoaded: false
    };
  },
  computed: {
    ...mapState("calendars", ["calendarEntries", "calendarEntriesByContentId"]),
    ...mapState("contents", ["contentById"]),
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),

    formattedText() {
      return this.selectedEvent.description.replace(/\n/g, '<br>');
    },
    isEditable() {
      return this.content && this.content.editable != 'true';
    },
    isRSVP() {
      return (this.selectedEvent["rsvp_flag"] == 1 || this.selectedEvent['rsvp'] == 1);
    },
    shareId() {
      return this.content?.relationships['context-share']?.data?.id
    },
    creatorAvatar(){
      return this.content?.attributes['creator-avatar']
    },
    creatorName(){
      let n = "" 
      if(this.selectedEvent.creator_id && this.usersById[this.selectedEvent.creator_id]){
        n =  this.usersById[this.selectedEvent.creator_id].attributes['first-name'] + " "
            + this.usersById[this.selectedEvent.creator_id].attributes['last-name']
      } else if (this.selectedEvent['creator-id'] && this.usersById[this.selectedEvent['creator-id']]) {
        n =  this.usersById[this.selectedEvent['creator-id']].attributes['first-name'] + " "
            + this.usersById[this.selectedEvent['creator-id']].attributes['last-name']
      }
      if (n && n.indexOf("Unknown") >= 0){
        n = ""
      } 
      return n
    },
    selectedEvent(){
      if(this.calendarEntriesByContentId[parseInt(this.$route.params.id)]) {
        return this.calendarEntriesByContentId[parseInt(this.$route.params.id)]
      } else {
        return this.contentById[this.$route.params.id].attributes
      }
    },
  },
  async created() {
    await this.fetchMembersByRally({
      rallyId: this.rallyId,
      status: ["Active"]
    });
    if(this.calendarEntries && this.calendarEntries.length > 0) {
       await this.calendarEntries.forEach(entry => {
        if (entry.id === parseInt(this.$route.params.id)) {
          this.selectedEvent = entry;
          //this.channelId = findDefaultChannelOfType("Calendar", this.rallyChannelsByRallyId[this.rallyId]);
          this.selectedContentUid = entry["content_uid"];
          this.fetchContentById({ id: entry["content_uid"], contextChannelId: this.channelId });
        }
      });
    } else { 
      await this.fetchContentById({id: this.$route.params.id, contextChannelId: this.channelId, contextRallyId: this.rallyId })
      this.selectedEvent.creator_id = this.selectedEvent['creator-id']
    }
    this.isLoaded = true
    window.scroll(0, 0);
  },

  methods: {
    ...mapActions("contents", ["fetchContentById", "doRSVP"]),
    ...mapActions("memberships", ["fetchMembersByRally"]),

    doFormatDateEvent(d){
        if (d['converted-start']){
          return formatDateSignup(d, railsTzToMomentTz(userInfo().timezone))
        } else {
          return formatDateEvent(d, railsTzToMomentTz(userInfo().timezone))
        }
    },
    hideActions() {
      this.showElipsis = false;
    },
    navCalendar: function() {
      router.push(`/${this.rallyId}/events`);
    },
    
    eventRSVP() {
      this.doRSVP({
        id: this.content?.id,
        shareId: this.shareId,
        content_uid: this.content.id
      });
    },
    perform(method, params) {
      if (this[method]) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit("perform", method, params);
      }
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script> 
