import axios from "axios";
import jwtInterceptor  from '../../common/jwt-interceptor';

import {
    RNS_URL,
    RNS_COUNTS_URL,
    RAFS_URL,
    buildServiceParamMap,
    updateMapById,
    buildListOfAttributeValues,
    performGet, PARTS_URL
} from "../../common";

import { authHeader } from "../../common/auth-header";
import { StatusBarStyle } from "@capacitor/core";
import {userInfo} from "@/common/user-info";
import rally from "@/components/Rally.vue";

const state = {
    channelCountsByRallyId: {},
    activityFeed: [],
    isLoading: false,
    endOfActivity: false
};
const getters = {
    channelCountsByRallyId: state => state.channelCountsByRallyId,
    activityFeed: state => state.activityFeed,
    isLoading: state => state.isLoading,
    endOfActivity: state => state.endOfActivity
}
const actions = {
    async contentViewed({ commit, state }, inparams) {
        const { contentId, contentType, rallyId, actionType, userId } = inparams;
        const requestOptions = {
            method: "POST",
            url: RNS_URL() + "/user-activities",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                data: {
                    type: "user-activities",
                    attributes: {user_id: userId,
                        rally_id: rallyId,
                        activity_type: contentType,
                        activity_id: contentId,
                        action_type: actionType,
                        originable_type: 'Rally',
                        originable_id: rallyId,
                        source: 'vue-mobile'}
                }
            }
        };

        const response = await jwtInterceptor(requestOptions);
    },
    async fetchActivities({ commit, state }, inparams) {
        const { rallyId } = inparams;
        const user = userInfo();
        let jsonparams = {};
        try{
            var params = buildServiceParamMap(jsonparams);
            const requestOptions = {
              method: "GET",
              headers: {
                "Content-Type": "application/json"
              },
              url: RNS_COUNTS_URL() +  "/rally-counts/" + rallyId ,
              params: params
            };
            const response = await jwtInterceptor(requestOptions);

            if (response && response.data && response.data.activity_count) {
                commit("setChannelCountsByRallyId", {rallyId, counts: response.data.activity_count})
            }
        } catch (e){
            console.log("fetch act error")
            console.log(e)
        }
    },

    async fetchActivityFeed({ commit, state }, inparams) {
        const user = userInfo();
        const { pageNumber } = inparams;
        if(pageNumber == 1) {
            state.activityFeed = [];
        }
        state.isLoading = true;
        let jsonparams = {
            // rallyId,
            user_id: user.user_id,
            "page[number]": pageNumber,
        };
        try{
            var params = buildServiceParamMap(jsonparams);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                url: RAFS_URL() ,
                params: params
            };
            const response = await jwtInterceptor(requestOptions);
            if (response && response.data) {
                commit("setActivityFeed", {feed: response.data.data, pageNumber: pageNumber})
                state.isLoading = false;
            }
        } catch (e){
            console.log("fetch act feed error")
            console.log(e)
        }
    }

};

const mutations = {
    setChannelCountsByRallyId: (state, params) =>{
        const {rallyId, counts} = params;
        var newMap = {};
        newMap[rallyId]= counts
        state.channelCountsByRallyId = {...state.channelCountsByRallyId, ...newMap}
    },
    setActivityFeed: (state, params) =>{
        const {feed, pageNumber} = params;
        var newMap = {};
        if(pageNumber == 1)
        {
            state.activityFeed = feed
        } else {
            state.activityFeed = [...state.activityFeed, ...feed]
        }
        if((feed.length == 0) || (pageNumber == 1 && feed.length < 4)) {
            state.endOfActivity = true;
        } else {
            state.endOfActivity = false;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
