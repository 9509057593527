<template>
  <div class="body">
    <UserAvatarUpload :uniqueId="uniqueId" p-size="200px;" p-line-height="12rem;" p-font-size="6rem;"/>
    <form>
      <div class="sub-heading">About Me</div>
      <div class="form-group">
        <label for="first_name">First Name</label>
        <input name="first_name" type="text"  v-model="currentUser.attributes['first-name']"  />
      </div>
      <div class="form-group">
        <label for="last_name">Last Name</label>
        <input name="last_name" type="text" v-model="currentUser.attributes['last-name']" />
      </div>
      <div class="form-group">
        <label for="position_field">Current Position or Role</label>
        <input name="position_field" type="text" v-model="currentUser.attributes['current-position']"  />
      </div>
      <div class="form-group">
        <label for="expertise">Areas of Expertise or Interest</label>
        <textarea name="expertise" v-model="currentUser.attributes['expertise']"  ></textarea>
      </div>
      <div class="form-group">
        <label for="additional_info">Additional Information</label>
        <textarea name="additional_info" v-model="currentUser.attributes['additional-info']"  ></textarea>
      </div>
      <div class="form-group">
        <label>Time Zone</label>
        <select  v-model="currentUser.attributes['time-zone']">
          <option  v-for="t in timezoneEntryList" :key="t[0]" :value="t[1]">{{t[0]}}</option>
        </select>
      </div>
      <div class="sub-heading">Contact Information</div>
      <div class="form-group">
        <label for="address">Address</label>
        <input name="address" type="text" v-model="currentUser.attributes['address']"  />
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input name="city" type="text" v-model="currentUser.attributes['city']"  />
      </div>
      <div class="form-group">
        <label for="state">State</label>
        <input name="state" type="text" v-model="currentUser.attributes['state']"  />
      </div>
      <div class="form-group">
        <label for="zip">Zip Code</label>
        <input name="zip" type="text" v-model="currentUser.attributes['zip-code']"   />
      </div>
      <div class="form-group">
        <label for="primary_phone">Primary Phone</label>
        <input name="primary_phone" type="text" v-model="currentUser.attributes['primary-phone']"  />
      </div>
      <div class="sub-heading">Social Media</div>
      <div class="form-group">
        <label for="facebook_url">Facebook URL</label>
        <input name="facebook_url" type="text" v-model="currentUser.attributes['facebook-url']"  />
      </div>
      <div class="form-group">
        <label for="linkedin_url">LinkedIn URL</label>
        <input name="linkedin_url" type="text" v-model="currentUser.attributes['linkedin-url']"   />
      </div>
      <div class="form-group">
        <label for="twitter_url">Twitter URL</label>
        <input name="twitter_url" type="text" v-model="currentUser.attributes['twitter-url']"   />
      </div>
      <hr/>
      <div v-if="customizedProfiles.length" class="form-group radios" >
        <label>
          <input v-model="updateAll" type="radio" value="true">
          <div class="circle" style="float:left"/>
          Update my profile in all rallies
        </label>
        <div>
          <label style="">
            <input v-model="updateAll" type="radio" value="false">
            <div class="circle" style="float: left;"/>
            Update my default profile, but not these Rallies with custom profiles:
          </label>
          <div style="margin-left: 30px; font-size: 10pt; color: #008295;">{{ customizedProfiles.join(', ') }} And a ton more text to see how this will look</div>
        </div>
      </div>
      <div class="actions">
        <button class="primary" @click.prevent="saveUserInfo()" :disabled="isSaving" >Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {TIMEZONE_MAPPING} from "@/common";
import {userInfo} from "@/common/user-info";
import {eventBus} from "@/main";

export default {
  name: "UserProfile",
  data: function() {
    return {
      isSaving: false,
      user_id: null,
      updateAll: false
    };
  },
  computed: {
    ...mapState("user", ["usersById"]),
    ...mapState("rallies", ["allRallies"]),
    ...mapState("memberships", ["myCustomProfiles"]),
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
    customizedProfiles() {
      let customProfiles = this.myCustomProfiles.filter(profile => profile.attributes['is-custom']).map(profile => profile.attributes['title'])
      return customProfiles
    },

    timezoneEntryList(){
      return Object.entries(TIMEZONE_MAPPING)
    },
    uniqueId() {
      return this.user_id
    }
  },
  created() {
    this.loadPageData();
  },
  methods: {
    ...mapActions("user", ["updateUserInfo"]),
    ...mapActions("memberships", ["getMyCustomProfiles"]),
    collapseAccordion() {
      // Assuming you have a way to select the open accordion, for example:
      let openAccordion = document.querySelector('.open-accordion-class'); // Replace with your actual selector
      if(openAccordion) {
        openAccordion.classList.remove('open-accordion-class'); // Or use the appropriate method to collapse it
      }
    },
    async loadPageData() {
      this.user_id = userInfo().user_id;
      await this.getMyCustomProfiles({rallyId: null});
    },
    log(message) {
      this.$log.debug(message);
    },
    saveUserInfo(){

      this.isSaving = true;  // Disable the button
      eventBus.$emit('uploadAvatar'+ this.uniqueId, {userId: this.user_id})
      this.updateUserInfo({updatedUser:this.currentUser, userNotificationSettings: {}, updateAll: this.updateAll, userPrefs: []})
          .then(() => {
            this.$store.dispatch('user/flashSuccess', 'User info updated!');
            this.$emit('saveComplete');
            this.isSaving = false;  // Re-enable the button after save
          });

      //eventBus.$emit('uploadAvatar'+ this.uniqueId, {userId: this.user_id})
      //this.updateUserInfo({updatedUser:this.currentUser, userNotificationSettings: {}, updateAll: this.updateAll, userPrefs: []})
      //this.$store.dispatch('user/flashSuccess', 'User info updated!');
    },
  }
}

</script>

<style scoped>

</style>
