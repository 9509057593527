<template>
  <div class="body">
    <p>If you would like to delete your account from the Rallyhood system, you can remove it here. </p>
    <p>Please be aware that removing your account will also remove your access from any Rallies you are a member of in the future. </p>
    <p>For any technical assistance, please contact: <a href="mailto:support@rallyhood.com">support@rallyhood.com</a></p>
    <p>We are happy to help!</p> 

    <hr style="border: 0.2px solid darkgrey;">
    <button @click="openModal()" class="danger">Remove My Account</button>
      
      
    <div  :class="[{ open: modalOpen }, 'modal', '-modal']">
        <div class="shadow" @click="closeModal"></div>
        <div class="body">
          <i class="fal fa-times exit" @click="closeModal"></i>
            <div >
              <div class="heading">Remove My Account</div>
              <div class="description">
                <p>Are you sure you want to remove your account? This action will permanently remove you from Rallyhood and all access to Rallies  with which you have been a member and any content within them. </p>
              </div>
                <a style="color:red;text-decoration: underline;" @click="removeAccount">Yes, I am sure I want to permanently delete my Rallyhood account.</a>
                <br/>
                <br/>
                <a style="text-decoration: underline;" @click="closeModal">No, I changed my mind. I want to keep my Rallyhood account.</a>
                <p style="color:#008295;">Click here for technical support: <a style="color:#008295;" href="mailto:support@rallyhood.com">support@rallyhood.com</a></p>
            </div>
        </div>
    </div>




  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {userInfo} from "@/common/user-info";
import { router } from "../../common";
import {Capacitor, Plugins} from "@capacitor/core";
const { Storage } = Plugins;
export default {
  name: "ManageAccount",
  data: function() {
    return {
      user_id: null,
      primaryEmail: '',
      emailList: {},
      newEmail: '',
      modalOpen: false,
    };
  },
  computed: {
    ...mapState("user", ["usersById", "userEmails"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
    passwordError() {
      return this.password.length < 8 ||
          !/\d/.test(this.password) ||
          !/[a-z]/.test(this.password) ||
          !/[A-Z]/.test(this.password) ||
          !/[^\w\s]/.test(this.password)
    },
    passwordConfirmationError() {
      return this.passwordConfirmation !== this.password
    },
    secondaryEmails() {
      const secondaryEmails = {};
      this.userEmails.forEach((email) => {
        if (email.attributes['primary'] == false) {
          secondaryEmails[email.id] = email.attributes['email'];
        }
      });
      //return filteredEmails;
      //let secondaryEmails = this.userEmails.filter(email => email.attributes['primary'] == false).map(email => email.attributes['email'], id => email.attributes['id'])
      return secondaryEmails;
    },
  },
  created() {
    this.loadPageData();
    this.primaryEmail = this.usersById[this.user_id].attributes['email']
  },
  methods: {
    ...mapActions("user", ["clearUsersState", "deleteMyUser", "addSecondaryUserEmail", "removeSecondaryUserEmail", "updateUserInfo"]),
    ...mapActions("rallies", ["clearRallyState"]),
    ...mapActions("messages", ["clearMessageState"]),
    ...mapActions("contents", ["clearContentsState"]),
    closeModal() {
      //this.$emit('perform', 'openRHWeb', null);
      this.modalOpen = false;
    },
    openModal() {
      //this.$emit('perform', 'openRHWeb', null);
      this.modalOpen = true;
    },
    addEmail() {
      // Make API call to add email
      this.addSecondaryUserEmail({updatedUser: this.currentUser, email: this.newEmail})
      const userEmailId = Date.now(); // Generate unique ID for new email
      this.secondaryEmails[userEmailId] = this.newEmail;
      this.newEmail = '';
    },
    async loadPageData() {
      this.user_id = userInfo().user_id;
    },
    async removeAccount() {
      await this.deleteMyUser()
      router.push(`/login`);
      this.clearRallyState();
      this.clearMessageState();
      this.clearContentsState();
      this.clearUsersState();
      localStorage.setItem("at", null);
      localStorage.removeItem('user')
      await Storage.remove({ key: 'user' });
    },
    /*saveUserInfo(){
      this.updateUserInfo({updatedUser:this.currentUser, userNotificationSettings: {}, updateAll: this.updateAll, userPrefs: []})
      this.$emit('saveComplete');
      this.$store.dispatch('user/flashSuccess', 'User email updated!');
    },*/
  }
}
</script>

<style scoped>

</style>