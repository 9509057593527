import {Plugins} from "@capacitor/core";

const { Storage } = Plugins;

export function userInfo() {
    try {
        let userInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        return userInfo;
    } catch (e){
        return null
    }
}

export async function userInfoPersistent() {
    try {
        let user = await Storage.get({ key: 'user' });
        let parsedUser = JSON.parse(user.value);
        return parsedUser;
    } catch (e){
        console.log("ERRROR!!!!")
        console.log(e)
        return null
    }
}