<template>
  <article v-on:click="$emit('perform', 'openContent', content)">
    <div class="wrapper">
      <img class="avatar" :src="callAvaUrl(eventAvatar)" />
      <div v-if="totalTickets > 0" class="tickets">
        {{ totalTickets }} Tickets
      </div>
      <div v-if="content && content.attributes && content.attributes['converted-start']" class="calendar-circle">
        <div class="month">
          {{ eventMonth(content.attributes["converted-start"]) }}
        </div>
        <div class="day" v-if="content && content.attributes">{{ eventDay(content.attributes["converted-start"]) }}</div>
      </div>
      <div class="title" v-if="content && content.attributes"><b>{{ content.attributes.title }}</b></div>
      <div class="full-date" v-if="content && content.attributes">{{ formatEventStartDateTime(content.attributes) }}</div>
      <div v-if="addressLine1" class="full-date">{{ addressLine1 }}</div>
      <div v-if="city" class="full-date">{{ city }}, {{ state }} {{ zip }}</div>
    </div>
  </article>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  formatCurrency,
  findOrganizerParticipation,
  avaUrl,
  formatDateTimeNoTimezoneConversion,
  formatEventLine1, railsTzToMomentTz
} from "../../../common";
import { userInfo } from "../../../common/user-info";
import ContentPayableMixin from "../../ContentPayableMixin";
import AddressMixin from "../../AddressMixin";
import {eventBus} from "@/main";
import ContentMixin from "@/components/ContentMixin.vue";
export default {
  mixins: [ContentPayableMixin, AddressMixin],
  name: "PayableEventListed",
  props: ["id", "channel", "rally", "content"],
  data() {
    return {
      locationLoaded: false
    }
  },
  async created() {
    if(this.content && this.content.attributes) {
      await this.fetchAddressesByIds({addressIds: [this.content.attributes['address-id']]})
      this.locationLoaded = true
    }
  },
  computed: {
    ...mapState("contents", ["contentById"]),
    ...mapState("locations", ["locationsByAddressId"]),
    eventAvatar() {
      if(this.content && this.content.relationships && this.content.relationships.avatar) {
        return this.contentById[this.content.relationships.avatar.data.id];
      } else {
        return ''
      }
    },
    totalTickets() {
      let ticketCount = 0
      if(this.content && this.content.attributes) {
        if(this.content.attributes['child-content-participants'] && this.content.attributes['child-content-participants'][0]) {
          this.content.attributes['child-content-participants'].forEach(participant => {
            ticketCount = ticketCount + participant.quantity
          })
        }
      }
      return ticketCount
    },
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    ...mapActions("locations", ["fetchAddressesByIds"]),
    eventMonth(eventDate) {
      return moment(eventDate,  "YYYY/MM/DD").format("MMM").toUpperCase();
    },
    eventDay(eventDate) {
      return moment(eventDate, "YYYY/MM/DD").format("DD");
    },
    formatEventStartDateTime(eventDate) {
      return formatDateTimeNoTimezoneConversion(eventDate, railsTzToMomentTz(userInfo().timezone))
    }
  }
};
</script>
