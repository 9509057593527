<script>
import {
  router,
  findDefaultChannelOfTypeForRally,
  findDefaultChannelObjOfType,
  slugToTypeObjectMap,
  channelTypeObjectMap
} from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    contentId() {
      return parseInt(this.$route.params.id);
    },
    channelTypePlural() {
      return channelTypeObjectMap()[this.channelType].channelTypePlural;
    },
    channelSlug() {
      // Used in place of channel.type.
      return this.channel.slug;
    },
    channelId() {
      return this.channel.id
    },
    channelType() {
      return this.channel.type
    },
    channelIdOld() {
      if (this.rallyChannelsByRallyId && this.rallyChannelsByRallyId[parseInt(this.rallyId)]) {
        let chanid = findDefaultChannelOfTypeForRally(
          this.channelTypePlural,
          this.rallyChannelsByRallyId,
          this.rallyId
        );
        if (!chanid && this.altChannelTypePlural){
          chanid =  findDefaultChannelOfTypeForRally(
          this.altChannelTypePlural,
          this.rallyChannelsByRallyId,
          this.rallyId
        );
        }
        return chanid 
      } else {
        return null
      }
    },
   /* contextId(){
      return this.channelId
    }*/
  },
  methods: {
    ...mapActions("contents", ["setContentById", "flagContent"]),
    ...mapActions("rallies", ["fetchRalliesChannels"]),
    async loadContents() {
      if (this.channel) {
        //alert("WE GOT CHANNEL")
        this.contextId = this.channelId;
        this.myFetchContents()
      } else {
        await this.fetchRalliesChannels({rallyId: this.$route.params.rallyId})
        let channels = this.rallyChannelsByRallyId[this.$route.params.rallyId]
        channels.forEach(channel => {
          if ( (this.channelType == channel.attributes.type) ||
              ( this.slug() === 'special_events' && channel.attributes.slug === 'fundraisers?fundraiser_type=PayableEvent') ||
              ( this.slug() === 'pay' && channel.attributes.slug === 'fundraisers?fundraiser_type=PayableGroup') ||
              ( this.slug() === 'give' && channel.attributes.slug === 'fundraisers?fundraiser_type=GivePayableGroup')) {
            this.channelId = channel.id
            this.contextId = this.channelId;
            this.myFetchContents()
          }
        })
      }
    },
    myReloadPageData(){
    },
    slug() {
        //const pathParts = this.$route.path.split('/');
        //return pathParts[2]; // considering the first segment (before the first '/') will be an empty string
      return this.$route.params.channelSlug
    },
    async loadPageData() {
      this.loadAttempted = true; 
      try {
        this.myReloadPageData()
        this.loadContents();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
