<template>
  <div
    id="bell-notification"
    class="ellipsis-button"
  >
    <i
      v-click-outside="hideNotifications"
      :class="bellClass"
      @click="toggleNotifications"
    />
    <div
      v-if="notificationsPresent"
      class="count"
    >
      {{ newRhNotifications.length }}
    </div>
    <ul v-if="showNotifications" class="additional" style="overflow:scroll;">
      <EmptyState v-if="!notificationsPresent" channelType="Notifications"  />
      <li
        v-if="!notificationsPresent"
        class="option none-found"
      >
        No new notifications at this time.
      </li>
      <RhNotification
          v-for="notification in rhNotifications" 
          :key="notification.id"
          :notification="notification"
          :notificationId="notification.id"
          :notificationStatus="notification.attributes['status']"
          :observer="observer"
      />
      <!--
      <li
        v-for="notification in notifications"
        :key="notification.id"
        class="option notification"
        @click="followNotification(notification)"
      > -->
        <!--<img
          :src="notification.avatar"
          class="avatar"
        />-->
        <!--<i
          v-if="sentiment(notification.action)"
          :class="sentiment(notification.action)"
        />-->

      <!--
        <span class="name">
          {{ notification.attributes['notification-text'] }}
        </span>

        -->
        <!--<span class="action-phrase">
          {{ actionPhrase(notification.action) }}
        </span>
        <span class="sample">
          "{{ notification.sample }}"
        </span>
        <div class="when">
          {{ notification.ago }}
        </div>-->
     <!--  </li> -->
    </ul>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import { router } from "../common";
  import {userInfo} from "@/common/user-info";
  import { eventBus } from "../main.js";
  export default {
    data() {
      return {
        observer: null,
        showNotifications: false
      };
    },
    computed: {
      ...mapState("channels", ["newPrivateMessages"]),
      ...mapState("notifications", ["rhNotifications", "newRhNotifications"]),
      bellClass() {
        if ( this.notificationsPresent ) {
          return 'fal fa-bell has-new';
        } else {
          return 'fa fa-bell';
        }
      },
      notificationsPresent() {
        return this.newRhNotifications.length > 0
      },
    },
    created() {
      this.observer = new IntersectionObserver(
          this.onElementObserved,
          {
            root: this.$el,
            threshold: 1.0,
          }
      );
      eventBus.$on('toggleBell' , (p) => {
        try {
        //alert("toggling")
        this.toggleNotifications()
        } catch (e){
          //alert(e)
        }
      })
    },
    mounted() {
      this.loadNotifications()
    },
    methods: {
      ...mapActions("notifications", ["fetchNotifications", "notificationViewed"]),
      actionPhrase(action) {
        switch ( action ) {
          case 'like':
            return 'liked your post';
          case 'mention':
            return 'mentioned you in a post:';
          case 'request':
            return 'has requested to join';
          default:
            return false;
        }
      },

      loadNotifications() {
        setInterval(function () {
          this.fetchNotifications();
        }.bind(this), 300000);
        return this.fetchNotifications()
      },
      hideNotifications(event) {
        if ( ! event.target.classList.contains('open-notifications') ) {
          this.showNotifications = false;
        }
      },
      onElementObserved(entries) {
        entries.forEach(({ target, isIntersecting}) => {
          if (!isIntersecting) {
            return;
          }
          let notificationId = target?.attributes?.notificationid?.value;
          let notificationStatus = target?.attributes?.notificationstatus?.value;
          if (notificationId && (notificationStatus == 'new')){
            let params = {}
            params['notificationId'] = notificationId
            params['userId'] = userInfo()['id']
            params['status'] = 'viewed'
            this.notificationViewed(params);
          }
        })},
      sentiment(action) {
        switch ( action ) {
          case 'like':
            return 'icon like fas fa-thumbs-up';
          case 'send-love':
            return 'icon love fas fa-heart';
          case 'thank':
            return 'icon thank fas fa-award';
          default:
            return false;
        }
      },
      toggleNotifications() {
        this.fetchNotifications()
        this.showNotifications = !this.showNotifications;
      },
    },
    ready: function () {
      //alert('ready')
      this.loadNotifications();
      setInterval(function () {
        this.loadNotifications();
      }.bind(this), 30);
    },
    watch: {
      showNotifications(newValue) {
        this.$emit('toggle-bell', newValue);
      },
    },
  };
</script>
