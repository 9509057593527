<template>
  <div
    v-click-outside="hideUserMenu"
    id="site-nav"
    :class="[{opened: show_user_menu}, 'ellipsis-button']"
    @click="show_user_menu = !show_user_menu"
  >
    <component
        :is="elementType"
        :class="theType"
        :src="avatarFile"
    >

      <div v-if="imageMissing" :style="spiritColor" class="site-initials">
        {{ initials }}
      </div>

      <img v-if="imagePresent"
           :src="avatarFile"
           class="site-avatar"
      />
    </component>
    <div class="user-name">{{userName}}</div>
    <i class="fal fa-times close-menu"></i>
    <ul v-if="show_user_menu" class="additional">
      <li class="option">
        <router-link to="/rallies">My Rallyhood</router-link>
      </li>
      <li class="option">
        <router-link to="/calendar">My Calendar</router-link>
      </li>
      <li class="option">
        <router-link to="/messaging">Messaging</router-link>
      </li>
      <li class="option">
        <router-link to="/feed">Activity Feed</router-link>
      </li>
      <li class="option">
        <router-link to="/settings">My Settings</router-link>
      </li>
      <li class="option">
        <a href="javascript:;" class="help-general beacon-link" >Get Help</a>
      </li>
      <div v-if="isRallyhoodAdmin">
        <li class="option">
          <router-link to="/admin">Admin</router-link>
        </li>
      </div>
      <div v-if="isMasquerading">
        <li class="option">
          <a @click="stopMasquerading">Stop Masquerading</a>
        </li>
      </div>
      <li class="option" @click="doLogout()">
        Logout
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";
import { userInfo } from "../common/user-info";
import {Plugins} from "@capacitor/core";
import jwt_decode from "jwt-decode";

const { Storage } = Plugins;
export default {
  name: "UserMenu",
  props: ["pUserId"],
  data() {
    return {
      show_user_menu: false
    };
  },
  created() {},
  mounted() {
    // Attach the document-level click event listener when the component is mounted
    document.body.addEventListener('click', this.handleClick);
  },
  beforeDestroy() {
    // Remove the document-level click event listener before the component is destroyed
    document.body.removeEventListener('click', this.handleClick);
  },
  computed: {
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),
    ...mapState("user", ["status", "user", "usersById"]),
    currentUser() {
      return this.usersById[userInfo().user_id];
    },
    isRallyhoodAdmin() {
      if (this.currentUser && this.currentUser.attributes && this.currentUser.attributes['is-rallyhood-admin'] == true) {
        return true;
      } else {
        return false;
      }
    },
    isMasquerading() {
      if (localStorage.getItem("mat")){
        return true
      } else {
        return false;
      }
    },
    userName(){
      return (this.currentUser && this.currentUser.attributes) ? this.currentUser.attributes['first-name'] + " " + this.currentUser.attributes['last-name'] : ""
    },
    elementType() {
      return this.imageMissing ? "div" : "div";
    },
    imageMissing() {
      return !this.avatarFile || this.avatarFile.indexOf("missing.png") >= 0;
    },
    imagePresent() {
      return this.avatarFile && this.avatarFile.indexOf("missing.png") < 0;
    },
    avatarFile: function() {
      if (this.theUser) {
        var _avatar = this.userAvatarContent
        if (typeof(_avatar) === 'string' || _avatar instanceof String){
          return _avatar
        }
        if (_avatar) {
          var imgUrl = _avatar['medium_url'] ? _avatar['medium_url'] : _avatar.attributes['medium-url']
          return (imgUrl && imgUrl.indexOf("https://") < 0) ? "https://" + imgUrl : imgUrl
        }
      }
      return null;
    },
    theUser: function() {
      //use logged in user if pUser is not provided
      //this.user may not have the full complement of attributes...pull from usersById
      return this.pUserId ? this.pUser : this.usersById[parseInt(userInfo().user_id)]
    },
    spiritColor() {
      const colors = ["#40B5BC", "#5AC6C6", "#8FD0CA", "#03DACE", "#42ABC5"];
      if (this.theUser && this.theUser.id ){
        const spiritNumber = Math.floor(parseInt(this.theUser.id.split("").pop()) / 2);
        return `background-color: ${colors[spiritNumber]};`;
      } else {
        return `background-color: ${colors[0]};`;
      }
      //return "#40B5BC"
    },
    initials() {
      let firstInitial = ""
      let lastInitial = ""
      try{
        firstInitial =  (this?.theUser?.attributes["first-name"] && this.theUser.attributes["first-name"].length > 0) ?  this.theUser.attributes["first-name"][0] : "";
        lastInitial =  (this?.theUser?.attributes["last-name"] && this?.theUser?.attributes["last-name"].length > 0) ? this.theUser.attributes["last-name"][0] : "";
        if (this.membership && this.membership.attributes){
          firstInitial = this.membership.attributes["first-name"] ? this.membership.attributes["first-name"][0] : firstInitial
          lastInitial = this.membership.attributes["last-name"] ? this.membership.attributes["last-name"][0] : lastInitial
        }
      } catch (e)
      {
        console.log(e)
      }
      return firstInitial + lastInitial
    },
    userAvatarContent() {
      //var avid = parseInt(_user.relationships.avatar.data.id)
      if (this?.pMembership?.relationships?.avatar?.data?.id && this?.pMembership?.relationships?.avatar?.data?.id != "-1" && this.contentById[this.pMembership.relationships.avatar.data.id + ""].attributes){
        return this.contentById[this.pMembership.relationships.avatar.data.id + ""]
      }
      else if (this?.theUser?.attributes?.avatar){
        return this.theUser.attributes.avatar
      } else if ( !this?.theUser?.relationships?.avatar?.data?.id ){
        return this.defaultAvatarsByType["user"]
      } else {
        return this.contentById[this.theUser.relationships.avatar.data.id]
      }
    },
    theType: function() {
      return "site-avatar";
    },

  },
  methods: {
    ...mapActions("rallies", ["clearRallyState"]),
    ...mapActions("messages", ["clearMessageState"]),
    ...mapActions("contents", ["clearContentsState"]),
    ...mapActions("user", ["clearUsersState"]),
    hideUserMenu() {
      this.show_user_menu = false
    },
    handleClick(event) {
      // Check if the clicked element has the specific CSS class you want to target
      const targetClass = 'help-general'; // Replace with your actual CSS class name
      if (event.target.classList.contains(targetClass)) {
        // Call your specific function when the element with the CSS class is clicked
        this.helpScout();
      }
    },
    helpScout() {
      !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
      window.Beacon('init', 'd0a1a615-0313-4053-bcd8-c03a24967a9c');
      window.Beacon('open');
      window.Beacon('on', 'close', () => window.Beacon('destroy'));
      console.log(userInfo())
      window.Beacon('identify', {
        email: userInfo().email
      });
    },
    async doLogout() {
      this.show_user_menu = false;
      router.push(`/login`);
      this.clearRallyState();
      this.clearMessageState();
      this.clearContentsState();
      this.clearUsersState();
      localStorage.setItem("at", null);
      localStorage.removeItem('user')
      await Storage.remove({ key: 'user' });
    },
    log(message) {
      this.$log.debug(message);
    },
    stopMasquerading() {
      const newToken = localStorage.getItem("mat")
      var decodedToken = jwt_decode(newToken);
      var user = { id: decodedToken.user.id,
        user_id: decodedToken.user.id,
        access_token: newToken,
        timezone: decodedToken.user.tz }
      let jsonUser = JSON.stringify(user)
      localStorage.setItem("at", localStorage.getItem("mat"));
      localStorage.removeItem("mat");
      localStorage.setItem("user", jsonUser);
      location.reload()
    }
  }
};

</script>
