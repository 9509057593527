<script>
  export default {
    data() {
      return {
        modalStatus: false,
      };
    },
    methods: {
      toggleAccordion(event) {
        const accordion = event.target.parentElement;
        const accordions = accordion.parentElement.getElementsByClassName('accordion');
        const wasOpen = accordion.classList.contains('open');
        Array.from(accordions).forEach((a) => {
          a.classList.remove('open');
        });
        if ( ! wasOpen ) {
          accordion.classList.add('open');
          window.scroll(0, 0);
        }
      },
      toggleAccordionById(parentElement, id) {
        const accordion = parentElement;
        const accordions = accordion.getElementsByClassName('accordion');
        const wasOpen = accordion.classList.contains('open');
        Array.from(accordions).forEach((a) => {
          a.classList.remove('open');
        });
        let element = document.getElementById(id)
        console.log("eleement is")
        console.log(element)
        if (element){
          element.classList.add('open');
          window.scroll(0, 0);
        }
      },
      toggleModal() {
        this.modalStatus = !this.modalStatus;
      }
    }
  };
</script>
