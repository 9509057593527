<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
          <div v-if="!isNewLifecycle" class="delete-icon">
            <!--<button class="danger" @click.prevent="doDelete" >Delete</button>-->
            <DeleteIcon type="button"   :content="content" :rallyId="this.rallyId" :channel-id="this.channelId"  />
          </div>
        </div>
        <div class="right">
          <div class="save-button">
            <button
              @click.prevent="$emit('perform', 'save')"
              class="primary"
              type="submit"
            >Save</button>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Folder Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
      </div>
    </form>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin"; 

  export default {
    name: "Folder",
    mixins: [
      ContentMixin,
    ],
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    computed: {
      isNewLifecycle(){
        return this.content.attributes['lifecycle'] == "new" && !this.content.id
      }
    }
  };
</script>
