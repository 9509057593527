<template>
  <div v-if="checkReloadData" class="register">
    <button :class="[cssClass, 'register-button']" @click="toggleModal">{{ label }}</button>
    <div :class="[{ open: confirmStatus }, 'modal']" >
      <div class="shadow" @click="toggleConfirm"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleConfirm"></i>
        <div class="heading">Thanks for your purchase!</div>
        <div>Confirmation Number:</div>
        <div>{{ "[RH" + confNum + "]" }}</div>
      </div>
    </div>
    <div :class="[{ open: modalStatus }, 'modal']" style="max-height: 100vh;overflow: scroll;">
      <div class="shadow" @click="toggleModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">Pay</div>
        <div class="heading">Items</div>
        <div class="form-group" v-for="payable in this.payables" :key="payable.id">
          <div v-if="!checkoutMode || (payable.quantitySelected && payable.quantitySelected > 0)">
            <span class="label"> {{ payable.attributes.title }} ({{ "$" + payable.attributes.cost / 100 + ".00" }}) {{ soldOut(payable) ? "SOLD OUT": ""}} </span>
            <select v-model="payable.quantitySelected" class="quantity" v-on:change="onChangeValue($event)" :disabled="checkoutMode">
              <option v-for="i in calcMaxTickets(payable) + 1 " :key="i" :value="i - 1">{{ i -1 }}</option>
            </select>
          </div>
        </div>
        <div >
          {{this.additionalInfoText}}
        </div>
        <div class="form-group" v-for="n in customFields.length" :key="customFields[n-1].name">
          <textarea v-model="customFieldsArr[n-1]" :placeholder="customFields[n-1].name"></textarea>
        </div>
        <br/>
        <div v-if="this.stripeTx">
          <div class="left">Taxes</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-taxes"] }}</div>
        </div>
        <div v-if="this.stripeTx">
          <div class="left">Fees</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-fees"] }}</div>
        </div>
        <div v-if="this.stripeTx">
          <div class="left">Total</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-total"] }}</div>
        </div>
        <div style="color:red">{{this.errorMessage}}</div>
        <div>
          <StripeElementCard v-if="checkoutMode"  ref="elementRef" @error="stripeCardError" :pk="publishableKey" @token="payItTokenCallback" />
        </div>
        <div class="actions">
          <button class="secondary" @click="toggleModal">Cancel</button>
          <button class="danger" @click="destroy" v-if="registered">Delete</button>
          <button v-if="!checkoutMode" class="primary" @click="checkout" :disabled="invalid">Checkout</button>
          <button v-if="checkoutMode" class="primary" @click="payIt" :disabled="invalid">Pay Now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CheckoutMixin from "./CheckoutMixin";
import ContentReloadMixin from "./ContentReloadMixin";
import ContentPayableMixin from "./ContentPayableMixin";
import {userInfo} from "@/common/user-info";
export default {
  mixins: [ CheckoutMixin],
  props: ["contextId", "rallyId", "theChannelId"],
    data: function() {
    return {
        customFieldsArr: ["",""]
    }
  },
  created() {
    //this.loadAndCreate();
      try {
        this.fetchContents({
        ignorePagination: true,
        contentType: "Message", 
        contextId: this.contextId,
        contextRallyId: this.rallyId,
        contextType: ["Fundraiser","PayableGroup"],
        type: "PaymentCustomFieldsDefinition",
        contextChannelId: this.theChannelId
      })
      this.fetchContents({
        ignorePagination: true,
        contentType: "Message", 
        contextId: this.contextId,
        contextRallyId: this.rallyId,
        contextType: "Content",
        type:  "NotesInstructions"
      })
      this.fetchContents({
        ignorePagination: true,
        contentType: "Message", 
        contextId: this.contextId,
        contextRallyId: this.rallyId,
        contextType: "Content",
        type:  "ExtraInformation"
      })
      this.fetchContents({
        ignorePagination: true,
        contentType: "Event", 
        contextId: this.contextId,
        contextRallyId: this.rallyId,
        contextType: "Content",
        type:  "ChildPayableEvent",
        use_event_timezone: false,
        timezone: userInfo().timezone
      })
      } catch (e){
        console.log(e)
      }
  },
  computed : {
    invalid(){
      let isABlank = false
      for ( let i = 0; i < this.customFields.length; i++ ) {
         if ( !this.customFieldsArr[i]){
           isABlank = true
         }
      }
      return isABlank
    },
    checkReloadData() {
      if (!this.allContentByContextTypeAndId[this.contextId] && !this.loadAttempted ) {
        return false
      } 
      return true
    },
    additionalInfoField(){
      let c = this.allContentByContextTypeAndId[this.contextId] 
      if (c && c.filter){
       return c.filter(function(s) {
        return s.attributes.type == 'NotesInstructions'
       })
      } else {
        return []
      }
      //return this.allContentByContextTypeAndId[parseInt(this.contextId)];
    },
     
    errorMessage(){
      return this.stripeTxErrorsByTxId[parseInt(this.stripeTxId)]?.data?.errors[0]?.detail
    },

    additionalInfoText(){
      return this.additionalInfoField && this.additionalInfoField[0] ?  this.additionalInfoField[0].attributes.body : "Additional Info"
    }

  },
  methods: {
    ...mapActions("contents", ["fetchContents"])
  }
};
</script>
