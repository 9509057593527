import axios from "axios";
import {
  RCS_URL,
  RCHANS_URL,
  buildServiceParamMap,
  findDefaultChannelOfType,
  mergeById,
  sortByDate,
  RSENTS_URL,
  PARTS_URL,
  buildMapByValueName,
  buildParticipantJSON,
  RFINS_STRIPE_TRANSACTION_URL,
  RFINS_FUNDRAISER_DETAILS_URL,
  RFINS_ACCOUNT_RALLY_URL,
  RFINS_URL
} from "../../common";
import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import jwtInterceptor  from '../../common/jwt-interceptor';
import { eventBus } from "../../main.js";

const state = {
  pendingStripeTxByUserAndContentId: {},
  stripeTxErrorsByTxId: {},
  fundraiserDetailsById: {},
  accountRallyById: {},
  accountsByRallyId: {},
  accountsByUserId: {}
};

const getters = {
  stripeTxErrorsByTxId: state => {
    return state.stripeTxErrorsByTxId;
  },
  pendingStripeTxByUserAndContentId: state => {
    return state.pendingStripeTxByUserAndContentId;
  },
  fundraiserDetailsById: state => {
    return state.fundraiserDetailsById;
  },

  accountRallyById: state => {
    return state.accountRallyById
  }
};

const actions = {
  async clearContentsState({ commit, state }) {
    commit("clearState");
  },

  //Started PUT "/api/v1/stripe_transactions/2981.json"
  //Processing by Api::V1::StripeTransactionsController#update as JSON
  //Parameters: {"data"=>{"type"=>"stripe-transactions", "attributes"=>{"id"=>"2981", "account_rally_id"=>"177", "content_uid"=>19024212, "user_id"=>"303137", "paid_user_id"=>nil, "refund_user_id"=>nil, "amount"=>nil, "stripe_order_id"=>nil, "subtotal"=>0, "processor_fees"=>0, "rh_fees"=>0, "taxes"=>0, "created_at"=>"2021-02-21T15:42:34.000Z", "updated_at"=>"2021-02-21T15:42:34.000Z", "transaction_status"=>"", "calcs_done"=>nil, "share_id"=>10134817, "email"=>"jason.topliff@rallyhood.com", "total_fees"=>0, "transaction_total"=>0, "formatted_taxes"=>"0.00", "formatted_fees"=>"0.00", "formatted_total"=>"0.00", "payer_first_name"=>"", "payer_last_name"=>"", "payer_email"=>"jason.topliff@rallyhood.com", "payer_phone"=>"", "parent_transaction_id"=>nil, "links"=>{"self"=>"http://localhost:3080/api/v1/stripe_transactions/2981"}, "stripe_payment_token"=>"", "context_rally_id"=>"39019", "payable_id"=>"", "sku_string"=>"1472:1:10134819,1473:0:10134822,1474:0:10134825,1475:0:10134828,1488:0:10134863,1489:0:10134870,1490:0:10134877,1491:0:10134880,", "street_address_zip"=>"", "extra_information"=>"", "contact_phone"=>"", "contact_email"=>"jason.topliff@rallyhood.com", "participant_params"=>"
  async updateStripeTransaction({ commit, state }, inparams) {
    const {
      accountRallyId,
      contentUid,
      payableId,
      shareId,
      shareChannel,
      skuArray,
      stripeTransactionId,
      stripePaymentToken,
      transactionStatus,
      participantList,
      rallyId, 
      addInfo,
      notes,
      customFields
    } = inparams;
    const user = userInfo();
    var convShareId = shareId ? parseInt(shareId) : shareId;
    const createdAttributes = {
      id: stripeTransactionId,
      account_rally_id: accountRallyId,
      content_uid: contentUid,
      context_rally_id: rallyId,
      content_type: "PayableEvent",
      payable_id: payableId,
      share_id: convShareId,
      user_id: user.user_id,
      sku_ids_with_quantity: skuArray, //"sku_string"=>"1472:1:10134819,
      //street_address_zip: "66086",
      transaction_status: transactionStatus,
      stripe_payment_token: stripePaymentToken,
      contact_email: user.email,
      participant_params: buildParticipantJSON(participantList)
    };

    const requestOptions = {
      method: "PUT",
      url: RFINS_STRIPE_TRANSACTION_URL() + stripeTransactionId, 
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "stripe-transactions",
          attributes: createdAttributes
        }
      }
    };
    var response = null;
    try {
    response = await jwtInterceptor(requestOptions)
    this.dispatch("contents/updateNotesAndInfoForStripeTx", { stripeTransactionId, shareChannel, rallyId, notes, addInfo, customFields });
    } catch (error){
      var errStruct = { ...error };
      eventBus.$emit('overlayOff')
      if (errStruct.response) {
        commit("setStripeTxErrorsByTxId", { stripeTxId: stripeTransactionId, error: error.response });
      }
      return;
    }
    //clear error on success
    commit("setStripeTxErrorsByTxId", { stripeTxId: stripeTransactionId, error: null });
    commit("setPendingStripeTxByUserAndContentId", { contentId: contentUid, userId: user.user_id, object: response.data.data });
  },

  //this.fetchContents({rallyId: this.rallyId, contextType: "Channel", contextChannelId: 294216, contextId:this.rallyId, contentType: ['Attachment', 'Binary', 'Container'], pageNumber: 1});
  async fetchFundraiserDetails({ commit, state }, inparams) {
    try{
    const { fundraiserId } = inparams;
    const user = userInfo();
    var jsonparams = {
      content_uid: fundraiserId
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET", 
      url: RFINS_FUNDRAISER_DETAILS_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      params: params
    };
    const response = await jwtInterceptor(requestOptions);
    commit("setFundraiserDetailsById", { id: fundraiserId, content: response.data.data });
  } catch (e){
    console.log(e)
  }
  },
  //this.fetchContents({rallyId: this.rallyId, contextType: "Channel", contextChannelId: 294216, contextId:this.rallyId, contentType: ['Attachment', 'Binary', 'Container'], pageNumber: 1});
  async fetchAccount({ commit, state }, inparams) {
    try{
      const { accountRallyId } = inparams;
      const user = userInfo();
      var jsonparams = {
       //id : accountRallyId
      };
      var params = buildServiceParamMap(jsonparams);
      const requestOptions = {
        method: "GET",
        url: RFINS_ACCOUNT_RALLY_URL() + accountRallyId + ".json",
        headers: {
          "Content-Type": "application/json"
        },
        params: params
      };
      const response = await jwtInterceptor(requestOptions);
      commit("setAccountRallyById", { id: accountRallyId, account: response.data.data });
    } catch (e){
      console.log(e)
    }
  },
  async fetchAccounts({ commit, state }, inparams) {
    try{
      const { rallyId, returnUrl } = inparams;
      const user = userInfo();
      var jsonparams = {
        context_rally_id : rallyId,
        creator_id: user.id,
        user_id: '*',
        "ne[account_type]": 'default',
        stripe_standard_return_url: returnUrl
      };
      var params = buildServiceParamMap(jsonparams);
      const requestOptions = {
        method: "GET",
        url: RFINS_ACCOUNT_RALLY_URL(),
        headers: {
          "Content-Type": "application/json"
        },
        params: params
      };
      const response = await jwtInterceptor(requestOptions);
      commit("setAccountsByRallyId", { rallyId: rallyId, accounts: response.data.data });
    } catch (e){
      console.log(e)
    }
  },

  async fetchAccountById({ commit, state }, inparams) {
    try{
      const { id, returnUrl } = inparams;
      const user = userInfo();
      var jsonparams = {
        id,
        stripe_standard_return_url: returnUrl
      };
      var params = buildServiceParamMap(jsonparams);
      const requestOptions = {
        method: "GET",
        url: RFINS_ACCOUNT_RALLY_URL() + id + ".json",    
        headers: {
          "Content-Type": "application/json"
        },
        params: params
      };
      const response = await jwtInterceptor(requestOptions);
      return response.data.data
      //commit("setAccountsByRallyId", { rallyId: rallyId, accounts: response.data.data });
    } catch (e){
      console.log(e)
    }
  },
  async fetchUserAccounts({ commit, state }, inparams) {
    try{
      const { returnUrl } = inparams;
      const user = userInfo();
      var jsonparams = {
        creator_id: user.id,
        user_id: '*',
        "ne[account_type]": 'default',
        stripe_standard_return_url: returnUrl
      };
      var params = buildServiceParamMap(jsonparams);
      const requestOptions = {
        method: "GET",
        url: RFINS_ACCOUNT_RALLY_URL(),
        headers: {
          "Content-Type": "application/json"
        },
        params: params
      };
      const response = await jwtInterceptor(requestOptions);
      commit("setAccountsByUserId", { userId: user.id, accounts: response.data.data });
    } catch (e){
      console.log(e)
    }
  },
  async createStripeAccount({ commit, state }, inparams) {
    try {
      const {rallyId, account, returnUrl} = inparams;
      const user = userInfo();

      const timestamp = new Date().getTime();
      const random = Math.floor(Math.random() * timestamp);

      const createdAttributes = {
        account_type: 'stripe_standard',
        user_id: user.id,
        creator_id: user.id,
        rally_id: rallyId,
        context_rally_id: rallyId,
        street_address_state: account.attributes['state'],
        street_address_zip: account.attributes['zip'],
        title: account.attributes['title'] || "New Account - " + random,
        stripe_standard_return_url: returnUrl
      };
      const requestOptions = {
        method: "POST",
        url: RFINS_ACCOUNT_RALLY_URL(),
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          data: {
            type: "accounts",
            attributes: createdAttributes
          }
        }
      };
      const response = await jwtInterceptor(requestOptions);
      commit("addAccountByRallyId", {rallyId: rallyId, account: response.data.data});
      return response.data.data
    } catch (e){
      console.log(e)
    }
  },
  async importStripeAccount({ commit, state }, inparams) {
    try {
      const {rallyId, account, returnUrl} = inparams;
      const user = userInfo();

      const createdAttributes = {
        title: account.attributes['title'],
        account_id: account.attributes['master-account-id'],
        creator_id: user.id,
        rally_id: rallyId,
        context_rally_id: rallyId
      };

      const requestOptions = {
        method: "POST",
        url: RFINS_URL() + "account-rallies",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          data: {
            type: "account-rallies",
            attributes: createdAttributes
          }
        }
      };
      const response = await jwtInterceptor(requestOptions);

      this.dispatch("fetchAccounts", {rallyId: rallyId, returnUrl: returnUrl})
      return response.data.data
    } catch (e){
      console.log(e)
    }
  },
  async updateStripeAccount({ commit, state }, inparams) {
    try {
      const {account} = inparams;

      const requestOptions = {
        method: "PUT",
        url: RFINS_ACCOUNT_RALLY_URL()  + account.id,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          data: {
            type: "accounts",
            attributes: account.attributes
          }
        }
      };
      const response = await jwtInterceptor(requestOptions);

      return response.data.data
    } catch (e){
      console.log(e)
    }
  },
  // Parameters: {"data"=>{"type"=>"stripe-transactions", "attributes"=>{"account_rally_id"=>"177", "content_uid"=>"19024212", "content_type"=>"PayableEvent", "payable_id"=>"19024214", "share_id"=>"10134817", "street_address_zip"=>nil, "partial_return_line_items"=>"", "user_id"=>303137, "contact_phone"=>nil, "contact_email"=>nil, "payer_first_name"=>nil, "payer_last_name"=>nil, "payer_email"=>nil, "payer_phone"=>nil, "participant_params"=>"eyJmdW5kcmFpc2VyX3R5cGUiOm51bGx9\n", "sku_ids_with_quantity"=>nil}}}
  async createStripeTransaction({ commit, state }, inparams) {
    const { accountRallyId, contentUid, payableId, shareId, rallyId, shareChannel, customFields} = inparams;
    const user = userInfo();
    var convShareId = shareId ? parseInt(shareId) : shareId;

    const createdAttributes = {
      account_rally_id: accountRallyId,
      content_uid: contentUid,
      content_type: "PayableEvent",
      payable_id: payableId,
      share_id: convShareId,
      context_rally_id: rallyId,
      user_id: user.user_id
    };

    const requestOptions = {
      method: "POST",
      url: RFINS_STRIPE_TRANSACTION_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "stripe-transactions",
          attributes: createdAttributes
        }
      }
    };

    const response = await jwtInterceptor(requestOptions);
    commit("setPendingStripeTxByUserAndContentId", { contentId: contentUid, userId: user.user_id, object: response.data.data });
    this.dispatch("contents/createNotesAndInfoForStripeTx", { stripeTransactionId: response.data.data.id , shareChannel, rallyId, customFields });
  }
};

const mutations = {
  addAccountByRallyId: (state, params) => {
    const {rallyId, account} = params
    var curarr = state.accountsByRallyId[rallyId]
    if (!curarr) {
      curarr = [];
    }
    curarr.push(account)
  },
  clearState: state => {
    state.pendingStripeTxByUserAndContentId = {};
  },

  setStripeTxErrorsByTxId: (state, params) => {
    const { stripeTxId, error } = params;
    var byIdObj = {};
    byIdObj[stripeTxId] = error;
    state.stripeTxErrorsByTxId = { ...state.stripeTxErrorsByTxId, ...byIdObj };
  },

  setAccountsByRallyId: (state, params) => {
    const {rallyId, accounts} = params
    var curarr = state.accountsByRallyId[rallyId]
    if (!curarr) {
      curarr = [];
    }
    var theAccounts = accounts ? accounts : []

    var byIdObj = {};
    byIdObj[rallyId] = theAccounts
    state.accountsByRallyId = { ...state.accountsByRallyId, ...byIdObj };
  },

  setAccountsByUserId: (state, params) => {
    const {userId, accounts} = params
    var curarr = state.accountsByUserId[userId]
    if (!curarr) {
      curarr = [];
    }
    var theAccounts = accounts ? accounts : []

    var byIdObj = {};
    byIdObj[userId] = theAccounts
    state.accountsByUserId = { ...state.accountsByUserId, ...byIdObj };
  },

  setPendingStripeTxByUserAndContentId: (state, params) => {
    const { contentId, userId, object } = params;
    var byIdObj = {};
    byIdObj[userId + contentId] = object;
    state.pendingStripeTxByUserAndContentId = { ...state.pendingStripeTxByUserAndContentId, ...byIdObj };
  },
  setFundraiserDetailsById: (state, params) => {
    const { id, content } = params;
    if (content) {
      var byIdObj = {};
      byIdObj[id + ""] = content[0];
      state.fundraiserDetailsById = { ...state.fundraiserDetailsById, ...byIdObj };
    }
  },
  setAccountRallyById: (state, params)=>{
    const {id, account} = params
    if (account){
      var byIdObj = {};
      byIdObj[id + ""] = account
      state.accountRallyById = { ...state.accountRallyById, ...byIdObj };
    }

  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
