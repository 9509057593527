<template>
  <div class="participate">
    <button v-if="!readOnly" :class="[cssClass, 'signup-button']" @click.stop="toggleModal">
      {{ label }}
    </button>
    <button v-if="readOnly" :class="[cssClass, 'signup-button']" >
      {{ label }}
    </button>
    <div :class="[{ open: modalStatus }, 'modal']">
      <div class="shadow" @click="toggleModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">Sign Up</div>
        <div v-if="maxParts == 0"  class="form-group">
          <select v-model="quantity">
            <option v-for="i in 100" :key="i-1" :value="i-1">{{ i-1 }} Spots</option>
          </select>
        </div>
        <div v-if="maxParts > 0" class="form-group">
          <select v-model="quantity">
            <option v-for="i in (this.dropDownMax)" :key="i-1" :value="i-1">{{ i-1 }} Spots</option>
          </select>
        </div>   <!--
        <div v-if="maxParts > 0 && (this.dropDownMax + myCount  > 1)" class="form-group">
          <select v-model="quantity">
            <option v-for="i in (this.dropDownMax + myCount + 1)" :key="i-1" :value="i-1">{{ i-1 }} Spots</option>
          </select>
        </div>
     
        <div v-if="maxParts > 0 && (this.pcRemainingSpots + myCount  == 1)" class="form-group">
          <select v-model="quantity">
            <option v-for="i in (2)" :key="i-1" :value="i-1">{{ i-1 }} Spots</option>
          </select>
        </div>-->

        <div class="actions">
          <DeleteButton @click="destroy" v-if="myParticipation" />
          <button class="secondary" @click="toggleModal">Cancel</button>
          <button class="primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"; 
import { userInfo } from "../common/user-info"
import { totalParticipantCount } from "../common";
import ParticipationMixin from "./ParticipationMixin";
import { eventBus } from "../main.js";
export default {
  props: ["content", "channelId", "rallyId", "readOnly"],
  mixins: [ParticipationMixin],
  data: function() {
    return {
      modalStatus: false,
      quantity: 0,
      partId: null,
      state: null,
      originalQuantity: 0,
      totalCount: 0,
      participations: []
    };
  },
  async created() {
    if (this.myParticipation) {
      this.quantity = this.myParticipation.attributes.quantity;
      this.originalQuantity = this.quantity;
    }
    await this.getParticipations();
  },
  computed:{
      ...mapState("participations", ["participationsByShareId"]),
      participationsState(){
        return this.participationByShareId[this.shareId]
      },
      maxParts() {
        return this.content.attributes["max-participants"];
      },
      partCount() {
      let t = 0
        this.participations.forEach(p => {
              if (p.attributes['quantity']  && parseInt(p.attributes['quantity']) ){
              t = t + parseInt(p.attributes['quantity'])
            }
        })
      return t
      },
      spotsAvailable(){
       return  (this.maxParts - this.partCount)
      },
      dropDownMax(){
        if (this.quantity && this.quantity > 0 &&  (this.quantity > this.spotsAvailable)) {
          return this.quantity + this.spotsAvailable + 1
        } else {
          return (this.maxParts - this.partCount) + 1
        }
      },
      //pcRemainingSpots() {
       // return this.maxParts - this.partCount
      //}    
  },
  methods: {
    ...mapActions("contents", ["setContentById", "updateContent"]), 
    ...mapActions("participations", [
      "createOrUpdateParticipation",
      "deleteParticipation",  "fetchParticipations"
    ]),
    destroy() {
      this.deleteParticipation({ id: this.myParticipation.id });
      var c = this.content.attributes["participant-count"];
      if (!c){
        c = 0
      }
      this.content.attributes["participant-count"] = c - this.originalQuantity;
      this.originalQuantity =  this.content.attributes["participant-count"]
      this.quantity = this.originalQuantity
      //this.updateContent({id: this.content.id, content: this.content, rallyId: this.rallyId})
      //this.toggleModal();
      this.quantity = 0
      //this.toggleModal();
      this.save()
    },
    async save() {
      await this.createOrUpdateParticipation({
        id: this.myParticipation ? this.myParticipation.id : null,
        contentUid: this.contentUid,
        shareId: this.shareId,
        participationStatus: "assigned",
        participantType: "follower",
        quantity: this.quantity,
        channelId: this.channelId,
        contentType: "Event", 
        origination: "opt_in"
      });
      var c = this.content.attributes["participant-count"];
      if (!c){
        c = 0
      }
      this.content.attributes["participant-count"] = c + (this.quantity - this.originalQuantity);
      this.originalQuantity =  this.content.attributes["participant-count"]
      eventBus.$emit('getParticipations')
      this.getParticipations();
      //this.quantity = this.originalQuantity
      // --- I don't think we need to update the content record when changing the participation
      //this.updateContent({id: this.content.id, content: this.content, rallyId: this.rallyId, noDateUpdate: true})
      this.toggleModal();
      // }
    },
    toggleModal() {
      //if (this.isFull){
        //return 
      //}
      this.getParticipations();
      this.modalStatus = !this.modalStatus;
    },
    async getParticipations() {
      let participationResponse = await this.fetchParticipations({ contextId: this.shareId });
      this.participations = participationResponse.data
      this.participantsLoaded = true;
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
