<template>
  <article class="folder">
    <div class="title">
      <i class="fal fa-folder file-type"></i>
      {{ content.attributes.title }}
    </div>
    <footer>
      <div class="left">
        <UserAvatar :pUserId="content.attributes['creator-id']" pSize="35px;" pFontSize="1.0rem" pLineHeight="2.2rem"/>
        <div class="author-name">
          {{ byLine }}
        </div>
        <div class="created-at">
          {{ when }}
        </div>
      </div>
      <ContentElipsisMenu :content="content" :rallyId="this.rallyId" :channelId="this.channelId" pinnable="false"
                          contentType="Files" hideDelete="true" hideReport="true" hideSentiments="true"/>
    </footer>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin";

  export default {
    name: "Folder",
    mixins: [
      ContentMixin,
    ],
    props: ["content", "rally", "channel"],
    created() {
      console.log("Folder", this.content);
    },
  }
</script>
