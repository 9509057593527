<template>
  <section v-if="this.participantNameList && this.participantNameList.length > 0" class="participations">
    <div class="header">
      <div class="user">Name</div>
    </div>
    <div v-for="pn in this.participantNameList" :key="pn.id" class="participation">
      <div v-if="pn.attributes" class="user">
        {{ pn.attributes.name }}
        <!--<div v-if="participationById[p.id].attributes.guests > 0" class="guests">{{guestsText}}</div>-->
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatCurrency, findOrganizerParticipation, formatDate, mergeByAtt, compareBy} from "../common";
import { userInfo } from "../common/user-info";
export default {
  props: ["payables", "channelId", "rallyId", "isRSVP"],
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("shares", ["sharesByContentId"]),
    ...mapState("user", ["usersById"]),
    guestsText(){
      if ( this?.participationById[this.id]?.attributes["participant-type"] == "will_attend" || this?.participationById[this.id]?.attributes["participant-type"] == "maybe_attending" ){
        return "(+ " + this?.participationById[this.id]?.attributes?.guests + " guests)"
      } else {
        return ""
      }
    },
    content() {
      return this.contentById[this.id]; 
    },
    user() {
      return userInfo();
    },
    participants() {
      return this.content.relationships.participations.data;
    },
    participantUserId(){
      var u = this.usersById[this.participationById[this.id + ""]];
      return u ?  u.id : "";
    },
    participantNameList(){
      let parr = []
      this.payables.forEach(pay => {
        let c = this.contentById[pay.id]; 
        let parts = c.relationships.participations.data;
        parts.forEach(p => {
           if (this.getParticipantName(p.id)){
            parr.push({ attributes: {name: this.getParticipantName(p.id)}})
           }
        })
      })
      if (parr && parr.length > 0) {
        parr = parr.sort(compareBy({}, "name"))
        return mergeByAtt(parr, [], "name")
      } else {
        return []
      } 
    }
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    getParticipantUser(id) {
      return this.usersById[this.participationById[id + ""].attributes["user-id"]];
    },
    getParticipantName(id) {
      var u = this.usersById[this.participationById[id + ""].attributes["user-id"]];
      return u ? u.attributes["first-name"] + " " +  u.attributes["last-name"] : ""
    },
    getParticipantObject(id){
      return this.participationById[id + ""]
    },
    edit(user_id) {
      if (this.isEditable(user_id)) {
        document.getElementsByClassName("rsvp-button")[0].click();
      }
    },
    isEditable(user_id) {
      return user_id == 1;
    },
    statusIcon(p) {
      var status = p ? p.attributes['participant-type'] : ""
      switch (status) {
        case "maybe_attending":
          return "fa-question-circle maybe";
        case "will_attend":
          return "fa-check-circle yes";
        case "not_attending":
          return "fa-times-circle no";
        case "no_reply":
          return "fa-times-circle no";
      }
    },
    statusLabel(p) {
      var status = p ? p.attributes['participant-type'] : ""
      switch (status) {
        case "maybe_attending":
          return "Maybe";
        case "will_attend":
          return "Yes";
        case "not_attending":
          return "No";
        case "no_reply":
          return "No Reply";
      }
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
