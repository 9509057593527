<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType } from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("rallies", ["ralliesByParent", "allRalliesByRallyId", "resultCountByRallyId", "rallyChannelsByRallyId"]),
    ...mapState("memberships", ["membersByRallyId"]),
    isLoadingComplete(){
      var lc = (this.rallyListing && this.rallyListing.length > 0 &&  this.resultCount && this.resultCount > 0) && ((this.rallyListing.length >= this.resultCount ) || (this.prevLoadCountSameCount >=  4))
      return lc
    }
  },
  watch: {
    allRallies: function(newval, oldval) {
      var oldValLen = oldval ? oldval.length : 0
      if (this.$refs.infLoad2 && newval && oldValLen  ){
        //keep the margin of error less than one page size of data for determine completion
        if (newval.length >= this.resultCountByRallyId[1]  ){
           if (this.$refs.infLoad2){
            this.$refs.infLoad2.stateChanger.complete();
           }
        } else {
          if (this.$refs.infLoad2){
            this.$refs.infLoad2.stateChanger.loaded();
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("rallies", ["fetchRalliesWithInfo", "fetchRalliesByParent"]),
    loadNextPage(){
      this.fetchRalliesWithInfo({});

    },
    infiniteHandler($state) {
      if (this.prevLoadCount == 0 && this.rallyListing){
        this.prevLoadCount = this?.rallyListing.length
      } 
      if (this.rallyListing && this.prevLoadCount == this?.rallyListing.length ){
        this.prevLoadCountSameCount++
      } else {
        this.prevLoadCountSameCount = 0
      }
      if (this.prevLoadCountSameCount <=  2) {
        this.loadNextPage()
      }
    }

  }
};
</script>
