<template>
  <section v-if="participants && participants.length > 0" class="participations">
    <div class="header">
      <div class="user">Name</div>
      <div v-if="isRSVP" class="status">Going?</div>
    </div>
    <div v-for="p in participants" :key="p.id" class="participation">
      <div class="user">
        {{ getParticipantName(p.id) }}
        <div  class="guests">{{guestsText(p.id)}}</div>
      </div>
      <div v-if="isRSVP" @click="edit(p.id)" :class="['status', { editable: isEditable(p.id) }]">
        <i :class="['fa', 'icon', statusIcon(getParticipantObject(p.id))]"></i>
        {{ statusLabel(getParticipantObject(p.id)) }}
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatCurrency, findOrganizerParticipation, formatDate } from "../common";
import { userInfo } from "../common/user-info";
export default {
  props: ["id", "channelId", "rallyId", "isRSVP"],
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("shares", ["sharesByContentId"]),
    ...mapState("user", ["usersById"]),

    content() {
      return this.contentById[this.id]; 
    },
    user() {
      return userInfo();
    },
    participants() {
      return this.content.relationships.participations.data;
    },
    participantUserId(){
      var u = this.usersById[this.participationById[this.id + ""]];
      return u ?  u.id : "";
    }
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    getParticipantUser(id) {
      return this.usersById[this.participationById[id + ""].attributes["user-id"]];
    },
    getParticipantName(id) {
      var u = this.usersById[this.participationById[id + ""].attributes["user-id"]];
      return u ? u.attributes["first-name"] + " " +  u.attributes["last-name"] : ""
    },
    getParticipantObject(id){
      return this.participationById[id + ""]
    },
    guestsText(id){
      if ( (this?.participationById[id]?.attributes["participant-type"] == "will_attend" ||
          this?.participationById[id]?.attributes["participant-type"] == "maybe_attending") &&
           this?.participationById[id]?.attributes?.quantity){
        return "(+ " + this?.participationById[id]?.attributes?.quantity + " guests)"
      } else {
        return ""
      }
    },
    edit(user_id) {
      if (this.isEditable(user_id)) {
        document.getElementsByClassName("rsvp-button")[0].click();
      }
    },
    isEditable(user_id) {
      return user_id == 1;
    },
    statusIcon(p) {
      var status = p ? p.attributes['participant-type'] : ""
      switch (status) {
        case "maybe_attending":
          return "fa-question-circle maybe";
        case "will_attend":
          return "fa-check-circle yes";
        case "not_attending":
          return "fa-times-circle no";
        case "no_reply":
          return "fa-times-circle no";
      }
    },
    statusLabel(p) {
      var status = p ? p.attributes['participant-type'] : ""
      switch (status) {
        case "maybe_attending":
          return "Maybe";
        case "will_attend":
          return "Yes";
        case "not_attending":
          return "No";
        case "no_reply":
          return "No Reply";
      }
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
