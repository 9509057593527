import axios from "axios";
import {
    buildServiceParamMap,
    mergeById,
    sortByDate,
    RSENTS_URL,
    mergeByIdMaintainOrder,
    mergeByAtt, compareBy
} from "../../common";

import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import { relativeTimeThreshold } from "moment";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
    likesByShareId: {},
    likeCountsByShareId: {}
};

const getters = {
    likesByShareId: state => id => {
        console.log("==========")
        console.log(state.likesByShareId);
        console.log("==========")
        return state.likesByShareId[id];
    },
    likeCountsByShareId: state => id => {
        return state.likeCountsByShareId[id];
    }
};

const actions = {

    async likeContent({ commit, state }, inparams) {
        const { shareId, likeType } = inparams;
        const user = userInfo();
        const atts = {
            context_id: shareId,
            context_type: "Share",
            type: likeType,
            creator_id: user.user_id,
            include: 'creator_name'
        };
        const requestOptions = {
            method: "POST",
            url: RSENTS_URL(),
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                data: {
                    type: "sentiments",
                    attributes: atts
                }
            }
        };

        const response = await jwtInterceptor(requestOptions);
        this.dispatch("sentiments/fetchContentLikeCounts", { shareId });
    },

    async unLikeContent({ commit, state }, inparams) {
        const { shareId, likeId } = inparams;
        const user = userInfo();
        const atts = {
            like_id: likeId
        };
        const requestOptions = {
            method: "DELETE",
            url: RSENTS_URL() + "/" + likeId + ".json",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                data: {
                    type: "sentiments",
                    attributes: atts
                }
            }
        };

        const response = await jwtInterceptor(requestOptions);
        this.dispatch("sentiments/fetchContentLikeCounts", { shareId });
    },

    async fetchContentLikeCounts({ commit }, inparams) {
        const { shareId } = inparams;
        const user = userInfo();
        var jsonparams = {
            context_id: shareId,
            context_type: "Share",
            //like: likeType,
            "page[size]": 1
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RSENTS_URL() + '/total',
            params
        };
        const response = await jwtInterceptor(requestOptions);
        var totalList = response.data.totals;

        if (shareId) {
            if (totalList.length == 0)
            {
                // --- Hack to set counts to 0 if there are no totals
                commit("setLikeCountsByShareId", {
                    id: shareId + 'Like',
                    count: 0
                });
                commit("setLikeCountsByShareId", {
                    id: shareId + 'Thank',
                    count: 0
                });
            }
            totalList.forEach(obj => {
                commit("setLikeCountsByShareId", {
                    id: shareId + obj.type,
                    count: obj.total
                });
            });
        }
    },
    async fetchContentLikes({ commit }, inparams) {
        const { shareId, likeType } = inparams;
        const user = userInfo();
        var jsonparams = {
            context_id: shareId,
            context_type: "Share",
            type: likeType,
            include: 'creator_name',
            //like: likeType,
            "page[size]": 100
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RSENTS_URL(),
            params
        };
        const response = await jwtInterceptor(requestOptions);
        var likes = response.data.data;
        console.log("LIKES!!!!!!!!!!!!!!!!!!!!!")
        console.log(likes)
        console.log('----------')
        console.log(response.data)
        var key = shareId
        commit("setAllLikesByShareId", {key, likes: likes});
    }
};

const mutations = {
    clearState: state => {
        state.likesByShareId = {};
        state.likeCountsByShareId = {};
    },
    setAllLikesByShareId: (state, params) => {
        const {key, likes} = params
        var curarr = state.likesByShareId[key];
        if (!curarr) {
            curarr = [];
        }
        var theLikes = likes ? likes : []

        var byIdObj = {};
        byIdObj[key] = theLikes
        state.likesByShareId = { ...state.likesByShareId, ...byIdObj };
    },
    setLikeCountsByShareId: (state, like) => {
        var lc = {};
        lc[like.id] = like.count;
        state.likeCountsByShareId = { ...state.likeCountsByShareId, ...lc };
    },
    incrementLikesByShareId: (state, like) => {
        var lc = {};
        lc[like.id] = !state.likeCountsByShareId[like.id] ? 1 : state.likeCountsByShareId[like.id] + 1;
        state.likeCountsByShareId = { ...state.likeCountsByShareId, ...lc };
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
