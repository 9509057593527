<template>
  <section>
    <a class="minor" @click="openModal()">
      Manage Attendees
    </a>
    <div :class="[{ open: modalStatus }, 'modal', 'manage-attendees-modal']">
      <div class="shadow" @click="closeModal($event)"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="closeModal($event)"></i>
        <div class="heading">MANAGE ATTENDEES</div>
          <div>
            <span class="sub-heading" style="margin-left: 25px;">{{ payable.attributes.title }} </span>
          </div>
          <div>
            <div v-if="participantsLoaded">
              <div class="form-group" v-for="(participant, $index) in participants" :key="$index">
                <div v-if="participant.attributes['participation-status'] == 'will_attend'">
                  <label class="sub-heading-sub-text" style="">{{ label(participant) }}</label>
                  <div v-if="!participant.attributes['editable']" class="sub-heading-sub-text" style="color:grey">
                    <i>(Attendees who are Rallyhood Users may not be changed)</i>
                  </div>
                  <div>
                    <span style="font-size:.8rem; color: lightgrey; width:20%">First Name:</span>
                    <input v-model="participant.attributes['first-name']" type="text" class="form-control"
                           :style="style(participant)" placeholder="First" :disabled="!participant.attributes['editable']" />
                  </div>
                  <div>
                    <span style="font-size:.8rem; color: lightgrey;  width:20%">Last Name:</span>
                    <input v-model="participant.attributes['last-name']" type="text" class="form-control"
                           :style="style(participant)" placeholder="Last" :disabled="!participant.attributes['editable']" />
                  </div>
                  <div>
                    <span style="font-size:.8rem; color: lightgrey;  width:20%">Email:</span>
                    <input v-model="participant.attributes['contact-email']" style="color: lightgrey; text-align: left;  margin-top:5px; margin-left: 52px; width: 75%" type="text" class="form-control"
                         placeholder="Email" disabled="disabled" />
                    </div>
                </div>
              </div>
            </div>
          </div>
        <div class="actions">
          <button class="secondary" @click="closeModal">Cancel</button>
          <button class="primary" @click="save">Save</button>
        </div>
        </div>
      </div>
  </section>
</template>

<script>
import {compareBy, mergeByAtt} from "@/common";
import {mapState, mapActions} from "vuex";

export default {
  name: "ManageAttendees",
  props: ["payable", "rallyId"],
  data: function() {
    return {
      loadAttempted: false,
      participants: [],
      modalStatus: false,
      participantsLoaded: false
    }
  },
  computed: {
    ...mapState("contents", ["contentById"]),

    participantUserId(){
      var u = this.usersById[this.participationById[this.id + ""]];
      return u ?  u.id : "";
    },
    participantList(){
      if (!this.participantsLoaded) {
        return []
      }
      let parr = []
      let parts = this.payable.relationships.participations.data;

        parts.forEach(p => {
          this.participants.push({ attributes: {name: p.id}})
        })
      if (this.participants && this.participants.length > 0) {
        parr = this.participants.copy()
        parr = parr.sort(compareBy({}, "name"))
        return mergeByAtt(parr, [], "name")
      } else {
        return []
      }
    },

  },
  created() {
    this.participantsLoaded = false;
    this.fetchParticipants();
  },
  methods: {
    ...mapActions("participations", ["fetchParticipations"]),
    ...mapActions("user", ["updateUserInfo"]),
    closeModal(event) {
      this.modalStatus = false;
    },
    label(participant) {
      if (participant.attributes['editable']) {
        return "Attendee"
      } else {
        return "Attendee"
      }
    },
    async fetchParticipants() {
      let participants = await this.fetchParticipations({contextId: this.payable.relationships['context-share'].data.id, rallyId: this.rallyId})
      this.participants = participants.data
      this.participantsLoaded = true
    },
    openModal() {
      this.modalStatus = true;
    },
    async save() {
      for(let participant of this.participants) {
        if (participant.attributes['editable']) {
          let userData = {
            id: participant.attributes['user-id'], // Assuming each participant has an id attribute
            attributes: {
              'first-name': participant.attributes['first-name'],
              'last-name': participant.attributes['last-name']
            }
          };
          // Assuming updateUserInfo is an action in your Vuex store
          // that handles API calls to update user info.
          await this.updateUserInfo({updatedUser: userData, userNotificationSettings: {}, updateAll: false, userPrefs: []});
        }
      }
      this.$store.dispatch('user/flashSuccess', 'Attendee Changes Have Been Saved');
      this.closeModal()
    },
    style(participant) {
      if (participant.attributes['editable']) {
        return "text-align: left;  margin-top:5px; margin-left: 25px; width: 75%"
      } else {
        return "text-align: left;  margin-top:5px; margin-left: 25px; width: 75%; color: lightgray;"
      }
    },

  }
}
</script>

<style scoped>

</style>