<template>
  <div>
    <main id="rallysettings" class="rally-settings">
      <div style="margin-top: 5px; margin-left: 10px; margin-bottom: 10px;">
        <a v-on:click="backToRally" class="back" >
          <i class="fal fa-chevron-left icon"></i>
          Back
        </a>
      </div>
      <section class="accordions">
        <div class="accordion" v-if="this.canManageOptions">
          <div class="title" @click="toggleAccordion">Options</div>
          <RallyOptions :rallyId="this.rallyId" :isDesktopSize="isDesktopSize"></RallyOptions>
        </div>
        <div class="accordion" id="myrallyprofile" v-if="this.hasDirectory">
          <div class="title"  @click="toggleAccordion">My Rally Profile</div>
          <RallyProfile :rallyId="this.rallyId" :isDesktopSize="isDesktopSize"></RallyProfile>
        </div>
        <div class="accordion">
          <div class="title" @click="toggleAccordion">My Preferences</div>
          <UserRallyPreferences :rallyId="this.rallyId" :isDesktopSize="isDesktopSize" :canDelete="canDeleteRally"></UserRallyPreferences>
        </div>
        <div class="accordion" v-if="this.canManageFeatures">
          <div class="title" @click="toggleAccordion">Manage Features</div>
          <ManageFeatures :rallyId="this.rallyId" :isDesktopSize="isDesktopSize"></ManageFeatures>
        </div>
        <div class="accordion">
          <div class="title" @click="toggleAccordion">Manage Payments</div>
          <ManagePayments :rallyId="this.rallyId" :isDesktopSize="isDesktopSize"></ManagePayments>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
  import { Plugins } from "@capacitor/core";
  import { mapState, mapActions, mapGetters } from "vuex";
  import {avaUrl, router} from "../common";
  import UxMixin from "../components/UxMixin";
  import { eventBus } from "../main.js";
  import { VueToggle } from "../main.js"

  export default {
    mixins: [UxMixin],
    data() {
      return {
        rallyId: null,
        receiveEmails: null,
      };
    },
    computed: {
      ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),

      isDesktopSize() {
        // Define the screen width breakpoint for desktop size
        const desktopBreakpoint = 768; // Adjust the value based on your specific needs

        // Check if the screen width exceeds the desktop breakpoint
        return window.innerWidth >= desktopBreakpoint;
      },
      rally() {
        return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
      },
      canManageFeatures() {
        if (typeof this.rally === "undefined") {
          return false;
        } else {
          return ((this.rally.rally.attributes['plan-type'] == 'Pro' ||
              this.rally.rally.attributes['plan-type'] == 'Plus' ||
              this.rally.rally.attributes['plan-type'] == 'Premium')) && this.rally.rally.attributes['editable'] === true
        }
      },
      canManageOptions() {
        if (typeof this.rally === "undefined") {
          return false;
        } else {
          return this.rally.rally.attributes['editable'] === true
        }
      },
      canDeleteRally() {
        if (typeof this.rally === "undefined") {
          return false;
        } else {
          return this.rally.rally.attributes['deletable'] === true
        }
      },
      hasDirectory() {
        if(this.rallyChannelsByRallyId[parseInt(this.$route.params.rallyId)] === undefined) {
          return false;
        } else {
          return this.rallyChannelsByRallyId[parseInt(this.$route.params.rallyId)].some(obj => obj.attributes['type'].toLowerCase() === 'directory');
        }

      }
    },
    mounted() {
      this.loadPageData();
      if (window.location.href.indexOf("myrallyprofile") > 0){
        this.toggleAccordionById(document.getElementById("rallysettings"), "myrallyprofile" )
      }

    },
    methods: {
      ...mapActions("rallies", ["fetchRalliesByIds", "fetchRalliesWithInfo", "updateRally"]),
      ...mapActions("memberships", ["fetchMembersByRally"]),
      async loadPageData() {
        this.rallyId = parseInt(this.$route.params.rallyId);
        await this.fetchMembersByRally({rallyId: this.rallyId, status: undefined, fetchMyMemberInfo: true});
        let rallyObjs = {}
        rallyObjs[this.rallyId] = {id: this.rallyId}
        await this.fetchRalliesWithInfo({pageNumber: 1, rallyObjs: rallyObjs, parentRallyId: null, rallyId: null})
      },
      backToRally() {
        router.push({ path: '/' + this.rallyId });
      },
    }
  };
</script>

<style>

</style>
