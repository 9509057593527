import axios from "axios";
import qs from "qs";
import { RCHANS_URL, buildServiceParamMap, buildMapByValueName, RCALS_URL, railsTzToMomentTz } from "../../common";

import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
  calendarEntries: [],
  calendarEntriesById: {},
  calendarEntriesByContentId: {}
};

const getters = {
  calendarEntries: state => state.calendarEntries,
  calendarEntriesById: state => state.calendarEntriesById,
  calendarEntriesByContentId: state => state.calendarEntriesByContentId
};

const actions = {
  async fetchCalendarEntries({ commit, state }, inparams) {
    const { rallyIds } = inparams;

    const user = userInfo();
    var intRallyIds = rallyIds.map(function(item) {
      return parseInt(item, 10);
    });
    ///UmFsbHkzOTcwMg%3D%3D.json?format=json&from_date=2020-12-26&group_by=none&rally_ids=39702&status[]=open&status[]=published&status[]=draft&status[]=archived&timezone_name=Eastern+Time+(US+%26+Canada)&to_date=2021-02-06&user_ids=16630"
    var url = RCALS_URL() + "/" + encodeURIComponent(btoa("Rally" + intRallyIds[0])) + ".json";
    if (intRallyIds.length > 1) {
      //rcals expects this format for the url if there's more than one rally id 
      url = RCALS_URL() + "/" + encodeURIComponent(btoa("User" + user.user_id)) + ".json";
    }
    var jsonparams = {
      format: "json",
      from_date: inparams.from_date,
      group_by: "none",
      rally_ids: intRallyIds.toString(),
      status: ["open"],
      to_date: inparams.to_date,
      user_ids: user.user_id,
      timezone_name :  userInfo().timezone,
      friendly_repeat_summary: true
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url,
      params
    };
    const response = await jwtInterceptor(requestOptions);
    commit("setCalendarEntries", response.data.RHResponse.rh_data);

  }
};
const mutations = {
  setCalendarEntries: (state, entries) => {
    let byIdMap = buildMapByValueName("id", entries)
    let byCIdMap = buildMapByValueName("content_uid", entries)
    state.calendarEntriesById = {...state.calendarEntriesById, ...byIdMap}
    state.calendarEntriesByContentId = {...state.calendarEntriesByContentId, ...byCIdMap}
    state.calendarEntries = entries
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
