<template>
  <article v-if="visible" class="empty-state">
    <i :class="[icon, 'fal', 'icon']"></i>
    <div class="cta">
      {{ cta }}
    </div>
    <div class="subtitle">
      {{ subtitle }}
    </div>
  </article>
</template> 

<script>
  export default {
    props: ['channelType'],
    data() {
      return {
        visible: false,
        emptyState: {
          Files: {
            cta: "Let's Get Started!",
            icon: 'fa-file-alt',
            subtitle: 'Click on the "+" button to upload your first files.',
          },
          GivePayableGroup: {
            cta: 'Start A Fundraiser To Support Your Cause!',
            icon: 'fa-hand-holding-heart',
            subtitle: 'Raise money for a friend, a group, or whatever your cause.',
          },
          Links: {
            cta: 'Link to a Helpful Website for Your Group',
            icon: 'fa-link',
            subtitle: 'Click on the "+" button to share your first link. ',
          },
          Gallery: {
            cta: 'Time to take some photos - Your Gallery is empty.',
            icon: 'fa-image',
            subtitle: 'Click on the "+" button to create your first album. ',
          },
          Notifications: {
            cta: "You're All Up To Date!",
            icon: 'fa-bell',
            subtitle: 'There are no new notifications.',
          },
          MessageWall: {
            cta: "Let's Get Started!",
            icon: 'fa-comments',
            subtitle: 'Click on the "+" button to post your first message.',
          },
          Signups: {
            cta: "Let's Get Started!",
            icon: 'fa-list-ul',
            subtitle: 'Create a Sign-up List ',
          },
          Payables: {
            cta: 'Create Your First Event!',
            icon: 'fa-ticket-alt',
            subtitle: 'You can create multiple ticket types.  If it is a free event, simply enter $0 for the price.',
          },
          PayableGroup: {
            cta: "Let's Get Started!",
            icon: 'fa-usd-circle',
            subtitle: 'Click here to set up you first payment request.',
          },
        },
      };
    },
    computed: {
      cta() {
        return this.emptyState[this.channelType].cta;
      },
      icon() {
        return this.emptyState[this.channelType].icon;
      },
      subtitle() {
        return this.emptyState[this.channelType].subtitle;
      },
    },
    created(){
      setTimeout( function() {
        this.visible = true
      }.bind(this), 3000);
    }
  }
</script>
