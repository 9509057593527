<template>
  <div>
  <div v-if="true">
    <main :class="mainClass">
      <ToolBar :back="back" :options="toolbarOptions" v-on:perform="perform" />
      <section v-if="componentName" class="content-area">
      <StandardContent
        v-if="content"
        v-on:perform="perform"
        :content="content"
        :channel="channel"
        :detailed="true"
        :id="content.id"
        :rally="rally"
      />
        <!--<component
          v-on:perform="perform"
          :class="contentDetailsClasses(content)"
          :content="content"
          :is="componentName"
          :channelId="channelId" 
          :id="contentId"
          :contextId="channelId"
          :rallyId="rallyId"
        />-->
        <div v-if="allContentByContextTypeAndId[content.id] && allContentByContextTypeAndId[content.id].length > 0 && showChildContent">
          <component
            v-for="child_content in allContentByContextTypeAndId[content.id]"
            v-on:perform="perform"
            :class="contentListClasses(child_content)"
            :content="child_content"
            :is="listedComponentName(child_content)"
            :key="child_content.id"
            :rallyId="rallyId"
          />
        </div>
      </section>
    </main> 
  </div>
  </div>
</template>

<script>
import { Plugins } from "@capacitor/core";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  router,
  slugToTypeObjectMap,
  findDefaultChannelOfType,
  buildListOfValues,
  kebabCase, channelTypeObjectMap
} from "../common";
import ContentMixin from "../components/ContentMixin";
import ContentReloadMixin from "../components/ContentReloadMixin"; 
import {userInfo} from "@/common/user-info";

export default {
  mixins: [ContentMixin, ContentReloadMixin],
  props: ["channel","rally"],
  data() {
    return {
      loadAttempted: false,
      //channelId: null,
     // contextId: null,
      contextType: null,
      page: 1,
      parentContextIds: [],
      parentContextTypes: [],
      channelActions: {
        /* SignupList: [
          { click: "foo", icon: "fa-plus-circle", label: "New Sign-Up" },
          { click: "foo", icon: "fa-download", label: "Download" }
        ]*/
      }
    };
  },
  computed: {
    ...mapState("contents", ["contentById", "allContentByContextTypeAndId"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    ...mapState("participations", ["participationById"]),
    back() {
      return this.contextType == "Content" ? "toParent" : "navUp";
    },
    channelSlug() {
      // Used in place of channel.type.
      return this.channel.attributes.slug;
    },
    channelType() {
      return this.channel.attributes.type
    },
    channelTypePlural() {
      return channelTypeObjectMap()[this.channelType].channelTypePlural;
    },
    altChannelTypePlural() {
      return channelTypeObjectMap()[this.channelType].altChannelTypePlural;
    },
    componentName() {
      return this.content ? this.content.attributes.type + "Details" : null;
    },
   /* componentView() {
      if ( this.editing) {
        return 'Form';
      } else if ( this.shared ) {
        return 'Shared';
      } else if (this.detailed ) {
        return 'Details';
      } else {
        return 'Listed';
      }
    },*/
    content() {
      return this.contentById[parseInt(this.$route.params.id)];
    },
    mainClass() {
      return kebabCase(this.channelSlug) + " show"; 
    },
    contentType() {
       return channelTypeObjectMap()[this.channelType].contentType;
    },
    toolbarOptions() {
      let options = new Array();
      if (this.content) {
        const type = this.content.attributes.type;
        const actions =
          type in this.channelActions ? this.channelActions[type] : [];

        if (actions) {
          for (let button of actions) {
            options.push(button);
          }
          // TODO: Admin only
          //not mvp
          // options.push({ click: "foo", icon: "fa-cog", label: "Settings" });
        }
      }
      return options;
    },
    checkReloadData() {
      //first clause is for the new content use case...form was appearing blank
      if ( !(this.content && this.content.editMode ) && !this.loadAttempted &&
          (!this.content || (this.content && (!this.allContentByContextTypeAndId[this.content.id] ||
              !this.allContentByContextTypeAndId[this.content.id].length > 0)))) {
        this.loadPageData();
        return false;
      }
      return true;
    },    
    rallyId() {
      return this.$route.params.rallyId;
    },
    showChildContent() {
      return this.content.attributes.type == "Folder" ||
          this.content.attributes.type == "Album" ||
          this.content.attributes.type == "Thread" ||
          this.content.attributes.type == "Board" ||
          this.content.attributes.type == "SignupList";
    }
  },
  updated() {
    this.updateContext();
  },
  created() {
    this.loadContents();
  },

  methods: {
    ...mapActions("contents", ["fetchContents", "fetchContentById"]),
    ...mapActions("participations", ["fetchParticipations"]),
    contentDetailsClasses(content) {
      return "content details " + kebabCase(content.attributes["type"]);
    },
    contentListClasses(content) {
      return "content listed " + kebabCase(content.attributes["type"]);
    },
    navUp() {
      const path = `/${this.rallyId}/${this.channelSlug}`;
      router.push(path);
    },
    listedComponentName(content) {
      return channelTypeObjectMap()[this.channelType].componentName + "Listed";
    },
    openContent(contentId) {
      const path = `/${this.rallyId}/${this.channelSlug}/${contentId}`;
      router.push(path);
      if (this.loadPageData) {
        this.loadPageData();
      }
    },
    perform(method, params) {
      this[method](params);
    },
    toParent() {
      //TODO:  works but may break for nested content
      //const path =  `/${this.rallyId}/${this.channelSlug}/${this.content.relationships["context-share"].data.id}`;
      const path = `/${this.rallyId}/${this.channelSlug}`;
      router.push(path);
    },
    updateContext() {
      // TODO: Was this shared to a channel or a parent content (like a folder)
      // this.contextType = this.content.relationships['context-share'].data.type;

      // TODO: Fix to get signup lists to behave:
      this.contextType =
        this.channelType == "Signups" && this.$route.params.id != 789
          ? "Content"
          : "Channel";
    },
    log(message) {
      this.$log.debug(message);
    },
    myReloadPageData() {
    },
    async myFetchContents() {
      if (this.contentType == "Fundraiser" || this.contentType == "GivePayableGroup" || this.contentType == "PayableGroup" ){
         await this.fetchContents({ 
          ignorePagination: true,
          contentType: "Payable", 
          contextId: this.contentId,
          contextRallyId: this.rallyId,
          contextType: "Content",
          include: ["shares", "participations"],
          type: "Payable",
          rallyId: this.rallyId,
          customPageSize: 100
        });
        try {
          await this.fetchContents({
            ignorePagination: true,
            contentType: "Event",
            contextId: this.contentId,
            contextRallyId: this.rallyId,
            contextType: "Content",
            include: ["shares"],
            type: "DeadlineEvent",
            rallyId: this.rallyId
        })
        } catch (e){
          console.log(e)
        }

        this.fetchContentById({
          id: this.contentId,
          contextChannelId: this.channelId,
          contentType: this.contentType
        });
        try {
          this.fetchContents({
            ignorePagination: true,
            contentType: "Message",
            contextId: this.contentId,
            contextRallyId: this.rallyId,
            contextType: ["Fundraiser","PayableGroup"],
            type: "PaymentCustomFieldsDefinition",
            contextChannelId: this.channelId
        })
       // content_type=Event&context_id=15422180&context_rally_id=126297&context_type=Content&include=shares&status%5B%5D=0&status%5B%5D=1&status%5B%5D=2&timezone=Eastern+Time+%28US+%26+Canada%29&type=ChildPayableEvent&use_event_timezone=false 

      this.fetchContents({
          ignorePagination: true,
          contentType: "Message",
          contextId: this.contentId,
          contextRallyId: this.rallyId,
          contextType: "Content",
          type: "NotesInstructions",
          include: ["shares"]
        })
      this.fetchContents({
        ignorePagination: true,
        contentType: "Message",
        contextId: this.contextId,
        contextRallyId: this.rallyId,
        contextType: "Content",
        type:  "ExtraInformation"
      })
        this.fetchContents({
          ignorePagination: true,
          contentType: "Event", 
          contextId: this.contentId,
          contextRallyId: this.rallyId,
          contextType: "Content",
          type:  "ChildPayableEvent",
          include: ["shares"],
          use_event_timezone: false,
          timezone: userInfo().timezone
        })
        } catch (e){
           console.log(e)
        }
      } else if (!this.content) { //this check had to go in place to keep the contentById map from getting reloaded and blanking the newly created object
      //passing a context channel for events causes it to fail to load event details
      if (this.contentType && this.contentType.forEach){
        console.log("contentype.....")
        console.log(this.contentType)
         this.contentType.forEach(ct =>{
            this.fetchContentById({
            id: this.contentId,
            contextChannelId: this.contentType !== "Event" ? this.channelId: "",
            contextRallyId: this.rallyId,
            contentType: ct
            })
         })
      } else {
        this.fetchContentById({
          id: this.contentId,
          contextChannelId: this.contentType !== "Event" ? this.channelId: "",
          contextRallyId: this.rallyId,
          contentType: this.contentType
        })
      }
    }
  }
  }
}
</script>
