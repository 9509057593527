import {Plugins} from "@capacitor/core";

const { Storage } = Plugins;

export function authHeader() {
    // return authorization header with jwt token
    return "Bearer " + accessToken();
}

export function accessToken() {
    // return access token from jwt token
    let access_token =  localStorage.getItem('at');
    //console.log("GETTING ACCESS TOKEN::::::::::")
    //console.log(access_token);
    if (access_token) {
        return access_token;
    } else {
        return "";
    }
}

export async function refreshToken() {
    // return refresh token from jwt token
    let user = await Storage.get({ key: 'user' });
    if (user) {
        let parsedUser = JSON.parse(user.value);
        if (parsedUser && parsedUser.refresh_token) {
            return parsedUser.refresh_token;
        } else {
            return "";
        }
    } else {
        return ""
    }
}

export function setAccessToken(access_token) {
    //console.log("SETTING ACCESS TOKEN::::::::::")
    //console.log(access_token);
    localStorage.setItem("at", access_token);
}

export async function setRefreshToken(refresh_token) {
    let user = await Storage.get({ key: 'user' });
    if (user) {
        let parsedUser = JSON.parse(user.value);
        //console.log("Setting user refresh token: " + refresh_token);
        parsedUser.refresh_token = refresh_token;
        await Storage.set({
            key: 'user',
            value: JSON.stringify(parsedUser)
        });
    }
}