<template>
    <div :class="[{ open: dmModalStatus }, 'modal', 'direct-message-modal']">
      <div class="shadow" @click="closeModal($event)"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="closeModal($event)"></i>
        <div class="heading">DIRECT MESSAGE</div>

        <div class="info">
          <div class="form-group">
            <div v-if="this.dmModalStatus">
              <UserAvatar v-if="pUserId" :pUserId="pUserId"  :pRallyId="pRallyId" :pMembership="pMembership" type="avatar" /> {{userName}} 
            </div>
            <input
              type="hidden"
              v-bind:value="pUserId"
              disabled
              />
          </div>
          <br/>
          <div class="form-group">
            <input
              type="text"
              v-model="dmSubject"
              placeholder="Subject"
              />
          </div>
          <br/>
          <div class="form-group">
            <textarea rows="5" v-model="dmText">
            </textarea>
          </div>
        </div>
        <div class="actions">
          <button class="secondary" @click="closeModal">Cancel</button>
          <button class="primary" @click="sendDirectMessage">Send</button>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import RallyPaginationMixin from "../components/RallyPaginationMixin";  
import {findDefaultChannelObjOfType} from "../common";
import { userInfo } from "../common/user-info";
import { eventBus } from "../main.js";

  export default {
    mixins: [RallyPaginationMixin],
    props: ['pRallyId', 'channelId', 'pUserId', 'uniqueId', "pMembership"],
      data() {
        return {
          dmModalStatus: false,
          dmSubject: '',
          dmText: ''
        };
      },
    created () { 
      eventBus.$on('maybeDirectMessageEvent' + this.uniqueId, () => {
          console.log("received maybe direct message..." + this.uniqueId)
          this.maybeDirectMessage();
      })
    },
    computed: {
      ...mapState("channels", ["privateChannels"]),
      ...mapState("memberships", ["profileByMembershipId", "membersByMemberId", "membersByUserIdByRallyId"]),
      membersByUserId() {
        return this.membersByUserIdByRallyId[this.rallyId];
      }, 
      membership(){
        return this.membersByUserIdByRallyId ?  this.membersByUserId[this.pUserId] : null 
      },
      userName() {
        if (this.membership !== undefined) {
          return this.membership.attributes['first-name'] + ' ' + this.membership.attributes['last-name'];
        } else {
          return '';
        }
      },
      rallyId(){
        return this.pRallyId ? this.pRallyId : this.$route.params.rallyId
      }
    },
    methods: {

        ...mapActions("channels", ["fetchPrivateChannels"]),
        ...mapActions("contents", ["createContents"]),
        ...mapActions("memberships", ["fetchMembersByRallyAndUserId"]), 
        closeModal(event) {
          this.dmSubject = ""
          this.dmText = ""
          this.dmModalStatus = false;
        },
        maybeDirectMessage() {
          // TODO: Do not allow people to talk to themselves,
          // it can be unhealthy in these trying times. :-)
          if (!this.dmModalStatus){
            const user = userInfo();
            if ( this?.pUserId === undefined || parseInt(user.user_id) == parseInt(this?.pUserId)) { return false; }
            this.fetchPrivateChannels({rallyId: this.rallyId, channelUsers: [user.user_id,this?.pUserId]});
            if (!this.membership){
              console.log("fetching membership in DM Modal!")
              //this.fetchMembersByRallyAndUserId({rallyId: this.rallyId, userIds: [this.pUserId]})
            }
            this.dmModalStatus = true;
          }
        },
        sendDirectMessage() {
          const channelKey = (this.rallyId + "").concat(':').concat(this?.pUserId)
          const privateChannel = this.privateChannels[channelKey];

            var params = {
              contentType: 'Message',
              contextId: privateChannel.id,
              contextType: "Channel",
              type: 'PrivateMessage',
              contextChannelId: privateChannel.id,
              contextRallyId: this.rallyId,
              status: 1,
              title: this.dmSubject,
              body: this.dmText
            };

            this.createContents(params);
            this.closeModal();
        },
    }
  }
</script>
