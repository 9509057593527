<template>
  <article v-on:click="$emit('perform', 'openContent', content)">
    <div class="wrapper">
      <img class="avatar" :src="callAvaUrl(eventAvatar)" />
      <div v-if="totalPayments > 0" class="payments">
        {{ totalPayments }} Payments
      </div>
      <div class="title">{{ content.attributes.title }}</div>
    </div>
  </article>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex"; 
import { formatCurrency, findOrganizerParticipation, avaUrl } from "../../../common";
import ContentMixin from "@/components/ContentMixin.vue";
import ContentPayableMixin from "@/components/ContentPayableMixin.vue";
export default {
  name: "PayableGroupListed",
  props: ["id", "rally", "channel", "content"],
  mixins: [
      ContentPayableMixin
  ],
  created() {
    //this.user_id = userInfo();
   /* this.fetchContents({
      contentType: "Payable",
      contextId: this.content.id,
      contextChannelId: this.channelId,
      contextRallyId: this.rallyId,
      contextType: "Content",
      include: ["shares", "participations"],
      type: "Payable",
      pageNumber: 1
    });*/
  },
  computed: {
    ...mapState("contents", ["contentById"]),
    eventAvatar() {
      return this.contentById[this.content.relationships.avatar.data.id];
    },
    totalPayments() {
      let paymentCount = 0
      if(this.content.attributes['child-content-participants'] && this.content.attributes['child-content-participants'][0]) {
        this.content.attributes['child-content-participants'].forEach(participant => {
          paymentCount = paymentCount + participant.quantity
        })
      }
      return paymentCount
    },
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    eventMonth(eventDate) {
      return moment(eventDate).format("MMM");
    },
    callAvaUrl(c){
      return avaUrl(c)
    },
    eventDay(eventDate) {
      return moment(eventDate).format("DD");
    }
  }
};
</script>
