<template>
  <div class="body">
    <div>
      <div style="display:flex">
        <div class="sub-heading">RALLY PROFILE </div>
        <div class="sub-heading-sub-text" style="padding-left: 10px;">(customized for just this rally)</div>
      </div>
      <div style="margin-bottom: 15px;">
        <router-link to="/settings" class="link-button" style="font-size: smaller;">View My Universal Rallyhood Profile</router-link>
      </div>
      <div class="well form-groups" v-if="myMembershipLoaded && myProfilesLoaded" >
        <form>
          <div class="sub-heading profile-sub-heading">About Me</div>
          <div class="form-group">
            <label for="first_name" class="profile-form-label">First Name</label>
            <input name="first_name" type="text"  v-model="myProfile.attributes['first-name']"  />
          </div>
          <div class="form-group">
            <label for="last_name" class="profile-form-label">Last Name</label>
            <input name="last_name" type="text" v-model="myProfile.attributes['last-name']" />
          </div>
          <div class="form-group">
            <label for="position" class="profile-form-label">Current Position or Role</label>
            <input name="position" type="text" v-model="myProfile.attributes['position']"  />
          </div>
          <div class="form-group">
            <label for="expertise" class="profile-form-label">Areas of Expertise or Interest</label>
            <textarea name="expertise" v-model="myProfile.attributes['expertise']"  ></textarea>
          </div>
          <div class="form-group">
            <label for="additional_info" class="profile-form-label">Additional Information</label>
            <textarea name="additional_info" v-model="myProfile.attributes['additional-info']"  ></textarea>
          </div>
          <div class="form-group">
            <label for="birthday_month" class="profile-form-label">Birthday Month</label>
            <input name="birthday_month" type="text" v-model="myProfile.attributes['birthday-month']"   />
          </div>
          <div class="sub-heading profile-sub-heading">Contact Information</div>
          <div class="form-group">
            <label for="address" class="profile-form-label">Address</label>
            <input name="address" type="text" v-model="myProfile.attributes['address']"  />
          </div>
          <div class="form-group">
            <label for="city" class="profile-form-label">City</label>
            <input name="city" type="text" v-model="myProfile.attributes['city']"  />
          </div>
          <div class="form-group">
            <label for="state" class="profile-form-label">State</label>
            <input name="state" type="text" v-model="myProfile.attributes['state']"  />
          </div>
          <div class="form-group">
            <label for="zip" class="profile-form-label">Zip Code</label>
            <input name="zip" type="text" v-model="myProfile.attributes['zip']"   />
          </div>
          <div class="form-group">
            <label for="primary_phone" class="profile-form-label">Primary Phone</label>
            <input name="primary_phone" type="text" v-model="myProfile.attributes['primary-phone']"  />
          </div>
          <div class="sub-heading profile-sub-heading">Social Media</div>
          <div class="form-group">
            <label for="facebook_url" class="profile-form-label">Facebook URL</label>
            <input name="facebook_url" type="text" v-model="myProfile.attributes['facebook-url']"  />
          </div>
          <div class="form-group">
            <label for="linkedin_url" class="profile-form-label">LinkedIn URL</label>
            <input name="linkedin_url" type="text" v-model="myProfile.attributes['linkedin-url']"   />
          </div>
          <div class="form-group">
            <label for="twitter_url" class="profile-form-label">Twitter URL</label>
            <input name="twitter_url" type="text" v-model="myProfile.attributes['twitter-url']"   />
          </div>
          <hr/>
          <div class="form-group radios">
            <label>
              <input name="customProfile" type="radio"   @click="setCustomProfile('true')" :checked="doSetToCustom">
              <div class="circle"></div>
              <div>
                SAVE CHANGES FOR MY CUSTOMIZED PROFILE (USED JUST FOR THIS RALLY).
              </div>
            </label>
            
              <label>
                <input name="customProfile" type="radio"  @click="setCustomProfile('false')" :checked="!doSetToCustom">
                <div class="circle"></div>
                <div>
                  SAVE CHANGES AND USE ACROSS ALL MY RALLIES (AKA: 'MY UNIVERSAL RALLYHOOD PROFILE')
                </div>
              </label>
            
          </div>

          <div class="actions">
            <button class="primary" @click.prevent="updateRallyProfile()" >Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {userInfo} from "@/common/user-info";
export default {
  name: "RallyProfile",
  props: ['rallyId', 'isDesktopSize'],
  data: function() {
    return {
      myMembershipLoaded: false,
      myProfilesLoaded: false,
      myTempProfile: {attributes:{}},
      doSetToCustom: true
    };
  },
  computed: {
    ...mapState("memberships", ["myMembershipByRallyId", "myProfileByRallyId"]),
    ...mapState("user", ["usersById"]),
    currentUser() {
      return this.usersById[userInfo().id];
    },
    isCustomProfile() {
      return this.myMembership && this.myMembership.attributes && this.myMembership.attributes['is-custom'] ? (this.myMembership.attributes['is-custom'] === 'true' || this.myMembership.attributes['is-custom'] === true) : false
    },
    myMembership() {
      if( this.myMembershipLoaded) {
        return this.myMembershipByRallyId[parseInt(this.$route.params.rallyId)]
      }
      return {id: 0}
    },    
    myProfile() {
      if ( this.myProfileByRallyId[parseInt(this.$route.params.rallyId)]) {
        console.log("MY PROFILE")
        console.log(this.myProfileByRallyId[parseInt(this.$route.params.rallyId)])
        return this.myProfileByRallyId[parseInt(this.$route.params.rallyId)] 
      } else {    
        return this.myTempProfile
      }
    }
  },
  created() {

    console.log("created: calling fetch members and custom profs")
    this.fetchMembersByRallyAndUserId({rally_id: parseInt(this.$route.params.rallyId), userIds: [userInfo().id]}).then(() => {
      this.myMembershipLoaded = true;
      this.myTempProfile = this.createTempProfile()
     
    });
    this.getMyCustomProfiles({rallyId: parseInt(this.$route.params.rallyId)}).then(() => {
      this.myProfilesLoaded = true;
    });
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRallyAndUserId", "getMyCustomProfiles", "updateMemberProfile"]),
    ...mapActions("user", ["updateUserProfile"]),
   /* copyMembershipAttributes() {
      this.currentUser.attributes['additional-info'] = this.myProfile.attributes['additional-info']
      this.currentUser.attributes['address'] = this.myProfile.attributes['address']
      this.currentUser.attributes['city'] = this.myProfile.attributes['city']
      this.currentUser.attributes['country'] = this.myProfile.attributes['country']
      this.currentUser.attributes['position'] = this.myProfile.attributes['current-position']
      this.currentUser.attributes['expertise'] = this.myProfile.attributes['expertise']
      this.currentUser.attributes['facebook-url'] = this.myProfile.attributes['facebook-url']
      this.currentUser.attributes['first-name'] = this.myProfile.attributes['first-name']
      this.currentUser.attributes['last-name'] = this.myProfile.attributes['last-name']
      this.currentUser.attributes['linkedin-url'] = this.myProfile.attributes['linkedin-url']
      this.currentUser.attributes['primary-phone'] = this.myProfile.attributes['primary-phone']
      this.currentUser.attributes['state'] = this.myProfile.attributes['state']
      this.currentUser.attributes['twitter-url'] = this.myProfile.attributes['twitter-url']
      this.currentUser.attributes['zip-code'] = this.myProfile.attributes['zip']
    },*/
    createTempProfile() {
      console.log("creating temp profile...")
      console.log(this.myMembership)
      let prof =  {attributes:{}, relationships: {membership: {data: {id:this.myMembership.id}}}}
      this.copyAttributes(this.myMembership, prof)
      return prof
    },
    copyAttributes(from, to) {
      to.attributes['additional-info'] = from.attributes['additional-info']
      to.attributes['address'] = from.attributes['address']
      to.attributes['city'] = from.attributes['city']
      to.attributes['country'] = from.attributes['country']
      to.attributes['position'] = from.attributes['current-position']
      to.attributes['expertise'] = from.attributes['expertise']
      to.attributes['facebook-url'] = from.attributes['facebook-url']
      to.attributes['first-name'] = from.attributes['first-name']
      to.attributes['last-name'] = from.attributes['last-name']
      to.attributes['linkedin-url'] = from.attributes['linkedin-url']
      to.attributes['primary-phone'] = from.attributes['primary-phone']
      to.attributes['state'] = from.attributes['state']
      to.attributes['twitter-url'] = from.attributes['twitter-url']
      to.attributes['zip-code'] = from.attributes['zip']
      to.attributes['is-custom'] = from.attributes['is-custom']
    },
    setCustomProfile(value) {
      console.log("value is" + value)
      this.doSetToCustom =  value
      this.myMembership.attributes['is-custom'] = value;
      //if (value == 'true'){
        //console.log("setting custom profile")
        /*let prof =  {attributes:{}, relationships: {membership: {data: {id:this.myMembership.id}}}}
        this.myMembership.attributes['is-custom'] = value;
        if (!this.myProfile){
         // this.copyAttributes(this.myMembership, prof)
        } else {
          prof = this.myProfile
        }
        this.copyAttributes(this.myMembership, prof)
        prof.relationships = {membership: {data: {id:this.myMembership.id}}}
        this.myProfileByRallyId[parseInt(this.$route.params.rallyId)] = prof
        this.myTempProfile = prof
        this.myProfilesLoaded = true;*/
      //}

    },
    updateRallyProfile() {
      console.log("setting custom profile")
      //let prof = {attributes:{}}
      //this.copyAttributes(this.myMembership, prof)
     // this.myProfileByRallyId[parseInt(this.$route.params.rallyId)] = prof
      //this.myTempProfile = prof
      //this.myProfilesLoaded = true;
      //this.copyAttributes(this.myProfile, this.myMembership)
      this.myMembership.attributes['is-custom'] = this.doSetToCustom + '' ;
      this.myProfile.attributes['is-custom'] = this.doSetToCustom + '';
      if(this.doSetToCustom) {
        this.updateMemberProfile({member: this.myMembership, profile: this.myProfile});
      } else {
        this.copyAttributes(this.myProfile, this.currentUser)
        this.updateMemberProfile({member: this.myMembership, profile: this.myProfile});
        this.updateUserProfile({updatedUser: this.currentUser, updateAll: true})
      }
      this.$store.dispatch('user/flashSuccess', 'Rally Profile successfully updated!');
    }
  }
}
</script>

<style scoped>

</style>