<template>
  <span>
    <a @click.stop="openPromoteModal" class="action">
      <i class="fal fa-sort-up"></i><span class="">Promote</span>
    </a>
    <div :class="[{ open: promoteModalStatus }, 'modal', 'sharing-modal']">
      <div class="shadow" @click="closePromoteModal"></div>
      <div class="body" style="overflow:scroll;">
        <i class="fal fa-times exit" @click="closePromoteModal"></i>
        <div v-if="isLoading" class="loading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-if="!isLoading " class="loaded">
          <div class="form-group">
            <label for="body"><b>Title</b></label>
            <input
                v-model="promote.data.attributes.title"
                id="title"
                name="title"
                type="text"
            />
          </div>
          <div class="form-group">
            <label for="body"><b>Body</b></label>
            <vue-editor
                v-model="promote.data.attributes.message"
                :editorToolbar="customToolbar"
            ></vue-editor>
          </div>
        <div class="form-group radios">
          <div class="radio-option">
            <input
                id="messageWallOption"
                v-model="promoteTo"
                type="radio"
                :checked="promoteTo === 'all'"
                value="all"
                class="hidden-radio"
            />
            <label for="messageWallOption" class="custom-radio-label">
              <div class="circle"></div>
              <div class="label-text">
                <div>Promote on Message Wall</div>
                <div style="font-size: 0.8rem;">(will also re-post in tonight's Daily Digest email)</div>
              </div>
            </label>
          </div>

          <div class="radio-option">
            <input
                id="dailyDigestOption"
                v-model="promoteTo"
                type="radio"
                :checked="promoteTo === 'digest_only'"
                value="digest_only"
                class="hidden-radio"
            />
            <label for="dailyDigestOption" class="custom-radio-label">
              <div class="circle"></div>
              <div class="label-text">
                <div>Promote only in the Daily Digest</div>
                <div style="font-size: 0.8rem;">(will not be visible on the message wall)</div>
              </div>

            </label>
          </div>
        </div>
          <div class="actions">
            <div class="left" style="width:45%">
              <button class="secondary" @click="closePromoteModal">Cancel</button>
            </div>
             <div class="right" style="width:45%">
              <button class="primary" @click.prevent="promoteContent" >Save</button>
             </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "PromoteIcon",
  props: ['content', 'rallyId', 'channelId', 'menu', 'type'],
  data: function() {
    return {
      customToolbar: [
        "bold",
        "italic",
        "underline",
        { list: "bullet" },
        { color: [] },
        { background: [] },
        //"image",
        "link",
        { align: [] }
      ],
      promoteModalStatus: false,
      isLoading: true,
      promote: null,
      promoteTo: 'all'
    };
  },
  computed: {
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
  },
  async created () {

  },
  methods: {
    ...mapActions("contents", ["createContents","createPromote"]),
    closePromoteModal()
    {
      this.promoteModalStatus = false;
      this.promote = null;
      this.isLoading = true;
    },
    async promoteContent() {
      const messageWallChannel = this.rallyChannelsByRallyId[this.rallyId].find(channel => channel.attributes.type === 'MessageWall');

      await this.createContents({  title: this.promote.data.attributes.title, body: this.promote.data.attributes.message,
        contextType: "Channel",
        type: "WallMessage",
        contentType: "Message",
        rallyId: this.rallyId,
        contextId: messageWallChannel.id,
        contextChannelId: messageWallChannel.id,
        contextRallyId: this.rallyId,
        status: 1,
        visibility: this.promoteTo, });
        this.$store.dispatch('user/flashSuccess', 'Repost was successful!');
       this.closePromoteModal()
    },
    async openPromoteModal()
    {
      this.promoteModalStatus = true;
      this.promote = await this.createPromote({contentId: this.content.id,
        contentType: 'Fundraiser',
        contextRallyId: this.rallyId,
        contextChannelId: this.channelId})
      this.isLoading = false;

    }
  }
}
</script>

<style scoped>
.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* or any desired space between radio options */
}

.hidden-radio {
  display: none;
}

.custom-radio-label {
  display: flex;
  align-items: center;
}

.label-text {
  display: block;
  margin-top: 0px;
  margin-left: 15px;
}

.label-text > div:last-child {
  margin-top: -12px; /* Add a small margin to the top of the second line of text, adjust as needed */
}

.circle {
  width: 20px; /* adjust as needed */
  height: 20px;
  border-radius: 50%;
  border: 1px solid black; /* your desired color */
  margin-right: 10px;
}

/* If you want to visually show selection, add a style for checked state */
.hidden-radio:checked + .custom-radio-label .circle {
  background-color: #008295; /* or any color to represent "checked" */
}
</style>