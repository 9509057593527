<script>
  import { Plugins, Filesystem, FilesystemDirectory, FilesystemEncoding, Share, Capacitor } from "@capacitor/core";
  import moment from "moment";
  import axios from "axios";

  export default {
    methods: {
      async downloadFile(fileUrl, fileName) {
         try {
          const response = await fetch(fileUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/octet-stream',
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('There was an error downloading the file:', error);
        }

      },

      formatDate(thedate) {
         return moment(thedate).format("MMMM DD, YYYY hh:MM a")
      },
      async saveFile(base64Data) {
        try {
          var result = await Filesystem.writeFile({
            path: "Downloads/yoda.png",
            data: base64Data,
            directory: FilesystemDirectory.directory,
            encoding: FilesystemEncoding.UTF8
          });
        } catch (e) {
          console.error("Unable to write file", e);
        }
      },

      log(message) {
        this.$log.debug(message);
      }
    }
  };
</script>
