<template >
  <article class="content direct-message listed">
     <DirectMessageModal :pUserId="pUserId" :uniqueId="this.content.id" :pRallyId="parseInt(this.rallyId)"/>
    <UserAvatar
      :pUserId="creatorId"
    ></UserAvatar>
    <div class="author-name">
      {{ byLine }}
      <span
        v-if="content.attributes.title"
        class="title"
      >
        - {{content.attributes.title}} 
      </span>
    </div>
    <div class="author-name">
       {{ content.channel_rally_name }} 
    </div>
    <div class="created-at">
      {{ when }}
    </div>
    <div class="body" style="margin-left: 70px;">
      <div >{{content.attributes.body}}</div>
      <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(this.channelId)" />
    </div>
    <div
      class="actions"
      v-click-outside="hideActions"
     
    >
      <i v-if="false && showElipsis"
        @click="showElipsis = !showElipsis"
        class="action ellipsis-button more fa-ellipsis-h fas"
      />
      <div class="action ellipsis-button more">
        <ul v-if="false && showElipsis" class="additional">
          <li v-if="false" @click="$emit('perform', 'edit')" class="option">
            <i class="fal fa-pencil"></i>
            Edit
          </li>
        </ul>
      </div>
      <i v-if="!myMessage" @click="newInline()" class="fal fa-reply"></i>

    </div>
  </article>
</template>

<script>
import { Plugins } from "@capacitor/core";
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../../../common";
import ContentMixin from "../../ContentMixin";
import { eventBus } from "../../../main.js";

export default {
  name: "PrivateMessageListed",
  props: ["id", "content", "pUserId"],
  mixins: [ContentMixin],
  data() {
    return {
      page: 1,
      show_create: false,
      showElipsis: false,
      polling_int: null,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("messages", ["allMessagesByRallyId"]),
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("channels", ["channelsById"]),  
    ...mapState("rallies", [ "allRalliesByRallyId"]),
    creatorId() {
      return this.content.attributes['creator-id']
    },
    myMessage(){
      return this.creatorId == this.user.user_id
    },
    /*isEditable() {
      return this.content.editable != 'true';
    },*/
    channel() {
      return this.content.privateMessageChannel.id
      //return this.channelsById[this.channelId]
    },
    rallyId(){
        return this.content.privateMessageChannel.attributes['rally-id']
      //return this.channel.attributes['rally-id']
    },
    rallyTitle(){
      return this.allRalliesByRallyId[parseInt(this.rallyId)] ? this.allRalliesByRallyId[parseInt(this.rallyId)].attributes["title"] : ""
    }
  },
  created() {
    this.$route.params["pageNumber"] = 1;
  },

  methods: {
    ...mapActions("messages", [
      "fetchAMessage",
      "createMessage"
    ]),
    newInline(params) {
      eventBus.$emit('maybeDirectMessageEvent' + this.content.id)
    },
    hideActions() {
      this.showElipsis = false;
    },
    perform(method, params) {
      if ( this[method] ) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit('perform', method, params);
      }
    },
    formatDate(ds){
      return moment(ds).fromNow()
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
