<template>
  <div>
      <main class="details members index">
      <ToolBar back="backToMembers" v-on:perform="perform" />
      <article :class="['detail', 'member', 'privacy-' + memberPrivacy]">
        <div v-if="this.member && (isSelf || isAdmin || memberPrivacy != 2)">
          <section class="avatar-section">
            <div style="margin-bottom: 1rem; text-align: center;">
              <UserAvatar :pRallyId="pRallyId" :pUserId="memberUser.id" :pMembership="member"  type="author-avatar-large" />
            </div>
            <div class="name">
                     {{ memberField(["first-name"])}} {{ memberField(["last-name"]) }} ({{
                member.attributes.role
              }})
              <div style="margin-top: 1rem;text-align:center" @click="launchDMModal"><i class="fal fa-comment" style="float:left;"></i></div>
               <DirectMessageModal :pUserId="memberUser.id" :uniqueId="uniqueId" :pRallyId="rallyId" :pMembership="this.member"/>
            <i v-if="isEmbeddedComponent" class="fa fa-envelope" style="padding-left: 20px;"
               data-target="#primary-modal"
               data-toggle="modal"
               :href="'/users/' + memberUser.id + '/personal_messages/new'"/>
            </div>
          </section>
          <section
            v-if="
              memberField(['current-position', 'position']) || memberField(['expertise']) || memberField(['additional-info']) 
            "
            class="profile-section"
          >
            <div v-if="memberField(['current-position', 'position'])" class="info">
              <h3>Current Position</h3>
              {{  memberField(['current-position', 'position'])  }}
            </div>
            <div v-if="memberField(['expertise'])" class="info">
              <h3>Areas of Expertise or Interest</h3>
              {{ memberField(["expertise"]) }}
            </div>
            <div v-if="memberField(['additional-info'])" class="info">
              <h3>Additional Information</h3>
              {{ memberField(["additional-info"]) }}
            </div>
          </section>
          <section v-if="isSelf || isAdmin || memberPrivacy == 0" class="contact-section">
            <h3>Contact Information</h3>
            <div
              class="info"
              v-if='memberField(["address"])  || memberField(["city"]) || memberField(["state"]) || memberField(["zip"])'
            >
              <i class="fal fa-map-marker"></i>
              <span v-if="memberField(['address'])">{{ memberField(["address"]) }}, </span>
              <span v-if="memberField(['address2']) ">{{ memberField(["address2"])  }}, </span>
              <span v-if="memberField(['address3']) ">{{ memberField(['address3']) }}, </span>
              <span v-if="memberField(['city'])">{{ memberField(['city']) }}, </span>
              <span v-if="memberField(['state'])">{{ memberField(['state']) }} </span>
              <span v-if="memberField(['zip'])">{{ memberField(['zip']) }}</span>
            </div>
            <div class="info half" v-if="memberField(['primary-phone'])">
              <i class="fal fa-phone"></i>
              <a :href="'tel:' + memberField(['primary-phone'])" target="_blank">{{
                memberField(["primary-phone"])
              }}</a>
            </div>
            <div class="info half" v-if="memberField(['email'])">
              <i class="fal fa-envelope"></i>
              <a :href="'mailto:' + memberField(['email'])" target="_blank">{{
                memberField(["email"])
              }}</a>
            </div>
            <div class="info half" v-if="memberField(['facebook-url'])">
              <i class="fab fa-facebook"></i>
              <a :href="memberField(['facebook-url'])" target="_blank">{{
                memberField(["facebook-url"])
              }}</a>
            </div>
            <div class="info half" v-if="memberField(['linkedin-url'])">
              <i class="fab fa-linkedin"></i>
              <a :href="memberField(['linkedin-url'])" target="_blank">{{
                m["linkedin-url"]
              }}</a>
            </div>
            <div class="info half" v-if="memberField(['twitter-url'])">
              <i class="fab fa-twitter"></i>
              <a :href="memberField(['twitter-url'])" target="_blank">{{
                memberField(["twitter-url"])
              }}</a>
            </div>
            <div class="info half" v-if="memberField(['birthday-month'])">
              <i class="fal birthday-cake"></i>
              Birthday: {{ memberField(["birthday-month"]) }}
            </div>
          </section>
        </div>

        <section v-if="isSelf">
          <h3>My Privacy Settings</h3>
          <form>
            <div class="form-group stacked-labels radios">
              <label>
                <input v-model="memberPrivacy"  v-on:click="changePrivacy($event)" name="privacy" type="radio" value="0" />
                <div class="circle"></div>
                Show my full profile.
              </label>
              <label>
                <input v-model="memberPrivacy"   v-on:click="changePrivacy($event)" name="privacy" type="radio" value="1" />
                <div class="circle"></div>
                Hide my contact information.
              </label>
              <label>
                <input v-model="memberPrivacy"  v-on:click="changePrivacy($event)" name="privacy" type="radio" value="2" />
                <div class="circle"></div>
                Hide my full profile.
              </label>
              <small style="margin-left: 33px; font-size: italic;">
                * Admins always have viewing rights.
              </small>
            </div>
          </form>
        </section>
        <footer v-if="isSelf">
          <i
            class="fal fa-pencil edit"
            title="Edit"
            @click.stop="editProfile"
          ></i>
        </footer>
        <footer v-else-if="isAdmin">
          <i
            v-if="m.status == 'Invited'"
            class="fal fa-redo reinvite"
            title="Resend Invitation"
            @click.stop="reinvite"
          ></i>
          <i
            class="fal fa-times-circle remove"
            title="Remove"
            @click.stop="remove"
          ></i>
        </footer>
      </article>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"; 
import { router } from "../common";
import { findDefaultChannelOfType } from "../common";
import MemberMixin from "../components/MemberMixin";
import { userInfo } from "../common/user-info"
import { eventBus } from "../main.js";

export default {
  mixins: [MemberMixin],
  data() {
    return {
      memberPrivacy: 0,
      uniqueId : Math.random(),
    };
  },
  created() {
    if (this.member){
      this.memberPrivacy = this.member.attributes.privacy + ""
    }
  },
  computed: {
    ...mapState("rallies", ["allRalliesByRallyId"]),
    ...mapState("memberships", ["membersByRallyId", "membersByUserId"]),
    ...mapState("user", ["usersById"]),
    /*  memberPrivacy() {
      return  this.isAdmin ? 0 :  this.member.attributes.privacy
    },*/
    isEmbeddedComponent() {
      return document.getElementById("vue_component_name_id")
    },
    channelSlug() {
      // Used in place of channel.type.
      return this.$route.name;
    },
    isAdmin() {
      // TODO: Rally admin should be able to see everyone's profile.
      return (this.myRole == "Admin" ||  this.myRole  == "Owner")
    },
    isSelf() {
      // TODO: People can edit their own profile.
      return this.memberUser ? this.memberUser.id === userInfo().user_id : false
    },
    m(){
      return this.memberUser && this.memberUser.attributes ? this.memberUser.attributes: []
     },
    memberId() {
      return this.$route.params.id;
    },
    selectedRally() {
      return this.allRalliesByRallyId[this.rallyId];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },
    directMessageUrl() {
      return "/users/" + this.$route.params.id + "/personal_messages/new";
    },
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally", "updateMemberPrivacy"]),
    launchDMModal(){
        eventBus.$emit('maybeDirectMessageEvent' + this.uniqueId)
    },
    backToMembers: function() { 
      router.push(`/${this.rallyId}/directory`);
    },
    changePrivacy(e) {
      this.member.attributes.privacy = event.target.value
      this.updateMemberPrivacy({member: this.member, rallyId: this.rallyId})
    },
    perform(method) {
      // Execute whatever method name came from the ToolBar.
      this[method]();
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
