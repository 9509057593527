<template>
  <div>
    <DashboardNav />
    <main class="user-settings">
      <section class="accordions">
        <div class="accordion">
          <div class="title" @click="toggleAccordion">Rallyhood Profile</div>
          <UserProfile @saveComplete="collapseAccordion"></UserProfile>
        </div>
        <div class="accordion email-addresses">
          <div class="title" @click="toggleAccordion">Email Addresses</div>
          <EmailAddresses @saveComplete="collapseAccordion"></EmailAddresses>
        </div>
        <div class="accordion">
          <div class="title" @click="toggleAccordion">Notification Settings</div>
          <NotificationSettings @saveComplete="collapseAccordion"></NotificationSettings>
        </div>
        <div class="accordion">
          <div class="title" @click="toggleAccordion">Daily Digest</div>
          <DigestSettings @saveComplete="collapseAccordion"></DigestSettings>
        </div>
        <div class="accordion archive-delete">
          <div class="title" @click="toggleAccordion">Change Password</div>
          <ChangePassword @saveComplete="collapseAccordion"></ChangePassword>
        </div>
        <div class="accordion archive-delete">
          <div class="title" @click="toggleAccordion">Manage Account</div>
          <ManageAccount @saveComplete="collapseAccordion"></ManageAccount>
        </div>
      </section>
    </main>
  </div>
</template>

<script>

import UxMixin from "../components/UxMixin";

export default {
  data: function() {
    return {
      user_id: null,
    };
  },

  mixins: [UxMixin],
  computed: {
  },
  methods: {
    collapseAccordion() {
      const accordions = this.$el.getElementsByClassName('accordion');
      Array.from(accordions).forEach((accordion) => {
        accordion.classList.remove('open');
      });
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    },
  },
};
</script>
