<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
        </div>
        <div class="right">
          <button
            @click.prevent="$emit('perform', 'save')"
            class="primary"
            type="submit"
          >Save</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="url">Website URL</label>
          <input
            v-model="content.attributes.url"
            id="url"
            name="url"
            type="url"
          />
        </div>
        <div v-if="!isNewLifecycle" class="form-group">
          <label for="title">Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
        <div v-if="!isNewLifecycle" class="form-group">
          <label for="title">Description</label>
          <textarea
            v-model="content.attributes.description"
            id="title"
            name="title"
          />
        </div>
      </div>
    </form>
  </article>
</template>

<script>
  import ContentMixin from "@/components/ContentMixin.vue";

  export default {
    name: "Link",
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    mixins: [ContentMixin],
    computed: {
      isNewLifecycle(){
        return this.content.attributes['lifecycle'] == "new" && !this.content.id
      }
    }
  };
</script>
