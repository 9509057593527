<template>
  <div class="form-group color-picker">
    <div class="color-current color">
      <div
        class="color-point"
        :style="'background-color: ' + color + ';'"
        @click="open"
      >
      </div>
    </div>
    <div
      class="color-palette"
      v-if="isOpen"
    >
      <div
        v-for="hex in colors"
        :key="hex"
        class="color-option"
        :style="'background-color: ' + hex + ';'"
        @click="select(hex)"
      >
      </div>
    </div>
    Color
  </div>
</template>

<script>
  export default {
    props: {
      color: String,
    },
    data() {
      return {
        colors: [
          '#1C8291',
          '#004A55',
          '#00AFEB',
          '#E7008A',
          '#E0B41C',
          '#70B842',
          '#25AD72',
          '#08441B',
          '#1550DD',
          '#052746',
          '#AA239F',
          '#8D39A2',
          '#E68C98',
          '#880C3C',
          '#CA0F27',
          '#F55719',
          '#CB5121',
          '#F4AC42',
          '#58595B',
          '#231F20',
        ],
        isOpen: false,
      };
    },
    methods: {
      close() {
        this.isOpen = false;
      },
      open() {
        this.isOpen = true;
      },
      select(hex) {
        this.$emit('changeColor', hex);
        this.close();
      },
    },
  };
</script>
