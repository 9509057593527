<template>
  <div>
    <MoveModal
        :visible="moveModal.visible"
        :nestedFolders="filteredContentsFolders"
        :channelId="channelId"
        @close="closeMoveModal"
        @move="moveFolder"
        ref="moveModal"
    ></MoveModal>
    <article class="content listed file">
      <i class="fal fa-file-image file-type icon"   v-on:click="goToContent()"></i>
      <div v-if="!isSharedFromAnotherRally" class="title" v-on:click="goToContent()"> 
        {{ content.attributes.title.substr(0, 43) }}
      </div>
      <div v-if="isSharedFromAnotherRally" v-on:click="goToContent()" class="shared-title">
        {{
          content.attributes.title
        }} <span class="shared">(SHARED by {{sharedByName}})</span>
      </div>
      <div class="author-name">
        {{ byLine }}
      </div>
      <div class="other-info">
        {{ Math.round(content.attributes["file-size"]/1000) + " KB"}} |
        {{ formatDate(content.attributes["created-at"]) }}
      </div>
      <ContentPin :content="content"/>
      <ContentElipsisMenu @moveFile="openMoveModal" :content="content" :rallyId="this.rallyId"
                          :channelId="this.channelId" contentType="Files" canDownload="true" canShare="true"
                          canMove="true" hideSentiments="true"/>

    </article>
  </div>
</template> 

<script>
  import BinaryMixin from "../../BinaryMixin";
  import ContentMixin from "../../ContentMixin"; 
  import { Plugins } from "@capacitor/core";
  import MoveModal from "@/components/content/folder/MoveModal.vue";
  import {mapActions, mapState} from "vuex";
    import {router} from "../../../common";
  const { Browser, LocalNotifications } = Plugins;

export default {
  name: "File",
  components: {MoveModal},
  mixins: [
    BinaryMixin,
    ContentMixin,
  ],
  data() {
    return {
      folderList: [],
      moveModal: {
        visible: false,
        selectedFolderId: null
      }
    }
  },
    props: {
      channel: Object,
      rally: Object,
      content: Object,
      contextShare: Object,
      parentContextId:String
  },
  computed: {    
    ...mapState("contents", ["allContentByContextTypeAndId"]),
    nestedFolders() {
      const foldersByParentId = {};
      const result = [...this.folderList];
      const topLevelFolders = [];

      for (const folder of result) {
        const parentId = folder.parentId;

        if (!parentId) {
          // Top-level folder
          folder.children = [];
          if (folder.attributes['nest-level'] === 1) {
            topLevelFolders.push(folder);
          }
        } else {
          const parentFolder = foldersByParentId[parentId];
          folder.attributes['nest-level'] = parentFolder.attributes['nest-level'] + 1;
          folder.children = [];
          if (parentFolder) {
            parentFolder.children.push(folder);
          }
        }
        foldersByParentId[folder.id] = folder;
      }

      topLevelFolders.unshift({ id: this.channelId, children: [], attributes: { editable: true, title: '** ROOT FOLDER **' }});
      return topLevelFolders;

    },
    contentsFolders() {
      return this.allContentByContextTypeAndId[parseInt(this.parentContextId)];
    },
    filteredContentsFolders(){
      const cs = this.contentsFolders//this.allContentByContextTypeAndId[parseInt(this.contextId ? this.contextId : this.channelId)];
      if (cs && cs.length > 0){
        return cs.filter(function(s) {
          return !(s.attributes && s.attributes.title && s.attributes.title.indexOf('[untitled]') >= 0 ) &&
              (s.attributes['status'] == 1 ||
                  s.attributes['status'] == 2 ||
                  s.attributes['status'] == 0 ||
                  s.editMode == true || s.attributes['lifecycle'] == "new")
        })
      } else {
         return []
      }
    }
  },
  watch: {
    result: {
      immediate: true, // Run the watcher immediately when the component is created
      handler(newFolderList) {
        // Recalculate the computed property when the result changes
        // This ensures that the nestedFolders is updated with the latest result
        this.$nextTick(() => {
          // Use nextTick to wait for the DOM update cycle to complete
          // before accessing the updated nestedFolders
        });
      },
    },
  },
  mounted() {
   // this.fetchFolderList();
  },
  methods: {
    ...mapActions("contents", ["fetchFoldersRecursive", "moveContent"]), 
    async fetchFolderList() {
      this.folderList = [] /*await this.fetchFoldersRecursive({
        contextRallyId: this.rallyId,
        contextChannelId: this.channelId,
        contextId: this.channelId,
        contextType: 'Channel',
        nestLevel: 1,
        result: []
      })*/
    },
    move(params) {
      // Emit an event to notify the parent component about the move action
      this.$emit('perform', 'moveFolder', params);
    },
    openLink(url) {
      Browser.open({ url: url });
      //window.open(url, '_blank');
    },
    openMoveModal() {
      this.moveModal.visible = true; 
    },
    closeMoveModal() {
      this.moveModal.visible = false;
    },
    goToContent() {
      router.push(`/${this.rallyId}/${this.myChannelSlug}/${this.content.id}`);
    },
    async moveFolder(params) {
      // Handle the logic for moving the folder
      // using the destinationFolderId
      await this.moveContent({shareId: this.contextShare.id, destinationId: params.destinationFolderId, destinationType: params.contextType})
      this.$store.dispatch('user/flashSuccess', 'File successfully moved!');
      this.closeMoveModal()
      //this.$emit('perform', 'loadPageData', '');
      window.location.reload();
    }
  },
};
</script>
