<template>
  <article v-on:click="$emit('perform', 'showLightbox', content)">
    <div class="avatar" :style="style"></div>
  </article>
 
</template>

<script>
import { imgUrl } from "../../../common";
  export default {
    name: "Photo",
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    computed: {
      style() {
        return `background-image: url('${imgUrl(this.content)}');`;
      },
    },
  };
</script>
