<template>
  <div id="dashboard-nav">
    <ul>
      <li
        :class="{ active: is('/rallies') }"
        @click="to('/rallies')"
      >
        My Rallyhood
      </li>
      <li
        :class="{ active: is('/calendar') }"
        @click="to('/calendar')">
        My Calendar
      </li>
      <li
        :class="{ active: is('/messaging') }"
        @click="to('/messaging')"
      >
        Messaging
      </li>
      <li
          :class="{ active: is('/feed') }"
          @click="to('/feed')">
        Activity Feed
      </li>
      <li
        :class="{ active: is('/settings') }"
        @click="to('/settings')"
      >
        My Settings
      </li>
      <li :class="{ active: is('/get_help') }">
        <a href="javascript:;" class="help-general beacon-link" style="text-decoration: none;" >Get Help</a>
      </li>
      <div v-if="isRallyhoodAdmin">
        <li :class="{ active: is('/admin') }"
          @click="to('/admin')"
          >Admin
        </li>
      </div>
    </ul>
    <div class="title">
      {{ pageTitle  }}
    </div>
  </div>
</template>

<script>
  import {userInfo} from "@/common/user-info";
  import {mapState} from "vuex";

  export default {
    computed: {
      ...mapState("user", ["usersById"]),
      currentUser() {
        console.log("current user")
        console.log(userInfo().user_id)
        console.log(this.usersById)
        console.log(this.usersById[userInfo().user_id])
        return this.usersById[userInfo().user_id];
      },
      pageTitle() {
        return this.$route.meta.title;
      },
      isRallyhoodAdmin() {
        if (this.currentUser && this.currentUser.attributes && this.currentUser.attributes['is-rallyhood-admin'] == true) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      is(page) {
        return this.$route.path == page;
      },
      to(route) {
        this.$router.push(route);
      }
    },
  };
</script>
