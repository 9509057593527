<template>
  <div :class="[{ open: fileLimitUploads }, 'modal']">
    <div class="shadow" @click="closeFileLimitModal"></div>
    <div class="body">
      <i class="fal fa-times exit" @click="closeFileLimitModal"></i>
        <div class="heading">Hey There!</div>
        <div class="info">
            You can upload up to 10 photos at once. If you have more to share, please upload another batch. Happy uploading and have a great day!
        </div>
        <div class="actions">
          <button class="secondary" @click.prevent="closeFileLimitModal">Close</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFirst: Boolean,
    options: Object
  },
  data() {
    return {
      fileLimitUploads: false
    }
  },
  computed: {
  },
  methods: {
    closeFileLimitModal(){
      this.fileLimitUploads = false;
    },
    showModal(){
      this.fileLimitUploads = true;
    }
  }
};
</script>
