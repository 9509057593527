import axios from "axios";
//import store from "../store/modules/user";
import { store } from "../store";
import { accessToken } from "../common/auth-header"
import { refreshToken } from "../common/auth-header"
import { setRefreshToken } from "../common/auth-header";
import { setAccessToken } from "../common/auth-header";
import { RAS_URL, CID } from "./constants";

const jwtInterceptor = axios.create({});
let refreshing_token = null;

// Helper function to create a delay (10 seconds in this case)
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function refresh_token() {
    console.log('Refreshing access token');
    const rt = await refreshToken();
    const at = accessToken();
    console.log(rt);
    const payload = {
        grant_type: 'refresh_token',
        access_token: at,
        refresh_token: rt,
        client_id: CID()
    };
    const refresh_url = RAS_URL();
    return axios.post(
        refresh_url,
        payload
    );
}

jwtInterceptor.interceptors.request.use((config) => {
    //const authData = store.getters["auth/getAuthData"];
    //console.log("INTERCEPTED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    //console.log("ACCESS TOKEN:::::::::::")
    //console.log(accessToken());
    config.headers.common["Authorization"] = `Bearer ${accessToken()}`;
    //config.headers.common["x-test-header"] = 'we be here!';
    return config;
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
console.log("ERROR:::::::::")
console.log(error)
        console.log(error == "Error: Network Error")
        const config = error.config;
        // Initialize retry count if it's not already set
        if (!config._retryCount) {
            config._retryCount = 0;
        }
        // Check for 504 Gateway Timeout error
        if (error.response && error.response.status === 504 ) {
            config._retryCount += 1;

            // Retry the request once
            if (config._retryCount === 1) {
                console.log("RETRYING ON 504 GATEWAY TIMEOUT");
                store.dispatch('user/flashNotice', 'Thank you for your patience while we are working on your request. The server is currently \n' +
                    'experiencing a high-volume of traffic so please hang tight while it works on the backend.');
                // Wait for 10 seconds before retrying
                await delay(10000);
                // Retry the API call
                return jwtInterceptor(config);
            }
             else if (config._retryCount === 2) {
                    console.log("RETRYING ON 504 GATEWAY TIMEOUT");
                    store.dispatch('user/flashWarning', 'Well, this is embarrassing, we are still waiting for the server to respond. \n' +
                        'Thank you for your patience!');
                    // Wait for 10 seconds before retrying
                    await delay(10000);
                    // Retry the API call
                    return jwtInterceptor(config);
            } else if (config._retryCount > 2) {
                console.log("RETRYING ON 504 GATEWAY TIMEOUT FAILED THE SEDONqD TIME");
                // After the second failure, show the warning message
                store.dispatch('user/flashWarning', 'So sorry about this! Due to an unusual volume of traffic, the request has timed out. \n' +
                    'Please try back again later. ');
                return Promise.reject(error);
            }
        }

        if (error.response && error.response.status === 401 && !config._retry) {
            config._retry = true;
            try {
                refreshing_token = refreshing_token ? refreshing_token : refresh_token();
                let res = await refreshing_token;
                refreshing_token = null;
                if(res.data.access_token) {
                    let user = JSON.parse(localStorage.getItem('user'));
                    setAccessToken(res.data.access_token);
                    await setRefreshToken(res.data.refresh_token);
                }
                error.config.headers[
                    "Authorization"
                    ] = `Bearer ${res.data.access_token}`;
                return axios(error.config);
            } catch (err) {
                return Promise.reject(err)
            }

        } else {
            return Promise.reject(error);
        }
    }
);

export default jwtInterceptor;
