// services/MarketingSiteService.js
import axios from 'axios';
import {RH_MARKETING_URL} from "@/common";

//const BASE_URL = RH_MARKETING_URL(); // Update this with the Rails app URL

export default {
    async pullFromMarketing(path) {
        const response = await axios.get(`${RH_MARKETING_URL()}/home/${path}`);
        console.log("response", response);
        return response.data;
    },

    async submitForm(path, params) {
        const response = await axios.post(`${RH_MARKETING_URL()}/home/page_post`, params);
        return response.data;
    }
};
