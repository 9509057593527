<script>
import {Base64} from "js-base64";
import {momentTzToRailsTz, isValidBase64} from "@/common";

export default {
  name: "GateMixin",
  data() {
    return {
      email: null,
      timezone: null,
      claimedUser: false,
      invitationToken: null,
    };
  },
  async mounted() {
    this.detokenizeEmail();
    this.detectTimezone();
    this.extractClaimedUser();
    this.extractInvitationToken();
  },
  methods: {
    detokenizeEmail() {
      if (!this.$route.query.et || this.$route.query.et.trim() === '' || !isValidBase64(this.$route.query.et)) {
        this.email = null;
        return;
      }
      try {
        this.email = Base64.decode(this.$route.query.et);
      } catch (e) {
        this.email = null;
        console.error('Invalid token:', e);
      }
    },
    detectTimezone() {
      let jsTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timezone = momentTzToRailsTz(jsTimezone);
    },
    extractClaimedUser() {
      let claimedParam = this.$route.query.c || '';
      if (claimedParam.trim() === '' || !isValidBase64(claimedParam) || claimedParam == false) {
        this.claimedUser = false;
        return;
      } else {
        this.claimedUser = true;
      }
    },
    extractInvitationToken() {
      let invtParam = this.$route.query.invt || '';
      this.invitationToken = invtParam.split('/')[0];
    },
  }

}

</script>
