<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType, avaUrl } from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
import ContentReloadMixin from "./ContentReloadMixin";
import { userInfo } from "../common/user-info";
export default {
  mixins: [ContentReloadMixin],
  data() {
    return {
      loadAttempted: false
    }
  },
  computed: {
    ...mapState("participations", ["participationById"]),
    channelId() {
      return this.channel.id
    },
    rallyId() {
      return this.rally.id
    },
    checkReloadData() {
      if (!this.loadAttempted ) {
        this.loadPageData();
        return false
      } 
      return true
    },
    payables() {
      let c = this.allContentByContextTypeAndId[this.contentId]
      if (c && c.filter){
       return c.filter(function(s) {
        return s.attributes.type == 'Payable'
       })
      } else {
        return []
      }
      //return this.allContentByContextTypeAndId[parseInt(this.contextId)];
    },
  },
  methods: {
    ...mapActions("contents", ["fetchContents"]),
    ...mapActions("rfins", ["fetchFundraiserDetails"]),
    callAvaUrl(c){
      return avaUrl(c)
    },
    async loadContents() {
      if (this.channelId) {
       // this.channelId = chanId;
        this.contextId = this.channelId;
        if (this.myFetchContents !== undefined){
          this.myFetchContents()
        }
      }
    },
    async myReloadPageData() {
      this.user_id = userInfo().user_id
      //this.fetchFundraiserDetails({ fundraiserId: this.id });
      await this.fetchContents({
        contentType: "Payable", 
        contextId: this.content.id,
        contextRallyId: this.rallyId,
        contextType: "Content",
        include: ["shares", "participations"],
        type: "Payable",
        rallyId: this.rallyId
        });

        try {
        this.fetchContents({
          ignorePagination: true,
          contentType: "Event", 
          contextId: this.content.id,
          contextRallyId: this.rallyId,
          contextType: "Content",
          include: ["shares"],
          type: "DeadlineEvent",
          rallyId: this.rallyId
        })
        } catch (e){
           console.log(e)
        }
    },
    async loadPageData() {
      this.loadAttempted = true; 
      try {
        await this.myReloadPageData()
        await this.loadContents();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
