<template>
  <article v-on:click="$emit('perform', 'openContainer', content)">
    <div class="wrapper" :style="spiritColor">
      <div class="title">{{ content.attributes.title }}</div>
    </div>
  </article>
</template>

<script>
  import ContentMixin from "@/components/ContentMixin.vue";

  export default {
    name: "Album",
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    mixins: [
      ContentMixin
    ],
    computed: {
      spiritColor() {
        const colors = ["#40B5BC", "#5AC6C6", "#8FD0CA", "#03DACE", "#42ABC5"];
        const spiritNumber = Math.floor(parseInt(this.content.id.split("").pop()) / 2);
        return `background-color: ${colors[spiritNumber]};`;
      },
    },
  };
</script>
