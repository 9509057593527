import axios from "axios";
import {
PARTS_URL,
RCS_URL,
buildServiceParamMap,
mergeById,
buildMapOfListsByValueName,
sortByDate
} from "../../common";
import { userInfo } from "../../common/user-info"
import { authHeader } from "../../common/auth-header"
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
  participationById: {},
  userParticipationsByContentId: {},
  participationsByShareId: {}
};

const getters = {
  participationById: state => {
    return state.participationById;
  },
  userParticipationsByContentId: state => {
    return state.userParticipationsByContentId
  },
  participationsByShareId: state => {
    return state.participationsByShareId
  }
};

const actions = {

  //Completed 200 OK in 49ms (Views: 5.8ms | ActiveRecord: 2.3ms | Allocations: 7264)
//Started POST "/api/v1/participations.json" for 3.129.232.47 at 2021-03-30 17:14:18 +0000
//Processing by Api::V1::ParticipationsController#create as JSON
 // Parameters: {"data"=>{"type"=>"participations", "attributes"=>{"content_uid"=>"19029687", "share_id"=>"10188707", "origination"=>"opt_in", "originator_id"=>"303137", "originator_type"=>"User", "participation_status"=>"interested", "participant_type"=>"interested", "user_id"=>303137, "quantity"=>"14"}}}
  // (0.4ms)  BEGIN


  // Started POST "/api/v1/participations.json" for 127.0.0.1 at 2021-03-11 20:51:36 -0600
  //Processing by Api::V1::ParticipationsController#create as JSON
  //Parameters: {"data"=>{"type"=>"participations", "attributes"=>{"content_uid"=>"19023743", "share_id"=>"10134380", "origination"=>"opt_in", "originator_id"=>"303137", "originator_type"=>"User", "participation_status"=>"interested", "participant_type"=>"interested", "user_id"=>303137, "quantity"=>"8"}}}
  async createOrUpdateParticipation({ commit, state }, inparams) {
    const { id, contentUid, shareId, participationStatus, participantType, quantity, guests, channelId,contentType, rallyId, userIdPart } = inparams;
    const user = userInfo();
    const createdAttributes = {
      content_uid: contentUid,
      share_id: shareId,
      origination: "opt_in",
      originator_id: user.user_id,
      originator_type: "User",
      participation_status: participationStatus,
      participant_type: participantType, 
      user_id: userIdPart ? userIdPart : user.user_id,
      quantity,
      guests,
      contextChannelId: channelId
    };
    var method = "POST"
    var url = PARTS_URL()
    if (id) {
      method = "PUT"
      url = PARTS_URL() + "/" + id + ".json"
      createdAttributes["id"] = id
    }
    const requestOptions = {
      method,
      url,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "participations",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);
    commit("setParticipationById", {id: response.data.data.id, participation: response.data.data});
    this.dispatch("contents/fetchContentById", {id: contentUid, contextChannelId: channelId, contentType})
  },

  async deleteParticipation({ commit, state }, inparams) {
    const { id } = inparams;
    const user = userInfo();
    var method = "DELETE"
    var url = PARTS_URL() + "/" + id + ".json"
    const requestOptions = {
      method,
      url,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        event_id: 0, id
      }
    };
    const response = await jwtInterceptor(requestOptions);
    commit("deleteParticipationById", {id})
  },


  async fetchParticipations({commit, state}, inparams) {

    const user = userInfo();
    const { contextId, rallyId, pageSize = 20 } = inparams;
    var jsonparams = {
      share_id: contextId,
      user_id: '*',
      include: 'users,avatars',
      "page[size]": pageSize,
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: PARTS_URL(),
      params: params
    };
    const response = await jwtInterceptor(requestOptions);
    //var parts = buildMapOfListsByValueName(response.data.included, "type")["participations"]
    for (var i = 0; i < response.data.data.length; i++) {
      commit("setParticipationById", {id: response.data.data[i].id, participation: response.data.data[i]});
    }
    commit("setParticipationsByShareId", {shareId: contextId, participations: response.data.data});
    return response.data;
  },

  async setParticipationById({ commit, state }, inparams) {
    commit("setParticipationById", inparams);
  },

  async setParticipationsByShareId({ commit, state }, inparams) {
    commit("setParticipationsByShareId", inparams);
  }
};

const mutations = {
  clearState: state => {
    state.participationById = {};
  },


  deleteParticipationById: (state, params) => {
    const { id } = params;
    var byIdObj = {...state.participationById}
    var part = byIdObj[id + ""]
    byIdObj[id + ""] = null;
    byIdObj[part.attributes['content-uid'] + part.attributes['participant-type'] + part.attributes['user-id']] = null;
    state.participationById = {  ...byIdObj };
  },

  setParticipationById: (state, params) => {
    const { id, participation } = params;
    var byIdObj = {};
    byIdObj[id + ""] = participation;
    if (participation.attributes['participation-status'] == "purchased"){
      byIdObj[participation.attributes['content-uid'] + participation.attributes['participation-status'] + participation.attributes['user-id']] = participation;
    } else {
      byIdObj[participation.attributes['content-uid'] + "will_attend" + participation.attributes['user-id']] = null;
      byIdObj[participation.attributes['content-uid'] + "not_attending" + participation.attributes['user-id']] = null;
      byIdObj[participation.attributes['content-uid'] + "maybe_attending" + participation.attributes['user-id']] = null;
      byIdObj[participation.attributes['content-uid'] + participation.attributes['participant-type'] + participation.attributes['user-id']] = participation;
    }
    state.participationById = { ...state.participationById, ...byIdObj };
  },

  setParticipationsByShareId: (state, params) => {
    const { shareId, participations } = params;
    var byIdObj = {};
    byIdObj[shareId + ""] = participations;
    state.participationsByShareId = { ...state.participationsByShareId, ...byIdObj };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
