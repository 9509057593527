<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType, timezoneMapping } from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import { userInfo } from "../common/user-info"
export default {
  computed: {
    ...mapState("rallies", ["rallyChannelsByRallyId", "allRalliesByRallyId"]),
    ...mapState("user", [ "usersById"]),
   // ...mapState("rallies", ["ralliesByParent", ""]),
   // ...mapState("memberships", ["membersByRallyId"]),
    checkReloadData() {
      //updatethis TODO...this rally data is never loaded in prod for non admins
      //if (!this.allRalliesByRallyId[this.rallyId]) {
      //console.log("rrm checkreload...")
      if ( !this.loadAttempted && (!this.allRalliesByRallyId[parseInt(this.rallyId)] || Object.keys(this.allRalliesByRallyId).length <= 1 || !this.rallyChannelsByRallyId[parseInt(this.rallyId)])) { //!this.membersByRallyId[this.rallyId] ||
        this.loadPageData()
        return false
      }
      return true
    },

   /* contextId(){
      return this.channelId
    }*/
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("rallies", ["fetchRalliesWithInfo", "fetchRalliesByParent"]),
    ...mapActions("activities", ["fetchActivities"]),
    loadPageData() {
      this.loadAttempted = true
      try {

        //updatethis TODO...these calls are throwing errors in prod for non admins
        if ( !this.allRalliesByRallyId[parseInt(this.rallyId)] ||
            Object.keys(this.allRalliesByRallyId).length <= 1 ||
            !this.rallyChannelsByRallyId[parseInt(this.rallyId)] )
        { // !document.getElementById("vue_component_name_id") &&
            this.fetchMembersByRally({ fetchMyMemberInfo: true, rallyId:   parseInt(this.$route.params.rallyId), status: ['Active'] });
            var rallyObjs = []
            rallyObjs[this.rallyId] = {id: this.rallyId}

            // --- I don't think this call is necessary any more as the rally should be loaded by the time we get here
            //this.fetchRalliesWithInfo({rallyId: this.rallyId})
            this.fetchActivities({rallyId: this.rallyId})

        } //else if (!this.rallyChannelsByRallyId[parseInt(this.rallyId)]){
          //var rid = this.rallyId
          //console.log("calling.... fetchRalliesChannelsForRallies" + rid)
          //this.fetchRalliesChannelsForRallies({rid : this.allRalliesByRallyId[parseInt(this.rallyId)]})
        //}

        //@TODO: this was causing endless looping on prod calls...got in a loop with the checkreloaddata
        //this.fetchUsersById({ userIds: [userInfo().user_id], loggedInUser: true });
        //@TODO: this was causing endless looping on prod calls...got in a loop with the checkreloaddata...had to add if conditional
         //console.log("rrmix....1")
        if ((!this.membersByRallyId  || !this.membersByRallyId[this.rallyId] ||  !this.membersByRallyId[this.rallyId].length == 1) && !document.getElementById("vue_component_name_id") ){
            //console.log("rrmix....2")
          this.fetchMembersByRally({ rallyId:  this.rallyId, fetchMyMemberInfo: true,  status: ['Active'] });
             //console.log("rrmix....3")
          if (this.rally.attributes.editable){
            this.fetchMembersByRally({ rallyId:  this.rallyId, fetchMyMemberInfo: false,
              status: ["Active", "Invited", "Requested", "Affiliated"]});
          } else {
            this.fetchMembersByRally({ rallyId:  this.rallyId, fetchMyMemberInfo: false,
              status: ["Active" , "Affiliated"]});
          }
        }
        if (this.myLoadPageData){
          this.myLoadPageData()
        }
        //}
      }catch (e){
        console.log(e)
      }
    }
  }
};
</script>
