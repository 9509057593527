<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fas fa-chevron-left icon"></i>
            Cancel
          </a>
        </div>
        <div class="right">    
            <DeleteIcon type="button"   :content="content" :rallyId="this.rallyId"  :channel-id="this.channelId"  v-if="!isNewLifecycle"/>
          <button
            @click.prevent="$emit('perform', 'save')" 
            class="primary save_button"
            type="submit"
          >Save</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Sign-Up List Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            v-model="content.attributes.description"
            id="description"
            name="description"
          />
        </div>
        <hr/>
        <div class="form-group">
          <span class="label">Publish Status </span>
          <select v-model="content.attributes.status" class="quantity" >
            <option value="1">Active</option>
            <option value="0">Draft</option>
            <option value="2">Archived</option>
          </select>
        </div>
      </div>
    </form>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin";

  export default {
    name: "SignupList",
    mixins: [
      ContentMixin,
    ],
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    computed: {
      isNewLifecycle(){
        return this.content.attributes['lifecycle'] == "new" && !this.content.id
      }
    },
    mounted() {
      if (this.isNewLifecycle){
        this.content.attributes['status'] = "1"
      }
    }
  };
</script>
