<template>
  <article v-on:click="$emit('perform', 'openContent', content)">
    <div class="wrapper">
      <img class="avatar" :src="callAvaUrl(eventAvatar)" />
      <div v-if="totalDonations > 0" class="donations">
        {{ totalDonations }} Donations
      </div>
      <div class="title">{{ content.attributes.title }}</div>
      <div class="description" v-html="content.attributes.description"></div>
      <div class="progress" :style="{ width: progress + '%' }"></div>
      <div v-if="fundraiserDetails" class="summary">{{ formatCurr(fundraiserDetails.attributes.total) }} Collected of {{ formatCurr(fundraiserDetails.attributes.goal) }} Goal</div>
    </div>
  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { formatCurrency, avaUrl } from "../../../common";
import ContentPayableMixin from "@/components/ContentPayableMixin.vue";
export default {
  name: "GivePayableGroupListed",
  props: ["id", "channel", "rally", "content"],
  mixins: [
    ContentPayableMixin
  ],
  computed: {
    ...mapState("rfins", ["fundraiserDetailsById"]),
    ...mapState("contents", ["contentById"]),
    fundraiserDetails() {
      return this.fundraiserDetailsById ? this.fundraiserDetailsById[this.content.id] : null;
    },
    eventAvatar() {
      return this.contentById[this.content.relationships.avatar.data.id];
    },
    progress() {
      if(this.content.attributes.collected === undefined) {
        return 0
      }

      return (this.content.attributes.collected / this.content.attributes.goal) * 100;
    },
    totalDonations() {
      let donationCount = 0
      if(this.content.attributes['child-content-participants'] && this.content.attributes['child-content-participants'][0]) {
        this.content.attributes['child-content-participants'].forEach(participant => {
          donationCount = donationCount + participant.quantity
        })
      }
      return donationCount
    },
  },
  created() {
   // this.fetchFundraiserDetails({ fundraiserId: this.id });
    //this.user_id = userInfo().user_id;
    /*this.fetchContents({
      ignorePagination: true,
      contentType: "Payable",
      contextId: this.content.id,
      contextRallyId: this.rallyId,
      contextType: "Content",
      include: ["shares", "participations"],
      type: "Payable",
      pageNumber: 1
    });*/
  },
  methods: {
    ...mapActions("rfins", ["fetchFundraiserDetails"]),
    ...mapActions("contents", ["fetchContents"]),
    callAvaUrl(c){
      return avaUrl(c)
    },
    formatCurr(val) {
      return formatCurrency(val);
    }
  }
};
</script>
