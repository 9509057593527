import axios from "axios";
import jwtInterceptor  from '../../common/jwt-interceptor';

import {
    RUNS_URL,
    buildServiceParamMap,
    updateMapById,
    buildListOfAttributeValues,
    performGet, PARTS_URL
} from "../../common";

import { authHeader } from "../../common/auth-header";
import { StatusBarStyle } from "@capacitor/core";
import {userInfo} from "@/common/user-info";

const state = {
    rhNotifications: {},
    newRhNotifications: {}
};

const getters = {
    rhNotifications: state=> state.rhNotifications,
    newRhNotifications: state=> state.newRhNotifications
};

const actions = {
    async notificationViewed({ commit, state }, inparams) {
        const { status, userId, notificationId } = inparams;
        const requestOptions = {
            method: "PUT",
            url: RUNS_URL() + "/user-notifications"  + "/" + notificationId + ".json",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                data: {
                    type: "user-notifications",
                    attributes: {user_id: userId,
                        status: status,
                        source: 'vue-mobile'}
                }
            }
        };

        const response = await jwtInterceptor(requestOptions);
    },
    async fetchNotifications({ commit, state }, inparams) {
        const user = userInfo();

        var jsonparams = {
            user_id: user.user_id,
            sort: 'created_at desc'
        };

        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RUNS_URL() + "/user-notifications",
            params: params
        };

        const response = await jwtInterceptor(requestOptions);
        commit("setRhNotifications", {rhNotifications: response.data.data});
        commit("setNewRhNotifications", {rhNotifications: response.data.data})
    },

};

const mutations = {
    setRhNotifications: (state, params) => {
        const {rhNotifications} = params
        state.rhNotifications = rhNotifications;
    },
    setNewRhNotifications: (state, params) => {
        const {rhNotifications} = params;
        const newRhNotifications = []
        for (const notification of rhNotifications) {
            if(notification.attributes['status'] == 'new') {
                newRhNotifications.push(notification)
            }
        }

        state.newRhNotifications = newRhNotifications;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
