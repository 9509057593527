<template>
  <article v-on:click="$emit('perform', 'openContainer', content)">
    <div>
      <i class="fal fa-list-ul icon"></i>
      <div class="title">
        {{ content.attributes.title }} {{ contentStatus }}
      </div>
    </div>
    <div class="description">{{ content.attributes.description }}</div>
    <div class="count"> {{ childrenCount }} Signups</div>

  </article>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ContentMixin from "@/components/ContentMixin.vue";
export default {
  name: "SignupListListed",
  props: {
    content: Object,
    channel: Object,
    rally: Object
  },
  mixins: [
    ContentMixin,
  ],
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId"]),
    childrenCount() {
        var c = this.content.attributes['subcontents-count']
        return c ? c : 0
    },
    contentStatus() {
      if (this.content.attributes.status === '0' || this.content.attributes.status === 0) {
        return '(Draft)'
      } else if (this.content.attributes.status === '2' || this.content.attributes.status === 2) {
        return '(Archived)'
      }
      return ''
    }
  }
};
</script>
