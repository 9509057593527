<template>
  <select v-model="selectedState">
    <option value="" disabled>Select State</option>
    <option v-for="state in stateAbbreviations" :value="state" :key="state">{{ state }}</option>

  </select>
</template>

<script>
export default {
  name: "StateDropDown",
  props: {
    value: String
  },
  data() {
    return {
      selectedState: this.value || '',
      stateAbbreviations: [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
        'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
        'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
      ]
    };
  },
  watch: {
    selectedState(newValue) {
      this.$emit('input', newValue);
    }
  }
}
</script>

<style scoped>

</style>