<template>
  <div>
    <main class="gate login">
      <div class="together">
        <img src="/rallyhood-white.svg" />
        Together with purpose!
      </div>
          <ChangePassword :resetToken="token" ></ChangePassword>
      <hr />
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { router } from "../common";
import {Capacitor, Plugins} from "@capacitor/core";
import {userInfoPersistent} from "@/common/user-info";
//import { App } from '@capacitor/app';
const { Storage } = Plugins;
const { App } = Plugins
export default {
  data() {
    return {
      token: ""
    };
  },
  computed: {
  },

  async created() {

    const url = new URL(window.location.href);
    // Get the query parameters
    const params = new URLSearchParams(url.search);
    this.token = params.get('prt'); // "2"

  },
  methods: {

  
  }
};
</script>

