<template>
  <li class="flag option action">
    <div @click="toggleModal">
      <i class="fal fa-flag"></i>
      <span v-if="doShowLabel" class="">Report</span>
    </div>
    <div :class="[{ open: modalStatus }, 'modal flagging-modal']">
      <div class="shadow" @click="closeModal"></div>
      <div class="body">
        <form>
          <i class="fal fa-times exit" @click="closeModal"></i>
          <div class="heading">Report</div>
          <div class="description" style="margin-bottom: 5px;">
            Please note that flagging something simply because you disagree with it is not appropriate. What is wrong with this content?
          </div>
          <div class="form-group  radios">
            <label>
              <input name="cause" type="radio" value="Harassment" />
              <div class="circle"></div>
              Harassment
            </label>
            <label>
              <input name="cause" type="radio" value="Hate Speech" />
              <div class="circle"></div>
              Hate Speech
            </label>
            <label>
              <input name="cause" type="radio" value="Spam" />
              <div class="circle"></div>
              Spam
            </label>
            <label>
              <input name="cause" type="radio" value="Sexually Explicit" />
              <div class="circle"></div>
              Sexually Explicit
            </label>
            <label>
              <input name="cause" type="radio" value="Violence" />
              <div class="circle"></div>
              Violence
            </label>
          </div>
          <div class="form-group">
            <label for="description">Comments (optional)</label>
            <textarea name="description"></textarea>
          </div>
          <div class="actions">
            <button class="secondary" @click="closeModal">Cancel</button>
            <button class="primary" @click.prevent="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
export default {
  props: ['content', 'rallyId', 'showLabel'],
  data: function() {
    return {
      modalStatus: false
    };
  },
  computed: {
      doShowLabel(){
        return this.showLabel == "true"
      }
  },
  methods: {
    ...mapActions("contents", ["setContentById", "flagContent"]),
    closeModal() {
      this.modalStatus = false;
      this.$emit("perform", "hideActions");
    },
    submit() {
      // TODO: Create the Moderation record
      //this.$emit("perform", "removeContent");
      this.removeContent()
      this.closeModal();
     // this.$emit('perform', 'handleLightboxHide')
    },
    removeContent() {
        // TODO: Actually remove from some collection somewhere?
        console.log("called remove content")
        this.flagContent({ id: this.content.id, rallyId: this.rallyId })
        console.log("Navup called")
        eventBus.$emit('navUp')
        eventBus.$emit('lightboxHide')

    },
    toggleModal() {
      this.modalStatus = !this.modalStatus;
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
