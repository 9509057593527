<!-- DarkOverlay.vue -->

<template>
  <div v-if="showOverlay" class="overlay">
    <!-- Your content goes here -->
    <div class="overlay-content">
      <p><b>Thank you! Please wait while we process your order.</b></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    overlayOn() {
      this.showOverlay = true;
      setTimeout( function() {
        this.showOverlay = false;
      }.bind(this), 10000);
    },
    overlayOff() {
      this.showOverlay = false;
    }
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Adjust the alpha value to control darkness */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Adjust z-index as needed */
}

.overlay-content {
  color: white; /* Adjust text color */
  text-align: center;
}
</style>
