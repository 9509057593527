<template>
  <div id="header-invitation" :class="{expanded: state}">
    <article class="invitation-options-vue">
      <div class="option expandable" style="background-color: #008295;">
        <div class="button" @click="expand">
          <i class="fal fa-user-plus" aria-hidden="true"></i>
          One At A Time
        </div>
        <div class="dialog">
          <div class="sub-heading">
            Invite Someone To This Rally
          </div>
          <div>
            <div class="form-group half">
              <input v-model="firstName" type="text" placeholder="First Name">
            </div>
            <div class="form-group half">
              <input v-model="lastName" type="text" placeholder="Last Name">
            </div>
          </div>
          <div class="form-group">
            <input v-model="email" type="text" placeholder="Email">
          </div>
          <div class="form-group">
            <textarea v-model="invitationText" rows="4">
              (This should come from rally record)
              Hello Everyone,

            We created this Rally as an all-in-one solution for us to stay organized and connected. It's one easy place for us to send messages, keep a group calendar, organize special events, collect money, as well as share files, photos, links, tasks, and more. We also love that it's private, secure, and ad-free!

            Thanks for all you do!
            </textarea>
          </div>
          <div>
            <div class="form-group stacked-labels checkboxes half">
              <label>
                <input type="checkbox" v-model="saveAsDefaultInvitationText"/>
                <div class="square"></div>
                Save as Default Invitation Message
              </label>
            </div>
            <div class="form-group inline-labels radios half" style="text-align: right;">
              <label>
                <input v-model="inviteAdmin" name="role" type="radio" value="false" />
                <div class="circle"></div>
                Invite As Friend
              </label>
              <label>
                <input v-model="inviteAdmin" name="role" type="radio" value="true" />
                <div class="circle"></div>
                Invite As Admin
              </label>
            </div>
          </div>
          <div class="actions">
            <button class="back" @click="close">Cancel</button>
            <button class="primary" @click="invite">Invite</button>
          </div>
        </div>
      </div>
      <div class="option expandable" style="background-color: #71C6A2;">
        <div class="button" @click="expand">
          <i class="fal fa-envelope" aria-hidden="true"></i>
          Email List
        </div>
        <div class="dialog">
          <div class="sub-heading">
            Send Group Invitations
          </div>
          <div class="form-group">
            <textarea v-model="emailList" placeholder="Type or paste email addresses separated by commas or on separate lines. For best performance, we recommend not pasting more than 50 emails at a time.">
            </textarea>
          </div>
          <div class="form-group">
            <textarea v-model="invitationText" rows="4">
            </textarea>
          </div>
          <div>
            <div class="form-group stacked-labels checkboxes half">
              <label>
                <input type="checkbox" v-model="saveAsDefaultInvitationText"/>
                <div class="square"></div>
                Save as Default Invitation Message
              </label>
            </div>
            <div class="form-group inline-labels radios half" style="text-align: right;">
              <label>
                <input v-model="inviteAdmin" type="radio" value="false" />
                <div class="circle"></div>
                Invite As Friend
              </label>
              <label>
                <input v-model="inviteAdmin" type="radio" value="true" />
                <div class="circle"></div>
                Invite As Admin
              </label>
            </div>
          </div>
          <div class="actions">
            <button class="back" @click="close">Cancel</button>
            <button class="primary" @click="invite">Invite</button>
          </div>
        </div>
      </div>
      <div class="option expandable" style="background-color: #00B3D8;">
        <div class="button" @click="expand">
          <i class="fal fa-link" aria-hidden="true"></i>
          Shareable Link
        </div>
        <div class="dialog">
          <div class="sub-heading">
            Invite by URL
          </div>
          <div class="form-group">
            <label>
              Share this link in the messenger of your choice
            </label>
            <input
              id="shareableUrlId"
              type="text"
              :value="shareableUrl"
              readonly="readonly"
            />
          </div>
          <div class="actions">
            <button class="back" @click="close">Close</button>
            <button class="primary" @click="copy">Copy Link</button>
          </div>
        </div>
      </div>
      <div
        @click="twitterShare"
        class="option"
        style="background-color: #0084B4;"
      >
        <div class="button">
          <i class="fab fa-twitter" aria-hidden="true"></i>
          Twitter
        </div>
      </div>
      <div
        @click="linkedinShare"
        class="option"
        style="background-color: #0077B5;"
      >
        <div class="button">
          <i class="fab fa-linkedin" aria-hidden="true"></i>
          Linked
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { RH_VUE_URL} from "../common";
  export default {
    data: function() {
      return {
          firstName: "",
          lastName: "",
          email: "",
          inviteFriend: false,
          inviteAdmin: false,
          invitationText: "",
          emailList: "",
          saveAsDefaultInvitationText: false,
          title: ""
        }
      },
    computed: {
      ...mapState("rallies", ["allRalliesByRallyId"]),
      role(){
        return this.inviteAdmin == "true" ? "Admin": "Friend"
      },
      shareableUrl(){
        return RH_VUE_URL() + this.rallyId
      },
      selectedRally() {
        return this.allRalliesByRallyId[parseInt(this.rallyId)];
      },
    },
    props: ["state", "rallyId"],
    methods: {
      ...mapActions("memberships", ["createMemberInvite", "clearMemberState", "fetchMembersByRally"]),
      ...mapActions("rallies", ["updateRally"]),
      close() {
        this.firstName = ""
        this.lastName = ""
        this.email = ""
        this.inviteAdmin = false
        this.$emit('close');
      },
      copy() {
        var c = document.getElementById('shareableUrlId');
        c.select();
        document.execCommand("copy");
        this.close();
      },
      expand(event) {
        this.invitationText = this.selectedRally.rally.attributes["invitation-message"]
        for (const option of document.querySelectorAll('.option')) {
          option.classList.add('collapsed');
        }
        event.target.closest('.option').classList.remove('collapsed');
        event.target.closest('.option').classList.add('expanded');
      },
      fbInvite() {
        //if ( confirm('View fbInvite() in Rallyhood GitHub') ) {
          window.open('www.facebook.com', '_blank');
        //}
        this.close();
      },
      async invite() {
        if (this.emailList && this.emailList.length > 0){
          this.emailList = this.emailList.replace(/\n/g, ",");
          this.emailList = this.emailList.replace(/\s+/g, "");
          this.emailList = this.emailList.replace(/,+/g, ",");
          //alert(this.emailList)
          const promises = this.emailList.split(",").map(e => {
            if (e.indexOf("@") > 0 ){
              //alert("invite" + e)
              e = e.trim()
              const arr = e.split("@");
              return this.createMemberInvite({
                createdVia: "RallyFriends",
                invitationText: this.invitationText,
                rallyId: this.rallyId,
                role: this.role,
                status: "Invited",
                firstName: arr[0],
                lastName: arr[1],
                nickname: "",
                email: e,
                title: this.title
              });
            }
          });

          await Promise.all(promises);
        }
        if (this.email && this.email.length > 0){
          this.email = this.email.trim()
          await this.createMemberInvite({ createdVia:"RallyFriends", invitationText: this.invitationText, rallyId: this.rallyId, role: this.role, status: "Invited", firstName: this.firstName, lastName: this.lastName, nickname: "", email: this.email, title: this.title})
        }
        this.email = "";
        this.emailList = "";
        if (this.saveAsDefaultInvitationText) {
          this.selectedRally.rally.attributes["invitation-message"] = this.invitationText;
          await this.updateRally({rally: this.selectedRally, invitation_message: this.invitationText});
        }
        this.$store.dispatch('user/flashSuccess', 'Inviation(s) are on their way!');
        this.close();
      },
      linkedinShare() {
        //if ( confirm('View linkedinShare() in Rallyhood GitHub') ) {
          window.open('http://www.linkedin.com/shareArticle?mini=true&amp;url=' + this.shareableUrl + '&amp;title='+ this.selectedRally.rally.attributes.title +'&amp;summary=Great+things+happen+when+we+come+together.+Thanks+for+all+you+do+to+help+make+our+community+strong&amp;source=rallyhood.com', '_blank');
        //}
        this.close();
      },
      twitterShare() {
       // if ( confirm('View twitterShare() in Rallyhood GitHub') ) {
          window.open('http://twitter.com/share?text=Please+join+my+Rally+on+Rallyhood%3A+' + this.selectedRally.rally.attributes.title + '&amp;url=' + this.shareableUrl , '_blank');
        //}
        this.close();
      },
    },
    watch: {
      state(value) {
        if ( ! value ) {
          for (const option of document.querySelectorAll('.option')) {
            option.classList.remove('collapsed', 'expanded');
          }
        }
      },
    },
  }
</script>
