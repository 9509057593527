<template>
  <article>
   <div class="title">
      <i class="fal fa-folder file-type"></i>
      {{ content.attributes.title }}
    </div>
    <footer>
      <div class="left">
        <img class="author-avatar" :src="content.attributes['creator-avatar']" />
        <div class="author-name">
          {{ byLine }}
        </div>
        <div class="created-at">
          {{ when }}
        </div>
      </div>
      <div class="right actions">
        
         <ContentElipsisMenu
             :content="content"
             :rallyId="this.rallyId"
             :channelId="this.channelId"
             can-share="true"
             can-pin="true"
             contentType="Gallery"
             hideDelete="true"
             hideReport="true"/>
      </div>
    </footer>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin";

  export default {
    name: "Album",
    mixins: [
      ContentMixin,
    ],
    props: ["content", "rally", "channel"]
  }
</script>
