<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType, avaUrl } from "../common";
import ContentFlagging from "./ContentFlagging";
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("memberships", ["myMembershipByRallyId"]),
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    ...mapState("shares", ["sharesByContentId", "sharesByShareId"]),
    ...mapState("channels", ["channelsById"]),
    isAdmin() {
      return this.allRalliesByRallyId[this.$route.params.rallyId].rally.attributes["editable"]
    },
    isOwner() {
      return this.myRallyMembership.attributes["role"] == "Owner"
    },
    myRallyMembership(){
      return this.myMembershipByRallyId[this.$route.params.rallyId];
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },
    userIsOwnerComputed(){
      return this.userIsOwner(this.content)
    },
    canDeleteComputed(){
      return this.canDelete(this.content)
    },
    canEditComputed(c){
      return this.canEdit(this.content)
    },
    canUnshareComputed() {
      return this.canUnShare()
    }
  },
  methods: {
    userIsOwner(c){
      return (c && c.attributes && c.attributes["creator-id"] == this.user.user_id) 
    },
    canDelete(){
       return ((this.content && this.content.attributes.deletable !== undefined) ?  (this.content.attributes.deletable) : (this.userIsOwner || this.isAdmin))
    },
    canEdit(){
      //return this.userIsOwner || this.isAdmin
      return ((this.content && this.content.attributes.editable !== undefined) ?  this.content.attributes.editable : (this.userIsOwner || this.isAdmin))
    },
    canUnShare(){
      let share = this.sharesByShareId[this.shareId]
      let channel = this.channelsById[this.channelId]
      if(share && share.attributes){
        return share.attributes['creator-id'] == this.user.user_id || this.isAdmin || channel.attributes.editable
      } else {
        return false
      }
    },
  }
};
</script>
