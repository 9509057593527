<template>
  <div class="toolbar">
    <div  class="left">
      <a v-if="back" v-on:click="$emit('perform', back)" class="back">
        <i class="fal fa-chevron-left icon"></i>
        Back
      </a>
      <a
        v-else-if="subchannels && subchannels.length > 0"
        class="toggle-subchannels"
        @click="$emit('perform', 'toggleSubchannels')"
      >
        <div class="open">
          <i class="fal fa-times"></i>
          &nbsp;
          Hide List
        </div>
        <div class="closed">
          <i class="fal fa-chevron-left"></i>
          &nbsp;
          Back To Conversations
          <span
            v-if="totalUnreadCount > 0"
            class="unread-count"
          >
            {{ totalUnreadCount }}
          </span>
        </div>
      </a>
      <div class="multi-button" style="padding: 5px 5px; font-size: .9rem; margin-top: 5px;" v-if="allowMemberFilter && !back">
        <select style="border: none;" @change="$emit('perform', 'handleMemberFilterChange', $event.target.value)">
          <option value="all_members_and_declinees" v-if="isAdmin">All Roles</option>
          <option value="all_members" v-if="!isAdmin">All Roles</option>
          <option value="admins">Owners & Admins</option>
          <option value="only_friends">Friends</option>
          <option value="invitees">Pending Invitations</option>
          <option value="declinees" v-if="isAdmin">Declined Invitations</option>
          <option value="requestees">Requests</option>
        </select>
      </div>
      <div class="multi-button" style="padding: 10px 16px;" v-if="allowStatusFilter && !back">
        <select style="border: none;" @change="$emit('perform', 'handleContentFilterChange', $event.target.value)">
          <option value="1">Active</option>
          <option value="0">Draft</option>
          <option value="2">Archived</option>
          <option value="3" v-if="allowMyContentsFilter">{{ myContentsLabel }}</option>
        </select>
      </div>
    </div>
    <div v-if="showOptions" class="right">
      <div :class="[{ open: multiButtonOpen }, 'multi-button']">
        <ToolBarButton
          v-on:perform="perform"
          :options="defaultItem"
          :isFirst="true"
        />
        <div v-if="showDropDown" @click="toggleMultiButton" class="dropdown">
          <i class="fas fa-sort-down icon"></i>
        </div>
        <ul v-if="showDropDown" class="additional">
          <ToolBarButton
            v-for="item in additionalItems"
            v-on:perform="perform"
            :isFirst="false"
            :key="item.label"
            :options="item"
          />
        </ul>
      </div>
    </div>
    <div :class="[{ open: searchBarOpen }, 'search-bar']">
      <form @submit.prevent="performSearch">
        <select
          v-if="searchContext == 'directory'"
          v-model="searchField"
          class="search-filters"
        >
          <option value="">Search All Fields</option>
          <option value="name">Name Only</option>
          <option value="position">Current Position</option>
          <option value="expertise">Expertise Or Interest</option>
          <option value="additional_info">Additional Information</option>
          <option value="address">Address</option>
          <option value="birthday_month">Birth Month</option>
        </select>
        <input
          autocomplete="off"
          v-model="searchQuery"
          @click="searchQuery = ''"
          class="search-field"
          id="search-field"
          placeholder="Search..."
          type="text"
        />
        <i class="fal fa-times close" title="Close" @click="closeSearch"></i>
      </form>
    </div>
    <OpenRhWeb :params="rhWebParams" :uniqueId="openRhWebUniqueId"/>
  </div>
</template>

<script>
import ToolBarButton from "./ToolBarButton.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { userInfo } from "../common/user-info"
import { router } from "../common";
import { eventBus } from "../main.js";
export default {
  components: {
    ToolBarButton
  },
  data: function() {
    return {
      multiButtonOpen: false,
      searchBarOpen: false,
      searchQuery: "",
      searchField: "",
      searchContext: null,
      rhWebParams: null,
    };
  },
  props: ["back", "options", "subchannels", "channelId",
    "allowStatusFilter", "allowMyContentsFilter", "allowMemberFilter",
    "myContentsLabel"],
  computed: {
    ...mapState("memberships", ["membersByUserId"]),
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    openRhWebUniqueId(){
      return  this.rallyId + this.channelSlug 
    },
    channelSlug() {
      return window.location.href.split("/")[4]; 
    },
    filteredOptions(){
      if (this.options) {
        const thisBack = this.back
        return this.options.filter(function(o) {
          return !o.hideAtTopLevel || (o.hideAtTopLevel && thisBack)
        });
      } else {
        return []
      }
    },
    defaultItem() {
      return this.filteredOptions[0];
    },
    additionalItems() {
      return this.filteredOptions.slice(1);
    },
    showDropDown() {
      return this.additionalItems.length > 0;
    },
    showOptions() {
      return this.filteredOptions && this.filteredOptions.length > 0;
    },
    rallyId() {
      return this.$route.params.rallyId;
    },
    isAdmin() {
      // TODO: Rally admin should be able to see everyone's profile.
      return this.myRole === "Admin" || this.myRole === "Owner";
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },
    totalUnreadCount() {
      if ( this.subchannels.length == 0 ) { return false; }

      let count = 0;
      for ( let i = 0; i < this.subchannels.length; i++ ) {
        count += this.subchannels[i].unread;
      }
      return count;
    },
  },
  methods: {
    ...mapActions("memberships", ["searchMembers", "clearMemberSearchResults", "fetchMembersByRally", "clearMemberState"]),
    ...mapActions("rallies", ["searchRallies", "searchChildRallies", "clearRallyState", "fetchRalliesWithInfo"]),
    ...mapActions("contents", ["fetchContents", "clearContentsState"]),
    changeContentFilter(event) {
      this.$emit('changeContentFilter', event.target.value);
    },
    closeSearch() {
      this.searchBarOpen = false;
      this.clearMemberSearchResults({});
      location.reload();
    },
    itemClass(item) {
      return item.icon + " fal icon";
    },
    perform(method, params) {
      this.multiButtonOpen = false;
      if (method == "openSearch") {
        this.searchQuery = ""
        if(this.$route.name == 'directory') {
          this.searchContext = 'directory';
        } else {
            this.searchContext = params.context;
        }

        this.searchBarOpen = ! this.searchBarOpen;
        document.getElementById("search-field").focus();
      } else if ( method == "openSettings" ) {
        const path = `/${this.rallyId}/${this.channelSlug}/edit`;
        router.push(path);
      } else if (method == "refreshAll") {
       /* this.clearRallyState()
        this.clearContentsState()
        this.fetchRalliesWithInfo({})*/
        location.reload()
      } else if (method == "openRHWeb") {
        console.log("called open rh web in tb...")
        console.log(params)
        this.rhWebParams = params;
        console.log("emitting event " + 'openRHWebEvent' + this.openRhWebUniqueId)
        eventBus.$emit('openRHWebEvent' + this.openRhWebUniqueId);
      } else if (method == 'download') {
        eventBus.$emit('download')
      } else {
        this.$emit("perform", method, params);
      }
    },
    performSearch() {
      //this.closeSearch()
      switch ( this.searchContext ) {
        case 'all rallies':
          this.searchRallies({
            query: this.searchQuery,
          });
          break;
        case 'directory':
          this.searchMembers({
            status: ["Active", "Invited", "Requested", "Affliated"],
            query: this.searchQuery,
            rallyId: this.rallyId,
            queryField: this.searchField
          });
          break;
        case 'members':
          this.searchMembers({
            status: ["Active", "Invited", "Requested", "Affliated"],
            query: this.searchQuery,
            rallyId: this.rallyId,
            queryField: this.searchField
          });
          break;
        case 'my rallies':
          this.searchRallies({
            query: this.searchQuery,
          });
          break;
        case 'child rallies':
          this.searchChildRallies({
            query: this.searchQuery,
            parent_rally_id: this.rallyId
          });
          break;
        case 'File':
        case 'Photo':
          console.log("searching files")
          this.fetchContents({
            ignorePagination: true,
            contextType: "Channel",
            contextRallyId: this.rallyId,
            contextChannelId: this.channelId,
            rallyId: this.rallyId,
            contentType: ["Attachment", "Binary", "Container"],
            query: this.searchQuery, 
            status: 1
          })
          break;
        case 'Message':
          console.log("searching messages")
          this.fetchContents({
            ignorePagination: true,
            contextType: "Channel",
            contextRallyId: this.rallyId,
            contextChannelId: this.channelId,
            rallyId: this.rallyId,
            contentType: ["Message"],
            query: this.searchQuery,
            status: 1
          })
          break;
        default:
          alert('Unknown search context: ' + this.searchContext);
       }
    },
    toggleMultiButton() {
      this.multiButtonOpen = !this.multiButtonOpen;
      this.searchBarOpen = false;
    }
  }
};
</script>
