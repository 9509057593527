import axios from "axios";
import {RLOCS_URL, buildServiceParamMap, mergeById, RCS_URL} from "../../common";

import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
    locationsByAddressId: {}
};

const getters = {
    locationsByAddressId: state => id => {
        return state.locationsByAddressId[id];
    }
}

const actions = {
    async clearAddressState({commit, state}) {
        commit("clearState");
    },

    async fetchAddressesByIds({commit}, inparams) {
        const {addressIds} = inparams;
        const user = userInfo();
        var jsonparams = {
            id: addressIds
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RLOCS_URL() + '/street-addresses',
            params
        };
        const response = await jwtInterceptor(requestOptions);
        if (response.data.data) {
            commit("setLocationsByAddressId", {addresses: response.data.data});
        }
    },
}

const mutations = {
    setLocationsByAddressId: (state, params) => {
        const {addresses} = params;
        var newMap = {};
        addresses.forEach(address => {
            newMap[parseInt(address.id)] = address
        })
        state.locationsByAddressId = {...state.locationsByAddressId, ...newMap}
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};