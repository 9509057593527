<template>
  <div class="body">
    <div class="avatar-upload">
      <AvatarUpload :uniqueId="uniqueId" :rallyImg="avatarImage"/>
    </div>
    <form @submit.prevent="updateRallyData">
      <div class="sub-heading">Rally Details</div>
      <div class="form-group">
        <label for="title">Rally Name</label>
        <input  v-model="rally.rally.attributes['title']" type="text" />
      </div>
      <div class="form-group">
        <label for="cry">Rally Description</label>
        <textarea v-model="rally.rally.attributes['description']">
                </textarea>
      </div>
      <div class="form-group">
        <label for="cry">Default Invitation Message</label>
        <textarea v-model="rally.rally.attributes['invitation-message']">
                </textarea>
      </div>
      <div class="form-group stacked-labels radios">
        <div class="sub-heading">Membership Settings</div>
        <label>
          <input name="privacy" v-model="rally.rally.attributes['privacy']" type="radio" value="Public"/>
          <div class="circle"></div>
          <b>Public - </b>
          Anyone who is interested can join.
        </label>
        <label>
          <input name="privacy" v-model="rally.rally.attributes['privacy']" type="radio" value="Private" />
          <div class="circle"></div>
          <b>Private -</b>
          Members must be invited or have their request approved by an admin.
        </label>
        <label>
          <input name="privacy" v-model="rally.rally.attributes['privacy']" type="radio" value="Unlisted" />
          <div class="circle"></div>
          <b>Unlisted - </b>
          Members may only be invited by an admin. This Rally will not appear in search results.
        </label>
      </div>
      <div class="form-group stacked-labels radios">
        <div class="sub-heading">Notify Immediately</div>
        <label>
          <input v-model="rally.rally.attributes['disable-notify-immediate']" name="disable_notify_immediate" type="radio" value="true" />
          <div class="circle"></div>
          <b>Admin Only</b>
          (Recommended for most groups) - New messages are normally grouped into the Daily Digest email sent at the end of each day. Admin are allowed to post urgent messages which are emailed immediately.
        </label>
        <label>
          <input v-model="rally.rally.attributes['disable-notify-immediate']" name="disable_notify_immediate" type="radio" value="false"/>
          <div class="circle"></div>
          <b>All Members -</b>
          Allow all members to post urgent messages.
        </label>
      </div>
      <div class="actions">
        <button class="primary" type="submit">Save Changes</button>
      </div>
    </form>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import {mapActions, mapState} from "vuex";
import UxMixin from "@/components/UxMixin.vue";
import {avaUrl, router} from "../../common";

export default {
  mixins: [UxMixin],
  name: "RallyOptions",
  props: ['rallyId', 'isDesktopSize'],
  computed: {
    ...mapState("rallies", ["allRalliesByRallyId"]),
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),
    avatarImage() {
      return this.rallyAvatarContent
    },
    rallyAvatarContent() {
      var avid = this.allRalliesByRallyId[this.rallyId].rally.relationships.avatar.data.id
      if ( avid == -1){
        return this.defaultAvatarsByType["rally"]
      } else {
        return this.contentById[avid];
      }
    },
    rally() {
      let r = this.allRalliesByRallyId[this.rallyId];
      return r
    },
    uniqueId() {
      return this.rallyId;
    }
  },
  methods: {
    ...mapActions("rallies", ["updateRally"]),
    async updateRallyData() {
      eventBus.$emit('uploadAvatar'+ this.uniqueId, {rallyId: this.rallyId})
      const rally = this.rally;
      const privacy = rally.rally.attributes.privacy; // Get the privacy value from the rally
      const status = rally.rally.attributes.status; // Get the status value from the rally
      const notify_immediately = rally.rally.attributes['disable-notify-immediate']
      const description = rally.rally.attributes['description']
      const invitation_message = rally.rally.attributes['invitation-message']
      // Call the updateRally action
      await this.updateRally({
        rally: rally,
        privacy: privacy,
        status: status,
        disable_notify_immediate: notify_immediately,
        description: description,
        invitation_message: invitation_message
      });
      this.$store.dispatch('user/flashSuccess', 'Rally successfully updated!');
    }
  }
}
</script>

<style scoped>

</style>