<template>
  <div v-if="checkReloadData" class="register">
    <button :class="[cssClass, 'register-button']" @click="toggleModal">{{ label }}</button>
    <div :class="[{ open: confirmStatus }, 'modal']">
      <div class="shadow" @click="toggleConfirm"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleConfirm"></i>
        <div class="heading">Thanks for your donation!</div>
        <div>Confirmation Number:</div>
        <div>{{ "[RH" + confNum + "]" }}</div>
      </div>
    </div>
    <div :class="[{ open: modalStatus }, 'modal']">
      <div class="shadow" @click="toggleModal"></div>
      <div v-if="!confirmStatus" class="body">
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">Register</div>
        <div class="form-group quantities" v-for="payable in this.payables" :key="payable.id">
          <div v-if="payable.attributes.title !== 'user-defined'">
            <span class="label"> {{ payable.attributes.title }} ({{ "$" + payable.attributes.cost / 100 + ".00" }}) </span>
            <select v-model="payable.quantitySelected" class="quantity" v-on:change="onChangeValue($event)">
              <option v-for="i in 10" :key="i" :value="i">{{ i }}</option>
            </select>
          </div>
          <div v-if="payable.attributes.title === 'user-defined'">
            Enter a dollar amount:
            <input
              type="text"
              name="userDefined"
              id="userDefined"
              v-model="payable.quantitySelected"
              v-on:change="onChangeValue($event)"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <textarea v-model="notes" placeholder="Notes"></textarea>
        </div>
        <!-- <div class="form-group">
          <textarea placeholder="Additional Info"></textarea>
        </div>--> 
        <div v-if="this.stripeTx">
          <div class="left">Fees</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-fees"] }}</div>
        </div>
        <div v-if="this.stripeTx">
          <div class="left">Total</div>
          <div class="right">{{ this.stripeTx.attributes["formatted-total"] }}</div>
        </div>
        <div>
          <StripeElementCard v-if="checkoutMode"  @error="stripeCardError" ref="elementRef" :pk="publishableKey" @token="payItTokenCallback" />
        </div>
        <div class="actions">
          <button class="secondary" @click="toggleModal">Cancel</button>
          <button class="danger" @click="destroy" v-if="registered">Delete</button>
          <button v-if="!checkoutMode" class="primary" @click="checkout" :disabled="invalid">Checkout</button>
          <button v-if="checkoutMode" class="primary" @click="payIt" :disabled="invalid">Pay Now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CheckoutMixin from "./CheckoutMixin";
//import ContentReloadMixin from "./ContentReloadMixin";
//import ContentPayableMixin from "./ContentPayableMixin";
 
export default {
  props: [ "rallyId", "contextId"],
  mixins: [ CheckoutMixin],
  data: function() {
    return {
      loadAttempted: false,
      modalStatus: false,
      state: null // <--- This should come from the participation record, and is referenced by cssClass() and label() below, but I was not really sure what the options were.
    };
  },
  methods: {
  }, 
  computed: {
    ...mapState("rfins", ["accountRallyById"]),
    checkReloadData() {
      if (!this.allContentByContextTypeAndId[this.contextId] && !this.loadAttempted ) {
        return false
      } 
      return true
    },
    label() {
      if (!this.isStripeAccountPresent){
        return "Donations Disabled"
      }
      switch (this.state + "") {
        case "1":
          return "I'm Going";
        case "2":
          return "Holding My Spot";
        default:
           return (this.iHazPurchaz) ? "DONATE MORE" : "DONATE"
      }
    },
    registered() {
      return this.state !== null;
    }
  }
};
</script>
