<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="$emit('perform', 'cancel')" class="back">
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
          <span v-if="!isNewLifecycle" > 
            <!--<button class="danger" @click.prevent="doDelete" >Delete</button>-->
            <DeleteIcon type="button"   :content="content" :rallyId="this.rallyId"  :channelId="this.channelId" />
          </span>
        </div>
        <div class="right">
          <button
            @click.prevent="$emit('perform', 'save')"
            class="primary"
            type="submit"
          >Save</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Album Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
      </div>
    </form>
  </article>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import { router} from "../../../common";
  import ContentMixin from "@/components/ContentMixin.vue";
  export default {
    name: "Album",
    props: {
      content: Object,
      channel: Object,
      rally: Object
    },
    mixins: [
      ContentMixin
    ],
    computed: {
      isNewLifecycle(){
        return this.content.attributes['lifecycle'] == "new" && !this.content.id
      }
    },
    methods: {
       ...mapActions("contents", ["deleteContent", "flagContent"]),
      doDelete() {
        //this.deleteContent({ id: this.content.id, rallyId: this.$attrs.rallyId });
        this.flagContent({ id: this.content.id, rallyId: this.$attrs.rallyId });

        
      },
     /* toParent() {
        //TODO:  works but may break for nested content
        //const path =  `/${this.rallyId}/${this.channelSlug}/${this.content.relationships["context-share"].data.id}`;
        const path = `/${this.rallyId}/${this.channelSlug}`;
        router.push(path);
      }*/
    /*navUp() {
        this.parentContainers.pop();
        this.contextId = this.parentContextIds.pop();
        this.contextType = this.parentContextTypes.pop();
      }*/
    }
  };
</script>
