<template>
  <div>
    <main>
      <section class="content-area">
        <!-- edit message--->
        <div class="content-modal">
          <form @submit.prevent="doSaveMessage" class="content message new" method="post">
            <div class="toolbar">
              <div class="left">
                <a @click="selectMessage(content.id)" class="back">
                  <i class="fal fa-chevron-left icon"></i>
                  Cancel
                </a>
              </div>
              <div class="right">
                <button class="primary" type="submit">Save</button>
              </div>
            </div>

            <article class="content listed message">
              <div class="form-group">
                <label for="title">Title</label>
                <input v-model="content.attributes.title" id="title" name="title" type="text" />
              </div>
              <div class="form-group">
                <label for="body">Body</label>
                <!-- <textarea
                  v-model="content.attributes.body"
                  id="body"
                  name="body"
                  rows="5"
                ></textarea>-->
                <vue-editor v-model="content.attributes.body" :editorToolbar="customToolbar"></vue-editor>
              </div>
              <div class="form-group">
                <label  class="checkbox-label">
                  <input @click="notifyImmediately = !notifyImmediately" type="checkbox" />
                  <b>&nbsp; Notify Immediately</b>
                  - This will send an email with your message to all {{this.rallyMemberCount}} people in this Rally.
                </label>
              </div>
              <ContentAttachments  ref="cAttach" v-on:newFile="newFile" :readOnly="false" :content="content" :rallyId="this.rallyId" :channelId="this.channelId" />
            </article>
          </form>
        </div>
        <!--end edit message -->
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router, findDefaultChannelOfTypeForRally, slugToTypeObjectMap } from "../common";

export default {
  name: "SelectedMessagePage",
  data() {
    return {
      comments: [],
      customToolbar: ["bold", "italic", "underline", { list: "bullet" }, { 'color': [] }, { 'background': [] },  "link", { 'align': [] },],
      show_editor: false,
      polling_int: null,
      rallyId: null,
      id: null,
      channelId: null,
      notifyImmediately: false,
      newFileUpload: null
    };
  },
  computed: {
    ...mapState("contents", ["contentById"]),
    ...mapState("rallies", ["rallyChannelsByRallyId", "allRalliesByRallyId"]),
    content() {
      return this.contentById[this.id];
    },
    rallyMemberCount() {
      return this.allRalliesByRallyId[this.rallyId]?.rally?.attributes["child-count"];
    },
    channelTypePlural() {
      return slugToTypeObjectMap()["messages"].channelTypePlural;
    }
  },
  created() {
    this.rallyId = this.$route.params.rallyId;
    this.id = this.$route.params.id;
    this.channelId = findDefaultChannelOfTypeForRally(this.channelTypePlural, this.rallyChannelsByRallyId, this.rallyId);
  },

  methods: {
    ...mapActions("messages", ["saveMessage"]),
    newFile: function(nf) {
      this.newFileUpload = nf
    },
    navMessageList: function() {
      router.push(`/${this.rallyId}/messages`);
    },
    doSaveMessage() {
      this.$refs.cAttach.saveAttachments()
      this.saveMessage({ priority: this.notifyImmediately ? 1 : 4, message: this.content, rallyId: this.rallyId, channelId: this.channelId, status: 1 });
      this.show_selected = true;
      router.push(`/${this.rallyId}/messages`);
    },
    selectMessage: function() {
      router.push(`/${this.rallyId}/messages`);
    },
    clearMessage: function() {
      this.show_selected = false;
      this.fetchAllMessages();
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
