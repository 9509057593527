<template>
  <article class="listed">
    <hr/>
    <span>
      <p style="font-weight: bold; height: 15px;">
        <template v-if="account.attributes['disabled']">
          {{ computedTitle }}
        </template>
        <template v-else>
          <template v-if="this.isEditing">
            <input type="text" v-model="account.attributes['title']" @blur="saveTitle" @keydown.enter="saveTitle" >
          </template>
          <template v-else>
            <label @click="startEditingTitle">{{ computedTitle }}</label>
          </template>
        </template>
        <span v-if="account.attributes['disabled']" style="color:red;">
          (DISABLED)
        </span>
      </p>
      <div v-if="!isComplete" class="stripe_link">
        <a href="#" @click="continueEnrollment()">Continue Enrollment</a>
      </div>
    </span>
      <div v-if="isComplete">
        <div v-if="isStandard">
          <div>
            <a class="stripe_link" href="https://dashboard.stripe.com/login" target="_blank">Login to Stripe</a>
          </div>
        </div>
        <div v-if="isExpress">
          <div>
            <a class="stripe_link" :href="connectDashboardLink" target="_blank">Open Stripe Dashboard</a>
          </div>
        </div>
        <div class="stripe_reports">
          <div>
            <a class="stripe_link" @click.prevent="downloadFile('payment')">Download Payment Report</a>
          </div>
          <div>
            <a class="stripe_link" @click.prevent="downloadFile('payout')">Download Payout Report</a>
          </div>
        </div>
      </div>

  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router, findDefaultChannelOfType, avaUrl} from "../common";
import { userInfo } from "../common/user-info";
import { RFINS_URL } from "../common";
import axios from "axios";
import { Plugins } from "@capacitor/core";
import { Capacitor } from "@capacitor/core";

const { Browser } = Plugins;

export default {
  name: "StripeAccount",
  props: ["account"],
  data() {
    return {
      isEditing: false
    };
  },
  computed: {
    isComplete() {
      return this.account.attributes['stripe-status'] && this.account.attributes['stripe-status'] === 'enabled'
    },
    isStandard() {
      return this.account.attributes['account-type'] === "stripe_standard";
    },
    isExpress() {
      return this.account.attributes['account-type'] === "stripe";
    },
    connectDashboardLink() {
      return this.account.attributes['connect-dashboard-link']['url']
    },
    computedTitle() {
      if (this.isExpress) {
        return "(E) - " + this.account.attributes['title']
      } else {
        return this.account.attributes['title']
      }
    }
  },
  methods: {
    ...mapActions("rfins", ["updateStripeAccount"]),
    continueEnrollment() {
      if (Capacitor.getPlatform() == "android" ||
          Capacitor.getPlatform() == "ios") {
        Browser.open({ url: this.account.attributes['stripe-standard-enrollment-url'] });
      } else {
        window.location.href = this.account.attributes['stripe-standard-enrollment-url']
      }
    },
    downloadReport(reportType) {
      //window.location.href = "http://localhost:5010/api/v2/reports.xlsx?report_name=payment&account_rally_id=" + this.account.attributes['account-rally-id'] + "&account_id=" + this.account.attributes['account-id']
    },
    downloadReportUrl(reportName) {
      return RFINS_URL() + "reports.xlsx?report_name=" + reportName
          + "&account_rally_id="
          + this.account.attributes['account-rally-id']
          + "&account_id="
          + this.account.attributes['account-id']
    },
    downloadFile(reportName) {
      const token = localStorage.getItem('at');

      axios({
        url: RFINS_URL() + "reports?report_name=" + reportName
            + "&account_rally_id="
            + this.account.attributes['account-rally-id']
            + "&account_id="
            + this.account.attributes['account-id'],
        method: 'GET',
        responseType: 'blob', // Set the response type to 'blob' for file download
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
          .then(response => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'reports.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
    },
    startEditingTitle() {
      this.isEditing = true;
    },
    saveTitle() {
      this.isEditing = false;
      this.updateStripeAccount({account: this.account})
    },
  }
};
</script>
