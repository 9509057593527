<template>
  <div class="rsvp">
    <a v-if="hideButton" @click.stop="toggleModal()" class="action">
      <i class="fal fa-share-square"></i><span class="">Assign</span>
    </a>
    <button v-if="!hideButton" :class="[buttonStyle, 'rsvp-button']" @click="toggleModal">{{ label }}</button>
    <div :class="[{ open: modalStatus }, 'modal']">
      <div class="shadow" @click="toggleModal"></div>
      <div class="body">
        <div v-if="assigning">
          <div class="heading">Assign Someone</div>
          <div class="form-group">
            <select v-model="selectedUserId" v-on:change="onChangeParticipant($event)">
              <option v-for="m in this.membersByRallyId[this.rallyId]"
                      :key="m.attributes['user-id']"
                      :value="m.attributes['user-id']">
                {{ m.attributes["first-name"] }} {{ m.attributes["last-name"] }}
              </option>
            </select>
          </div>
        </div>
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">RSVP</div>
        <div class="form-group inline-labels radios">
          <label>
            <input name="state" type="radio" value="1" v-model="state" />
            <div class="circle"></div>
            Yes
          </label>
          <label>
            <input name="state" type="radio" value="0" v-model="state" />
            <div class="circle"></div>
            No
          </label>
          <label>
            <input name="state" type="radio" value="2" v-model="state" />
            <div class="circle"></div>
            Maybe
          </label>
          <label>
            <input name="state" type="radio" value="3" v-model="state" />
            <div class="circle"></div>
            No Reply
          </label>
        </div>
        <div class="form-group">
          <select v-model="guests">
            <option value="0">No Guests</option>
            <option v-for="i in 50" :key="i" :value="i">+{{ i }} Guests</option>
          </select>
        </div>
        <div class="actions">
          <button class="secondary" @click="toggleModal">Cancel</button>
          <button class="danger" @click="destroy" v-if="registered">Delete</button>
          <button class="primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { userInfo } from "../common/user-info"
export default {
  props: ["shareId", "content", "channelId", "userId", "rallyId", "hideButton", "assigning"],
  data: function() {
    return {
      state: null,
      modalStatus: false,
      buttonStyle: "primary",
      guests: 0,
      selectedUserId: null
    };
  },
  created() {
   // this.fetchRSVP({ shareId: this.shareId, contentUid: this.contentUid });
  },
  computed: {
    ...mapGetters("contents", ["rsvpsByUserIdAndShareId"]),
    ...mapGetters("rallies", ["rallyChannelsByRallyId"]),
    ...mapState("participations", ["participationById"]),
    ...mapState("user", ["usersById"]),
    ...mapState("memberships", ["membersByRallyId"]),
    myParticipation() {
     /* if (this.participationById[this?.content?.id  + this.myUserId]){
       return this.participationById[this?.content?.id   + this.myUserId]
      }*/
      if (this?.participationById[this?.content?.id + "will_attend" + this.myUserId]){
        return this?.participationById[this?.content?.id  + "will_attend" + this.myUserId]
      }
      if (this?.participationById[this?.content?.id  + "not_attending" + this.myUserId]){
        return this?.participationById[this?.content?.id  + "not_attending" + this.myUserId]
      }
      if (this?.participationById[this?.content?.id  + "maybe_attending" + this.myUserId]){
        return this?.participationById[this?.content?.id  + "maybe_attending" + this.myUserId]
      }
      if (this?.participationById[this?.content?.id  + "no_reply" + this.myUserId]){
        return this?.participationById[this?.content?.id  + "no_reply" + this.myUserId]
      }
      return {}

    },
    members() {
      return this.membersByRallyId[this.rallyId];
    },
    myUserId() {
      return this?.selectedUserId ? this.selectedUserId : userInfo().user_id;
    },
    getParticipantUser(id){
      return this.usersById[this?.participationById[id+ ""].attributes["user-id"]]
    },
    getParticipantName(id){
      var u = this.usersById[this?.participationById[id+ ""].attributes["user-id"]]
      return u ? u.attributes["first-name"] + " " +  u.attributes["last-name"] : ""
    },
    getInterestPart(id) {
      return (this.user && this.user.user_id) ? this?.participationById[id + "interested" + this.user.user_id] : null;
    },

    getMyPurchaseById(id) {
      return this.user &&  this.user.user_id ? this?.participationById[id + "purchased" + this.user.user_id] : null
    }, 
    cssClass() {
      return this.registered ? "warning" : "primary";
    },
    label() {
      if (this?.myParticipation?.attributes && this?.myParticipation?.attributes["participant-type"]){
        switch (this?.myParticipation?.attributes["participant-type"]) {
          case "will_attend":
            return "I'm Going " + this.guestText
          case "not_attending":
            return "Not Going"
          case "maybe_attending":
            return "Maybe"
          case "no_reply":
            return "No Reply"
          default:
            return "RSVP"
        }
      } else {
        return "RSVP"
      }
    },
    guestText(){
      var q = this?.myParticipation?.attributes['guests']
      return q && q !== '0' ? q + ' + guest(s)' : ''
    },
    registered() {
      return this?.myParticipation !== null
    }
  },
  methods: {
    ...mapActions("participations", ["createOrUpdateParticipation", "deleteParticipation"]),
    
    destroy() {
      this.toggleModal();
      let newArr = []
      if (this.content.relationships.participations.data){
        this.content.relationships.participations.data.forEach(e => {
          if (parseInt(e.id) !== parseInt(this.myParticipation.id)){
            newArr.push(e)
          }
        });
        this.content.relationships.participations.data = newArr
      }
      this.deleteParticipation({id: this.myParticipation.id})
    },
    onChangeParticipant(){
      if (this.participationForUser(this.selectedUserId)){
        this.quantity = this.participationForUser(this.selectedUserId).attributes.quantity
      } else {
        this.quantity = 0
      }
    },
    save() {
      this.toggleModal();
      var participantType = "";
      var participationStatus = "";
      switch (this.state + "") {
        case "1":
          participantType = "will_attend";
          participationStatus = "yes";
          break;
        case "0":
          participantType = "not_attending";
          participationStatus = "no";
          break;
        case "2":
          participantType= "maybe_attending";
          participationStatus = "interested";
          break;
        case "3":
          participantType = "no_reply";
          participationStatus = "assigned";
          break;
        default:
          participantType = "";
      }

      this.createOrUpdateParticipation({
        id: this?.myParticipation?.id? this?.myParticipation?.id : null,
        contentUid: this?.content?.id ,
        shareId: this.shareId,
        participationStatus,
        participantType,
        guests: this.guests,
        quantity: this.guests,
        channelId: this.channelId,
        contentType: "Event", 
        origination: "opt_in",
        userIdPart: this.selectedUserId ? this.selectedUserId : null
      });
      //this.doRSVP({ id: this.partId, rsvpStatus,  participantType, quantity: this.quantity, shareId: this.shareId, contentUid: this.contentUid });
      //this.fetchRSVP({ shareId: this.shareId, contentUid: this.contentUid });
    },
    toggleModal() {
      this.modalStatus = !this.modalStatus;
    }
  }
};
</script>
