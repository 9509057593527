
<template>
  <div id="app" v-if="user">
    <AlertArea />
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { router } from "../common";
import AlertArea from "../components/AlertArea";
import { userInfo } from "../common/user-info";
export default {
  name: "rhvuecomponent",
  components: {
    AlertArea,
  },
  data() {
    return {
      componentName: "",
      userEmail: ""
    };
  },
  computed: {
    ...mapState("user", ["status", "user"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    ...mapState({
      alert: state => state.alert
    })
  },
  created() {
    this.userEmail = document.getElementById("vue_user_email_id").value;
    this.componentName = document.getElementById("vue_component_name_id").value;
    localStorage.setItem("rhcsrf", document.getElementsByName('csrf-token')[0].getAttribute('content')) 

    //updatethis
    //localStorage.removeItem("user");
    //this.login({ username: "jason.topliff@rallyhood.com", password:"" });
    //this.login({ username: "chris+tz94@rallyhood.com", password: "" });
    this.getRhJWT({email:this.userEmail})
    this.startJWTTokenRefresh({ email:this.userEmail });
    //make sure my membership data is loaded
    this.fetchMembersByRally({ fetchMyMemberInfo: true, rallyId: this.$route.params.rallyId, status: ['Active'] });
    //then fetch the first page of all other member data
    this.fetchMembersByRally({ rallyId:   this.$route.params.rallyId, status: ["Active", "Invited", "Requested", "Affliated"]});
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally"]), 
    ...mapActions("user", ["login", "getRhJWT", "startJWTTokenRefresh"]),
    ...mapActions({
      clearAlert: "alert/clear"
    })
  }
};
</script>

<style lang="scss">
@import "../stylesheets/main.scss";
</style>
