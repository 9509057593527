<template>
  <div class="avatar-upload" v-if="changingAvatar">
    <span class="avatar-upload-wrapper">
      <img v-if="!this.imgData && !this.rallyImg" src="https://rallyhood-assets.s3.amazonaws.com/default/rally_avatars/1/large/rally_avatar.jpg"/>
      <cropper
          v-if="this.imgData || this.rallyImg" style="background:#DDD"
          :src="currentRallyImgUrl"
          :minWidth="773"
          :minHeight="773"
          :stencil-props="{
          aspectRatio: 9 / 3
        }"
          @change="cropperChange"
      ></cropper>
    <div class="avatar-upload" >
      <image-uploader
        :quality="1"
        :maxWidth="1200"
        :maxHeight="400"
        outputFormat="verbose"
        :autoRotate="false"
        :preview="false"
        :capture="false"
        accept="image/*"
        :className="'file-input-label'"
        doNotResize="['gif', 'svg', 'jpg', ]"
        @input="cacheAvatar"
      >
        <label for="fileInput" slot="upload-label">
          <span class="upload-caption">
            <i class="fa fa-camera"></i>
            {{ 'NEW PHOTO' }}
          </span>
        </label>
      </image-uploader>
        <button class="cancel-button" @click="cancelAvatarChange">Cancel</button>
      <button class="cancel-button" @click="cancelAvatarChange">Cancel</button>
      </div>
    </span>
  </div>
  <div v-else>
    <div class="accordion-header">
      <button class="primary change-image" @click="changingAvatar = true">
        <i class="fa fa-photo"></i>
        Change Image
      </button>
    </div>
      <img v-if="this.imgData || this.rallyImg" style="background:#DDD; width:100%" :src="currentRallyImgUrl"/>
      <img v-if="!this.imgData && !this.rallyImg" style="width:100%" src="https://rallyhood-assets.s3.amazonaws.com/default/rally_avatars/1/large/rally_avatar.jpg"/>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
import {
  router,
  slugToTypeObjectMap,
  findDefaultChannelOfTypeForRally,
  kebabCase, avaUrl, originalImgUrl, mediumImgUrl
} from "../common";

export default {
  data() {
    return {
      changingAvatar: false,
      uploadProgress: 0,
      newRallyImgUrl: null,
      imgData: null,
      imgParams: {},
      tempKey: "TK" + Math.floor(Math.random() * 10000)
    };
  },
  props: ["uniqueId", "rallyImg"],
  computed: {
    ...mapState("contents", ["uploadCount", "allContentByContextTypeAndId", "contentById"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    currentRallyImgUrl() {
        return this.newRallyImgUrl
            ? this.newRallyImgUrl
            : (this.rallyImg ? originalImgUrl(this.rallyImg) : "https://rallyhood-assets.s3.amazonaws.com/default/rally_avatars/1/large/rally_avatar.jpg");
    },
    newImageName() {
      return this.imgData ? this.imgData.info.name : "";
    },
    progressBarStyle() {
      if ( this.uploadCount == 0 ) {
        return 'display: none;';
      } else {
        return "width: " + this.uploadProgress + "%;upload-count:" + this.uploadCount;
      }
    },
  },
  created() {
    //fetch after upload context_rally_id=39019&context_type=Message&context_channel_id=294210&context_id=19033866&page%5Bnumber%5D=1&page%5Bsize%5D=6&sort=contents.created_at+desc&include=shares%2Ccreator%2Ccreator_avatar%2Cparticipations%2Cavatar&user_id=303137&content_type=Binary&type=Attachment
    //started GET "/api/v1/contents.json?content_type=Binary&context_channel_id=294210&context_id=19033135&context_type=Message
    //&include=shares&page%5Bsize%5D=100&sort=contents.id"
    if(this.rallyImg) {
      this.imgData = this.rallyImg
    }

    //this.imgData = this.rallyImgUrl
    eventBus.$on("uploadAvatar" + this.uniqueId, p => {
      this.imgParams["contextRallyId"] = p.rallyId;
      this.imgParams["contextId"] = p.rallyId;
      //Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "creator_id"=>nil, "cropping"=>{"crop_h"=>"7", "crop_w"=>"20", "crop_x"=>"0", "crop_y"=>"10"}, "content_type"=>"Binary", "context_id"=>"51060", "context_type"=>"Rally", "status"=>1, "type"=>"RallyAvatar", "context_rally_id"=>"51060", "context_channel_id"=>nil, "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}
      this.uploadFile(this.imgParams);
      this.changingAvatar = false;
    });

  },
  watch: {
    allContentByContextTypeAndId: function(newById, oldById) {
      if (this.uploadProgress > 0) {
        this.uploadProgress = 95;
      }
    }
  },
  methods: {
    ...mapActions("contents", ["fetchContents", "uploadFile"]),
    ...mapActions("shares", ["shareContent"]),
    cancelAvatarChange() {
      this.changingAvatar = false;
      // Clear the uploaded image data and URL
      //this.imgData = this.rallyImg.attributes['url']
      //this.rallyImg = null;
      this.newRallyImgUrl = null;
    },
    cropperChange({ coordinates, canvas }) {
      this.imgParams = {
        contentType: "Binary",
        contextType: "Rally",
        type: "RallyAvatar",
        attachment: canvas.toDataURL().split(",")[1],
        attachmentDataURL: canvas.toDataURL(),
        status: 1,
        attachmentContentType: this.imgData.info ? this.imgData.info.type : this.rallyImg.attributes['attachment-content-type'],
        attachmentFileName: this.imgData.info ? this.imgData.info.name : this.rallyImg.attributes['attachment-file-name'],
        tempKey: this.tempKey
      };
    },
    cacheAvatar(img) {
      this.imgData = img;
      this.newRallyImgUrl = img.dataUrl;
    },

    displayMessageUploadProgress() {
      setTimeout(
        function() {
          if (this.uploadProgress <= 90) {
            this.uploadProgress += 3;
            this.displayMessageUploadProgress();
          } else {
            this.uploadProgress = 0;
          }
        }.bind(this),
        100
      );
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
    showLightbox(image) {
      this.currentPhoto = image;
    },
    handleLightboxHide() {
      this.currentPhoto = null;
    }
  }
};
</script>
