<template>
  <div>
    <main>
      <section>
        <div style="margin-top: 90px;" >
          <div v-if="showSearchScreen">
            <input type="text" v-model="userId" placeholder="Enter Id of User" style="margin-left:10px;margin-right:15px;">
            <button @click="masquerade()" style="padding-left:5px;padding-right:5px;">Masquerade</button>
          </div>
          </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions} from "vuex";
import {
  router
} from "../common";

export default {
  props: {
  },

  data() {
    return {
      showSearchScreen: true,
      searchText: '',
      userId: '',
      users: []
    }
  },

  methods: {
    ...mapActions("user", ["getMasqueradeToken"]),
    showAdminScreen() {
      this.showSearchScreen = true
    },

    async searchUsers() {
      const response = await axios.get('/api/search-users', {
        params: {
          search: this.searchText
        }
      })

      this.users = response.data
    },

    async masquerade(userId) {
      const token = await this.getMasqueradeToken({ userId: this.userId })
      console.log("DID WE GET IT?")
      console.log(token)
      location.reload()

      // Store the new JWT in local storage or Vuex state for use in subsequent requests
    }
  }
}
</script>
