<template>
  <div v-if="params && params.heading" :class="[{ open: modalOpen }, 'modal', '-modal']">
    <div class="shadow" @click="closeModal"></div>
    <div class="body">
      <i class="fal fa-times exit" @click="closeModal"></i>
        <div >
          <div class="heading">{{params.heading}}</div>
          <div class="description">
            {{params.description}}
          </div>
          <div class="actions">
            <div class="left">
              <button class="secondary" @click="closeModal">Cancel</button>
            </div>
            <div class="right">
              <button class="primary" @click="openRhWebTab">GET STARTED!</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { accessToken } from "../common/auth-header"
  import { RH_URL, router} from "../common";
  import { eventBus } from "../main.js";
  import { Plugins } from "@capacitor/core";
  const { Browser } = Plugins;
  export default {
    props: [ 'params', 'uniqueId' ],
    data() {
      return {
        openModal: 'no'
      }
    },
    created(){
      console.log("registering.... openRHWebEvent " + this.uniqueId)
      eventBus.$on('openRHWebEvent' + this.uniqueId, () => {
          console.log("received ...openRHWebEvent" + this.uniqueId)
        this.openRhWebTab();
          //this.openModal = 'yes'
      });

    },
    computed: {
      modalOpen() {
        return this.openModal == 'yes'
      },
    },
    methods: {
      
      closeModal() {
        //this.$emit('perform', 'openRHWeb', null);
        this.openModal = 'no';
      },
      openRhWebTab() {
        console.log("openRhWeb called......in component")
        console.log(this.params)
        const url = RH_URL();
        const rallyId = this.$route.params.rallyId;
        const returnTo = this.params.returnTo + '&cap_open=1';
        const mt = accessToken();
        const capOpenParam = "&cap_open=1"
        console.log("url " + `${url}?mt=${mt}&return_to=/${rallyId}/${returnTo}&cap_open=1`)
        Browser.open({ url: `${url}?mt=${mt}&return_to=/${rallyId}/${returnTo}&cap_open=1` });
        Browser.addListener('browserFinished', () => {
            console.log('browserFinished event called')
            router.push(`/${rallyId}/special_events`)
            location.reload()
        });
        this.openModal= 'no'
      }
    }
  }
</script>
